import { dist } from './dist';
import { apiURLSK, displayLog, prefix, notTouchyELs } from './config';
import { FBX, warningSuccSVGs } from './svgIcons';

export const setDevProf = () => {
  if (getCookie('fgxkegwwkf') === '') {
    createCookie('fgxkegwwkf', uuidvMain());
  }
};

export const idleStart = () => {
  const expire = 3600;
  const warning = 3300;
  let timer,
    currSeconds = 0;

  function resetTimer() {
    clearInterval(timer);
    currSeconds = 0;
    timer = setInterval(startIdleTimer, 1000);
  }
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.onclick = resetTimer;
  window.onkeypress = resetTimer;

  function startIdleTimer() {
    const infoText = window.infoText;
    const expireMsg = infoText.inf_Mkr45 ? infoText.inf_Mkr45 : 'Your session will expire due to inactivity in:';
    const back = infoText.inf_Mkr47 ? infoText.inf_Mkr47 : 'Back';

    currSeconds++;
    if (currSeconds >= warning && currSeconds < expire) {
      const value = convertHMS(expire - currSeconds);
      infoBox(`${expireMsg} ${value}`);
      document.getElementById('infoBoxBtn').innerHTML = back;
    }
    if (currSeconds === expire) {
      const sessionMsg = infoText.inf_Mkr52 ? infoText.inf_Mkr52 : 'Your session has expired. You will need to log back in to continue.';

      infoBox(sessionMsg);
      id('infoBoxBtn').setAttribute('onclick', 'logout(3)');
      id('infoBoxBtn').innerHTML = infoText.inf_Mkr30 ? infoText.inf_Mkr30 : 'Logout';
      clearInterval(timer);
    }
  }
};
export const mFile = (method, fileName) => {
  return {
    method: method,
    fileName: fileName,
    isFile: 1,
  };
};

export const initSession = (UUID, HuserID) => {
  return new Promise((resolve, reject) => {
    if (UUID) {
      createCookie('ewttgpvwwkf', UUID); // UUID
    } else {
      createCookie('ewttgpvwwkf', 0); // UUID
    }

    if (HuserID) {
      createCookie('jwugtkf', HuserID); // HuserID
    } else {
      createCookie('jwugtkf', 0); // HuserID
    }

    getCookie('mbohvbhfje') || createCookie('mbohvbhfje', 1); // languageID
    window.pageHist = new Map();
    window.pgstr = {};
    window.infoText = {};
    pageHist.set(5, '');
    pageHist.set(10, '');
    pageHist.set(15, '');
    pageHist.set(20, '');

    const vars = {};
    vars.languageID = getCookie('mbohvbhfje') || '1';
    vars.HTMLPageName = 'infoText';
    PREP(['getHTMLLexicon'], vars)
      .then(async (res) => {
        window.infoText = await createLangObj(res.data.getHTMLLexicon);
        resolve(); // Resolve the Promise when all asynchronous operations are complete
      })
      .catch(reject); // Reject the Promise if there's an error
  });
};

export const createCookie = (cKey, cValue) => {
  return new Promise((resolve, reject) => {
    document.cookie = `${cKey}=${cValue};secure,`;
    resolve(); // Resolve the promise when the cookie is set
  });
};

export const getCookie = (cKey) => {
  let name = cKey + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let cookie of ca) {
    let c = cookie;
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return 0;
};

export const initPageInstance = async (pageInst, data) => {
  const tier = pageInst.pageTier;

  for (let item of pageHist) {
    if (item[0] >= tier) {
      const currPage = pageHist.get(item[0]);
      delete pgstr[currPage];
      pageHist.set(item[0], '');
    }
  }

  pageInst = pgstr[pageInst.reference] ? pgstr[pageInst.reference] : pageInst;
  // const oldNew = pgstr[pageInst.reference] ? 0 : 1;

  pageHist.set(tier, pageInst.reference);
  pgstr[pageInst.reference] = pageInst;

  await pageInst.initPage(data || null);

  // buildBreadCrumbs();
};

// const buildBreadCrumbs = () => {

//   const hist = pageHist;
//   const bc = id('breadCrumbs');
//   let firstPage = true;
//   bc.innerHTML = '';

//   for (let [key, value] of hist) {
//     if (value && firstPage) {
//       firstPage = false;
//         const btn = divAtt('button', 'class', '');
//         btn.setAttribute('onclick', `PREP('${value}', 'reload=true')`);
//         btn.innerHTML = 'test';
//         bc.appendChild(btn);
//     } else if (value) {
//       const btn = divAtt('button', 'class', '');
//       btn.innerHTML = 'test';
//       bc.appendChild(btn);
//     }
//   }
// };

export const reloadPage = async (htmlPage, reference) => {
  const hdrs = await getAuthHdrs();
  const recipe = await getRecipe();
  recipe.page = htmlPage;

  await SWITCHBOX(recipe, hdrs, vars);
  pgstr[reference].loadPage();
};

export const PREP = async (rec, oldVars, loaderMsg) => {
  //-------------------------
  // Add Modal
  if (loaderMsg) {
    loader(1, `${loaderMsg}`);
  } else {
    loader(1);
  }

  //-------------------------
  // Set the Variables
  const vars = typeof oldVars == 'string' ? await urlToObj(oldVars) : oldVars || {};
  //-------------------------
  // Get the headers
  const hdrs = await getAuthHdrs();
  //-------------------------
  // Set the Recipe
  let recipe = rec;
  if (Array.isArray(rec)) {
    recipe = await getRecipe();
    recipe.methods = rec;
    return SWITCHBOX(recipe, hdrs, vars);
  } else {
    if (typeof rec == 'string') {
      recipe = await getRecipe();
      recipe.page = rec;
    }
    if (dist.hasOwnProperty(recipe.page)) {
      dist[recipe.page](recipe, hdrs, vars);
    } else {
      return SWITCHBOX(recipe, hdrs, vars);
    }
  }
  // loader(0);
};
window.PREP = PREP;

export const SWITCHBOX = async (recipe, hdrs, vars) => {
  //-------------------------
  // Create Form Data
  recipe.tabs && (hdrs.parentEntityName = recipe.page) && recipe.methods.push('getTabTranslation');
  //-------------------------
  // Check for language translation
  recipe.page && recipe.methods.push('getHTMLLexicon') && (vars.HTMLPageName = recipe.page);

  // if (recipe.page) {
  //   if (recipe.page !== 'login') {
  //     recipe.methods.push('getHTMLLexicon');
  //     vars.HTMLPageName = recipe.page;
  //   }
  // }
  //-------------------------
  // Try/Catch
  try {
    const headers = await cfd(hdrs, vars || {});
    const html = recipe.page ? await fetchHTML(recipe.page) : void 0;
    const t0 = performance.now();
    const data = recipe.methods.length ? await fetchMethods(recipe, headers) : {};
    const t1 = performance.now();
    const pageLang = data.getHTMLLexicon ? true : false;
    const Performance = `${t1 - t0} milliseconds.`;
    const tabs = recipe.tabs ? await fetchTabs(recipe, headers) : [];
    html && (id(recipe.div).innerHTML = html);
    recipe.tabs && translateTabs(data.getTabTranslation);
    pageLang && translatePage(data.getHTMLLexicon);
    tabs.length && displayTabs(tabs, vars);

    const response = {
      recipe,
      data,
      vars,
      Performance,
    };
    displayLog &&
      logRes({
        ...response,
      });
    loader(0);
    return response;
  } catch (err) {
    loader(0);
    errBox(err);
    throw err;
  }
};
window.SWITCHBOX = SWITCHBOX;
export const logRes = (res) => {
  let dataObj = {};
  let statusCodes = {};
  let statusTexts = {};

  for (let item in res.data) {
    let split = item.split('_');
    if (split.length > 1) {
      if (split[1] === 'SC') {
        statusCodes[item] = res.data[item];
      } else {
        statusTexts[item] = res.data[item];
      }
    } else {
      dataObj[item] = res.data[item];
    }
  }
  res.data = dataObj;
  res.statusCodes = statusCodes;
  res.statusTexts = statusTexts;
  console.log('Response (SB): ', res);
};

export const createLangObj = async (langArr) => {
  const langObj = {};

  if (langArr.length) {
    for (let row of langArr) {
      langObj[row.marker] = row.display;
    }
  }

  return langObj;
};

export const translateTabs = (tabs) => {
  for (let row of tabs) {
    id(row.tabEntityName) && (id(row.tabEntityName).innerHTML = row.shortDescription);
  }
};

export const directTab = (vars) => {
  if (vars.dTab) {
    changeTabN(vars.dTab);
  }
};

export const getAuthHdrs = async () => {
  const vars = {};
  vars.UUID = getCookie('ewttgpvwwkf');
  vars.HuserID = getCookie('jwugtkf');
  vars.deviceUUID = getCookie('fgxkegwwkf');
  vars.languageID = getCookie('mbohvbhfje');

  return vars;
};

export const insertDiv = async (html, div, vars) => {
  const recipe = await getRecipe();
  recipe.page = html;
  recipe.div = div;
  return PREP(recipe, vars || {});
};
window.insertDiv = insertDiv;

export const displayTabs = (tabList, vars) => {
  const nodeList = document.querySelectorAll('.tablinks');
  const tabArr = [];
  for (let t of tabList) {
    tabArr.push(t.entityName);
  }
  nodeList.forEach((tab) => {
    if (tabArr.includes(tab.id)) {
      unhide(tab.id);
    }
  });

  const dTab = vars.dTab ? 1 : 0;

  if (dTab && tabArr.includes(vars.dTab)) {
    changeTabN(vars.dTab, vars);
  } else {
    try {
      nodeList.forEach((tab) => {
        if (tabArr.includes(tab.id)) {
          throw tab.id;
        }
      });
    } catch (tabID) {
      changeTabN(tabID, vars);
    }
  }
  checkTabScroll();
};

export const id = (string) => {
  return document.getElementById(string);
};

export const loader = async (io, msg) => {
  if (id('loader')) {
    id('loader').style.display = io ? 'flex' : 'none';
    if (msg) {
      id('loaderMsg').innerHTML = msg;
    } else {
      id('loaderMsg').innerHTML = '';
    }
  }
};

export const scroll = () => {
  window.scrollTo(0, 0);
};

export const logout = (code) => {
  PREP(['logLogout'], {
    initCode: code,
  });
  initSession();
  location.reload();
};
window.logout = logout;

export const onblurVal = (elID) => {
  if (!id(elID).value || id(elID).value == '-') {
    id(elID).classList.add('incorrect');
    id(elID).classList.remove('correct');
  } else {
    id(elID).classList.add('correct');
    id(elID).classList.remove('incorrect');
  }
};

export const valE = (email) => {
  const regx = /^([a-zA-Z0-9\.\-_]+)@([a-zA-Z0-9-]+)(.[ujUJ]{2})?\.([a-zA-Z]{2,20})(\.[a-zA-Z]{2,10})?$/;
  return regx.test(email) ? true : false;
};

export const valP = (num, inpID) => {
  num = isNaN(num) ? num : num.toString();
  num = num.split(' ').join('');

  const parentElement = document.getElementById(`${inpID}`).parentElement;
  const cc = parentElement.getElementsByClassName('countryCodes');

  let regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  // COUNRTY PACIFIC REGEX
  if (cc[0]) {
    console.log('cc[0]', cc[0].value);
    if (cc[0].value == 65) {
      // Singapore number
      regex = /^[689]\d{7}$/;
    }
  }

  return regex.test(num) ? true : false;
};

export const saIDVal = (elID) => {
  const feedback = `${elID}_FB`;
  const IDnum = id(elID).value;

  if (valIDsa(IDnum)) {
    id(elID).classList.remove('incorrect');
    id(feedback).innerHTML = '';
    hide(feedback);
  } else {
    id(elID).classList.add('incorrect');
    id(feedback).innerHTML = 'Invalid ID Number';
    unhide(feedback);
  }
};
window.saIDVal = saIDVal;

export const valIDsa = (IDnum) => {
  const regx = /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/gm;
  return regx.test(IDnum) ? true : false;
};

export const formatYearMonthDay = (date) => {
  let newArr = [];
  newArr.push(date.split('/'));
  let newDate = `${newArr[0][2]}-${newArr[0][1]}-${newArr[0][0]}`;
  return newDate;
};

export const valDOB = (date) => {
  const regx = 'DOB Regex';
  const formatDate = formatYearMonthDay(date);
  return regx.test(formatDate) ? true : false;
};

export const passwordReminder = () => {
  btnModalOn('forgotPassBtn');

  const email = id('f_username').value;
  const emailInput = id('f_username');
  // const feedback = id('feedback');

  const vars = {};
  vars.emailAddress = email;

  if (valE(email)) {
    PREP(['passwordReminder'], vars)
      .then((res) => {
        btnModalOff('forgotPassBtn');
        infoBox(res.data.passwordReminder);
      })
      .catch(console.error());
  } else {
    btnModalOff('forgotPassBtn');
    emailInput.classList.add('incorrect');
    errBox('This is not a valid email address');
    // unhide('warningSVG');
    // hide('succSVG')
  }
};
window.passwordReminder = passwordReminder;

export let addToUserGroupBtnYN = false;
export let selectGroupYN = false;

export const toggleSelectsection = (arr) => {
  const obj = {};
  obj.activeYN = id('activeYN');
  obj.clientSelect = id('clientSelect');
  obj.selectGroup = id('selectGroup');
  obj.privateGroup = id('privateGroup');
  obj.newGroupNameDiv = id('newGroupNameDiv');
  obj.newGroupDescription = id('newGroupDescription');
  obj.addToUserGroup = id('addToUserGroup');

  // Define getGroupsListener function in the outer scope
  function getGroupsListener() {
    if (id('clientDD').value) {
      getGroups();
    }
  }

  hide('selectC');
  if (arr.includes('company')) {
    getCompanyName();
    unhide('company');
  } else {
    hide('company');
  }

  addToUserGroupBtnYN = arr.includes('addToUserGroupBtn') ? true : false;
  selectGroupYN = arr.includes('selectGroup') ? true : false;
  if (arr.includes('selectGroup')) {
    id('clientDD').addEventListener('change', getGroupsListener);

    if (id('clientDD')) {
      getGroups();
    }
  } else {
    id('clientDD').removeEventListener('change', getGroupsListener);
  }

  for (const key in obj) {
    if (arr.length) {
      if (arr.includes(key)) {
        if (id(key)) {
          unhide(key);
        }

        unhide('selectC');
      } else {
        if (id(key)) {
          hide(key);
        }
      }
    }
  }
};

export const getGroups = () => {
  if (id('clientDD') === '-' || !id('clientDD')) {
    hide('selectGroup');
    hide('privateGroup');
    hide('newGroupNameDiv');
    hide('newGroupDescription');
    hide('addToUserGroup');
  } else {
    if (selectGroupYN) {
      unhide('groupDD');
    } else {
      hide('groupDD');
    }

    if (id('groupDD').value !== '-') {
      if (addToUserGroupBtnYN) {
        unhide('addToUserGroup');
      } else {
        hide('addToUserGroup');
      }

      // if (id('groupDD').value == 'new') {
      //   unhide('privateGroup');
      //   unhide('newGroupNameDiv');
      //   unhide('newGroupDescription');
      // } else {
      //   hide('privateGroup');
      //   hide('newGroupNameDiv');
      //   hide('newGroupDescription');
      newGroupHideShow();

      // }
    } else {
      hide('addToUserGroup');
    }
  }

  if (id('clientDD').value) {
    PREP(['getUserGroups'], {
      hclientID: id('clientDD').value,
    })
      .then((res) => {
        const groups = cObj(res.data.getUserGroups);
        const obj = {};
        obj.HUSERGROUPID = 'new';
        obj.SHORTNAME = 'Create new';
        groups.push(obj);
        buildDropDownMain(groups, 'groupDD', 'HUSERGROUPID', 'SHORTNAME');
        unhide('selectGroup');
        id('groupDD').addEventListener('change', newGroupHideShow);
        newGroupHideShow();
      })
      .catch(console.error());
  } else {
    hide('selectGroup');
    hide('privateGroup');
    hide('newGroupNameDiv');
    hide('newGroupDescription');
    hide('addToUserGroup');
    id('groupDD').removeEventListener('change', newGroupHideShow);
  }
};

const newGroupHideShow = () => {
  if (id('groupDD').value == 'new') {
    unhide('privateGroup');
    unhide('newGroupNameDiv');
    unhide('newGroupDescription');
  } else {
    hide('privateGroup');
    hide('newGroupNameDiv');
    hide('newGroupDescription');
  }
};

export const ExcelToJSON = () => {
  this.parseExcel = function (file) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: 'binary',
      });
      workbook.SheetNames.forEach(function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        console.log();
        return XL_row_object;
        // buildEmployeeTable(XL_row_object)
      });
    };
    reader.onerror = function (ex) {
      return console.log(ex);
    };
    reader.readAsBinaryString(file);
  };
};

export const fetchMethods = async (recipe, headers) => {
  const methods = recipe.methods;
  let data = {};
  if (methods.length) {
    await Promise.all(
      methods.map(async (method) => {
        if (method.isFile) {
          const responseB = await fetchFile(recipe, headers, method);
          data[`${method.method}_SC`] = responseB.statusCode;
          data[`${method.method}_ST`] = responseB.statusText;
          data[method.method] = responseB.data;
        } else {
          const responseB = await fetchMethod(recipe, headers, method);
          data[`${method}_SC`] = responseB.statusCode;
          data[`${method}_ST`] = responseB.statusText;
          data[method] = responseB.data;
        }
      })
    );
    return data;
  } else {
    return 'Method Array Empty';
  }
};

export function fetchMethod(recipe, headers, method) {
  return new Promise((resolve, reject) => {
    let res;
    fetch(`${recipe.endPoint}${method}`, {
      method: recipe.type,
      body: headers,
    })
      .then((response) => {
        if (response.status === 500) {
          reject(`Failed to fetch method=${method}`);
        } else if (response.status === 401) {
          reject(response.statusText);
        } else if (response.status === 402) {
          reject(response.statusText);
        } else if (response.status === 409) {
          reject(response.statusText);
        } else if (response.status === 400) {
          reject(response.statusText);
        } else {
          res = response;
          return response.json();
        }
      })
      .then((parseData) => {
        resolve({
          data: parseData,
          statusCode: res.status,
          statusText: res.statusText,
        });
      })
      .catch(console.error());
  });
}

export function fetchFile(recipe, headers, method) {
  return new Promise((resolve, reject) => {
    let res;
    fetch(`${recipe.endPoint}${method.method}`, {
      method: recipe.type,
      body: headers,
    })
      .then((response) => {
        if (response.status === 500) {
          reject(`Failed to fetch method=${method}`);
        } else if (response.status === 401) {
          reject(response.statusText);
        } else if (response.status === 402) {
          reject(response.statusText);
        } else if (response.status === 409) {
          reject(response.statusText);
        } else if (response.status === 400) {
          reject(response.statusText);
        } else {
          res = response;
          return response.arrayBuffer();
        }
      })
      .then((parseData) => {
        downloadFile(parseData, method.fileName);
        resolve({
          data: true,
          statusCode: res.status,
          statusText: res.statusText,
        });
      })
      .catch(console.error());
  });
}

export const downloadFile = (ab, fileName) => {
  const byteArray = new Uint8Array(ab);
  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(
    new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  );
  a.download = `${fileName}.XLSX`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function fetchHTML(pageName) {
  return new Promise((resolve, reject) => {
    const html = `${prefix}${pageName}.html`;
    fetch(html).then((response) => {
      if (response.status === 404) {
        reject(`404 - ${html} not found`);
      } else {
        window.scrollTo(0, 0);
        const page = {};
        page.ID = 1;
        page.page = pageName;
        updDataS('currentPage', page);
        resolve(response.text());
      }
    });
  });
}

export function fetchTabs(recipe, headers) {
  return new Promise((resolve, reject) => {
    const infoText = window.infoText;
    fetch(`${recipe.endPoint}getTabs`, {
      method: recipe.type,
      body: headers,
    })
      .then((res) => {
        if (res.status === 401) {
          reject(res.statusText);
        } else if (res.status === 500) {
          const errMsg1 = infoText.inf_Mkr49 ? infoText.inf_Mkr49 : 'Server Error: Unable to fetch Tabs';
          reject(errMsg1);
        } else {
          return res.json();
        }
      })
      .then((resData) => {
        if (!resData.length) {
          const errMsg = infoText.inf_Mkr48 ? infoText.inf_Mkr48 : 'No Tabs have been allocated for this page';
          reject(errMsg);
        } else {
          resolve(resData);
        }
      });
  });
}

export function fetchTabTrans(recipe, headers) {
  return new Promise((resolve, reject) => {
    fetch(`${recipe.endPoint}getTabTranslation`, {
      method: recipe.type,
      body: headers,
    })
      .then((res) => {
        if (res.status === 401) {
          reject(res.statusText);
        } else if (res.status === 500) {
          reject('Server Error: Unable to fetch Tabs');
        } else {
          return res.json();
        }
      })
      .then((resData) => {
        if (!resData.length) {
          reject('No Tabs have been allocated for this page');
        } else {
          resolve(resData);
        }
      });
  });
}

export function getAPIHeaders1() {
  return new Promise((resolve, reject) => {
    var authObj = {};
    getDataS('deviceProfile', 1)
      .then(function (deviceDets) {
        authObj['deviceUUID'] = deviceDets['deviceUUID'];
        getDataS('userProfile', 1)
          .then(function (profileDets) {
            authObj['HuserID'] = profileDets['HuserID'];
            authObj['currentUUID'] = profileDets['currentUUID'];
          })
          .then(() => {
            resolve(authObj);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function cfd(headers, obj) {
  return new Promise((resolve, reject) => {
    const keyExceptions = ['idNumber', 'idNum'];

    if (obj.constructor !== Object) {
      reject('There was an error parsing variables ');
    } else {
      const vars = {
        ...headers,
        ...obj,
      };
      const nfd = new FormData();
      for (const key in vars) {
        if (vars.hasOwnProperty(key)) {
          if (key === 'ewttgpvwwkf') {
            nfd.append('UUID', vars[key]);
          } else {
            isNum(vars[key]) && !keyExceptions.includes(key) && (vars[key] = parseInt(vars[key]));
            nfd.append(key, vars[key]);
          }
        }
      }
      resolve(nfd);
    }
  });
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

// function fetchLang(pageName) {
//   return new Promise((resolve, reject) => {

//     PREP(['getHTMLLexicon'], {
//         HTMLPageName: pageName
//       })
//       .then(res => {
//         console.log('res: ', res)
//       }).catch(console.error())
//     // const html = `${prefix}${pageName}.html`
//     // fetch(html)
//     //   .then(response => {
//     //     if (response.status === 404) {
//     //       reject(`404 - ${html} not found`);
//     //     } else {
//     //       window.scrollTo(0, 0);
//     //       const page = {};
//     //       page.ID = 1;
//     //       page.page = pageName;
//     //       updDataS('currentPage', page);
//     //       resolve(response.text());
//     //     }
//     //   })
//   })
// }

export function buildClientList(data, value, text, elID) {
  const el = id(elID);
  el.options.length = '';
  let optOne;
  const infoText = window.infoText;
  if (data.length) {
    optOne = divAtt('option', 'value', '');
    optOne.innerHTML = infoText.inf_Mkr53 ? infoText.inf_Mkr53 : 'Select Client';
    el.appendChild(optOne);
    data.forEach((arr) => {
      const opt = divAtt('option', 'value', arr[`${value}`]);
      opt.innerHTML = arr[text];
      el.appendChild(opt);
    });
  } else {
    optOne = divAtt('option', 'value', '-');
    optOne.innerHTML = 'No Clients available --';
    el.appendChild(optOne);
  }
}

export const translatePage = (data) => {
  if (data.length) {
    for (let row of data) {
      const nodes = document.querySelectorAll(`.${row.marker}`);
      if (nodes.length) {
        nodes.forEach((node) => {
          node.innerHTML = row.display;
        });
      }
    }
  }
};

export const parseIntObj = (obj) => {
  for (let key in obj) {
    isNum(obj[key]) && (obj[key] = parseInt(obj[key]));
  }
  return obj;
};

export const urlToObj = async (url) => {
  const request = new Object();
  if (url != undefined) {
    const pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (let i = 0; i < pairs.length; i++) {
      if (pairs[i]) {
        const pair = pairs[i].split('=');
        request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    }
    return request;
  }
};

export const getRecipe = async () => {
  return {
    endPoint: apiURLSK,
    methods: [],
    switch: 'default',
    type: 'POST',
    chain: '',
    tabs: 0,
    div: 'mainContainer',
    scroll: 1,
  };
};

export const cObj = (data) => {
  const list = new Array();
  if (data) {
    for (var d = 0; d < data.DATA.length; d++) {
      var obj = new Object();
      for (var h = 0; h < data.DATA[d].length; h++) {
        obj[data.COLUMNS[h]] = data.DATA[d][h];
      }
      list.push(obj);
    }
  }
  return list;
};

export const checkPassReq = (currID, confID) => {
  const currVal = id(currID).value.trim();
  let status = false;

  if (confID) {
    const confVal = id(confID).value.trim();
    if (currVal !== '' && confVal !== '') {
      status = checkPass(currID, confID);
    }
  } else {
    if (currVal !== '') {
      status = checkPass(currID, confID);
    }
  }
  return status;
};

export const checkPass = (currID, confID) => {
  const currVal = id(currID).value.trim();
  let status = false;
  if (confID) {
    const confVal = id(confID).value.trim();
    if ((confirmPass(currVal, confVal) && valPass(currVal)) || (confVal === '' && currVal === '')) {
      status = true;
    }
  } else {
    if (valPass(currVal)) {
      status = true;
    }
  }
  return status;
};

export const valPass = (pass) => {
  let status = true;
  if (pass.trim()) {
    status = passRgx(pass);
  }
  return status;
};

export const passRgx = (pass) => {
  const regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{14,}$/;
  return regx.test(pass) ? true : false;
};

export const lowCase = (pass) => {
  const regx = /[a-z]+/g;
  return regx.test(pass) ? true : false;
};

export const upCase = (pass) => {
  const regx = /[A-Z]+/g;
  return regx.test(pass) ? true : false;
};

export const minChar = (pass) => {
  const regx = /[A-Za-z\d@$!%*?&]{14,}$/;
  return regx.test(pass) ? true : false;
};

export const isNum = (pass) => {
  const regx = /^\d+$/g;
  return regx.test(pass) ? true : false;
};
export const containsNum = (pass) => {
  const regx = /\d/;
  return regx.test(pass) ? true : false;
};

export const specialChar = (pass) => {
  const regx = /[@$!%*?&#]/;
  return regx.test(pass) ? true : false;
};

export const confirmPass = (curr, conf) => {
  return curr !== conf ? false : true;
};

export const inpPass = (inpID) => {
  const pass = id(inpID).value;
  const tick = `list-style-image: url(tick.svg);`;
  const cross = 'list-style-image: url(cross.svg);';

  id('lowCase').style = lowCase(pass) ? tick : cross;
  id('upCase').style = upCase(pass) ? tick : cross;
  id('minChar').style = minChar(pass) ? tick : cross;
  id('num').style = containsNum(pass) ? tick : cross;
  id('special').style = specialChar(pass) ? tick : cross;

  id('f_confirm_password').value && matchPass(inpID, 'f_confirm_password');

  if (!valPass(pass)) {
    !id(inpID).classList.contains('incorrect') && id(inpID).classList.add('incorrect');
    id(inpID).classList.contains('correct') && id(inpID).classList.remove('correct');
    unhide('passValBox');
  } else {
    !id(inpID).classList.contains('correct') && id(inpID).classList.add('correct');
    id(inpID).classList.contains('incorrect') && id(inpID).classList.remove('incorrect');
    hide('passValBox');
  }
};
window.inpPass = inpPass;

export const validatePasswordConfirmation = (field1, field2) => {
  matchPass(field1, field2);
  const changePasswordButton = document.getElementById("changePassBoxBtn");
  changePasswordButton.setAttribute("disabled", "true");

  if (passRgx(id(field1).value) && (id(field1).value === id(field2).value)) {
    changePasswordButton.removeAttribute("disabled");
  }
}
window.validatePasswordConfirmation = validatePasswordConfirmation;

export const removeIncorrect = (inpID) => {
  id(inpID).classList.remove('incorrect');
};
window.removeIncorrect = removeIncorrect;

export const capsLock = (inpID) => {
  const el = id(inpID);
  el.addEventListener('keydown', (e) => {
    // e.getModifierState("CapsLock") ? unhide("capsLock") : hide("capsLock");
    if (e.getModifierState && typeof e.getModifierState === 'function' && e.getModifierState('CapsLock')) {
      unhide('capsLock');
    } else {
      hide('capsLock');
    }
  });
};
window.capsLock = capsLock;

export const matchPass = (inpID1, inpID2) => {
  const pass1 = id(inpID1).value;
  const pass2 = id(inpID2).value;

  if (pass1 != pass2) {
    !id(inpID2).classList.contains('incorrect') && id(inpID2).classList.add('incorrect');
    id(inpID2).classList.contains('correct') && id(inpID2).classList.remove('correct');
    unhide('passMatchBox');
  } else {
    !id(inpID2).classList.contains('correct') && id(inpID2).classList.add('correct');
    id(inpID2).classList.contains('incorrect') && id(inpID2).classList.remove('incorrect');
    hide('passMatchBox');
  }
};
window.matchPass = matchPass;

export const viewPass = (inpID, eyeID) => {
  const input = id(inpID);
  const eye = id(eyeID);
  if (input.type === 'password') {
    input.type = 'text';
    eye.classList.add('iconOn');
  } else {
    input.type = 'password';
    eye.classList.remove('iconOn');
  }
};
window.viewPass = viewPass;

export const hidePassBox = () => {
  hide('passValBox');
  hide('passMatchBox');
};
window.hidePassBox = hidePassBox;

export const sanatizeNum = async (num, prefix) => {
  const numToString = isNaN(num) ? num : num.toString();
  let number = numToString.replace(/\D/g, '');
  number = number.charAt(0) == '0' ? number.substring(1) : number;
  number = prefix && number != '' ? prefix + number : '';
  return number;
};

export const buildDropDownMain = (data, elID, value, text) => {
  const split = text.split('+');
  const el = id(elID);
  const infoText = window.infoText;
  el.options.length = '';
  let optOne;

  if (data.length) {
    optOne = divAtt('option', 'value', '-');
    optOne.innerHTML = infoText.inf_Mkr10 ? infoText.inf_Mkr10 : ' Please choose an option---';
    el.appendChild(optOne);
    data.forEach((arr) => {
      const opt = divAtt('option', 'value', arr[`${value}`]);
      const desc = [];
      split.forEach((piece) => {
        desc.push(arr[piece]);
      });
      opt.innerHTML = desc.join(' ');
      el.appendChild(opt);
    });
  } else {
    optOne = divAtt('option', 'value', '-');
    optOne.innerHTML = 'No options available --';
    el.appendChild(optOne);
  }
};

export const buildDList = (data, elID, value) => {
  const el = id(elID);
  el.innerHTML = '';

  if (data.length) {
    for (let row of data) {
      const opt = divAtt('option', 'value', row[value]);
      el.appendChild(opt);
    }
  }
};

export const hide = (elID) => {
  const el = document.getElementById(elID);
  if (!el.classList.contains('hide')) {
    el.classList.add('hide');
  }
};
window.hide = hide;

export const unhide = (elID) => {
  const el = document.getElementById(elID);
  if (el.classList.contains('hide')) {
    el.classList.remove('hide');
  }
};
window.unhide = unhide;

export const toggleHide = (elID) => {
  const el = id(elID);
  if (el.classList.contains('hide')) {
    el.classList.remove('hide');
  } else {
    el.classList.add('hide');
  }
};
window.toggleHide = toggleHide;

export const uuidvMain = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const toggleSliderYN = (val) => {
  if (val === true) {
    return 1;
  } else if (val === false) {
    return 0;
  } else if (val === 1) {
    return true;
  } else if (val === 0) {
    return false;
  } else if (val === '') {
    return false;
  }
};

export const note = (message) => {
  const infoText = window.infoText;
  const success = infoText.inf_Mkr1 ? infoText.inf_Mkr1 : 'Success';
  const un = uuidvMain();
  const alertBanner = id('alertBanner');
  const msg = message ? `- ${message}` : '';
  const banner = divAtt('div', 'class', 'notification');
  banner.setAttribute('id', `banner_${un}`);
  const imgDiv = divAtt('div', 'class', 'noteIcon');
  imgDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25.2" height="25.2" viewBox="0 0 25.2 25.2"><path d="M13,.4A12.6,12.6,0,1,0,25.6,13,12.6,12.6,0,0,0,13,.4ZM10.9,18.9,5,13.3l1.5-1.6,4.4,4.2,8.6-8.8L21,8.6Z" transform="translate(-0.4 -0.4)"/></svg>`;
  const msgHdr = divAtt('div', 'class', '');
  msgHdr.innerHTML = `<p><strong>${success}</strong> ${msg}</p>`;
  msgHdr.style.marginRight = '10px';
  const btn = divAtt('div', 'onclick', `hide('banner_${un}')`);
  btn.innerHTML = '&#10005';
  btn.setAttribute('class', 'noteX');

  banner.appendChild(imgDiv);
  banner.appendChild(msgHdr);
  banner.appendChild(btn);
  alertBanner.appendChild(banner);

  setTimeout(() => {
    alertBanner.removeChild(banner);
  }, 6000);
};

export const eListen = (elID, callback, eventType) => {
  const oldNode = typeof elID === 'string' ? id(elID) : elID;
  const newNode = oldNode.cloneNode(true);
  let replace = false;
  if (Array.isArray(callback) && Array.isArray(eventType)) {
    replace = true;
    for (let i = 0; i < callback.length; i++) {
      const singleCallback = callback[i];
      const singleEvent = eventType[i];
      newNode.addEventListener(singleEvent, () => {
        singleCallback();
      });
      // if(examineNoTouchyEvents(eventType[i].toString())){}
    }
  } else {
    const evnt = eventType ? eventType : 'change';
    if (examineNoTouchyEvents(callback.toString())) {
      //keep oldNode(with its events) & add noTouchy event to it
      oldNode.addEventListener(evnt, () => {
        callback();
      });
    } else {
      //not a noTouchyEvent, replace oldNode(with its events) & append newNode
      replace = true;
      newNode.addEventListener(evnt, () => {
        callback();
      });
    }
  }

  if (replace) {
    oldNode.parentNode ? oldNode.parentNode.replaceChild(newNode, oldNode) : oldNode.replaceWith(newNode, oldNode);
    //oldNode.parentNode.replaceChild(newNode, oldNode);
  }
  // console.log('END ELISTEN',elID,callback);
};

export const examineNoTouchyEvents = (funcString) => {
  const arrayValues = Object.values(notTouchyELs);
  let found = false;
  arrayValues.forEach((value) => {
    const regex = new RegExp(value, 'g');
    if (regex.test(funcString)) {
      found = true;
    }
  });
  if (found) {
    return true;
  } else {
    return false;
  }
};

export const errBox = (msg) => {
  const page = id('popupBox');
  page.innerHTML = '';
  const div = divAtt('div', 'class', 'popupMsg');
  const infoText = window.infoText;
  const svg = divAtt('div', 'class', 'popIconErr');
  svg.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="83.7" height="83.8" viewBox="0 0 83.7 83.8"><g id="Layer_1-2" data-name="Layer 1-2"><path d="M41.9,0a41.9,41.9,0,1,0,41.8,41.9A41.82,41.82,0,0,0,41.9,0Zm15.4,61.9L41.9,46.55,26.5,62.05l-4.7-4.7,15.3-15.5L21.7,26.45l4.7-4.7,15.5,15.3,15.3-15.5,4.8,4.7L46.6,41.75l15.5,15.3Z" transform="translate(0 0.05)"/></g></svg>`;
  const h = divAtt('h3', 'class', 'msgHeading');
  h.style.color = 'var(--errorColor)';
  h.innerHTML = infoText.inf_Mkr3 ? infoText.inf_Mkr3 : 'Error';
  const p = divAtt('p', 'class', '');
  p.innerHTML = `${msg}`;
  const br = document.createElement('br');
  const btn = divAtt('button', 'class', 'errBtn');
  btn.setAttribute('onclick', `hide('popupBox')`);
  btn.style.width = '150px';
  btn.innerHTML = infoText.inf_Mkr17 ? infoText.inf_Mkr17 : 'Okay';
  const div2 = divAtt('div', 'class', 'overlay');

  div.appendChild(svg);
  div.appendChild(h);
  div.appendChild(p);
  div.appendChild(br);
  div.appendChild(btn);
  page.appendChild(div);
  page.appendChild(div2);
  unhide('popupBox');
};

export const infoBox = (msg) => {
  const page = id('popupBox');
  const infoText = window.infoText;
  page.innerHTML = '';
  const div = divAtt('div', 'class', 'popupMsg');
  div.style.borderColor = 'var(--primaryColor)';
  const svg = divAtt('div', 'class', 'popIconInfo');
  svg.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="83.38" height="83.38" viewBox="0 0 83.38 83.38"><path d="M41.85.16A41.69,41.69,0,1,0,83.54,41.85,41.68,41.68,0,0,0,41.85.16Zm.06,21a5.17,5.17,0,1,1-5.23,5.15A5.22,5.22,0,0,1,41.91,21.15Zm7.63,41.38q-7.68,0-15.36,0A2.56,2.56,0,0,1,31.51,60a2.6,2.6,0,0,1,2.71-2.61h2.4V41.84H34.31a2.59,2.59,0,1,1,0-5.17c3.26,0,6.52,0,9.78,0A2.62,2.62,0,0,1,47,39.57c0,5.6,0,11.21,0,16.81v1c.92,0,1.72,0,2.53,0A2.59,2.59,0,0,1,52.19,60,2.56,2.56,0,0,1,49.54,62.53Z" transform="translate(-0.16 -0.16)"/></svg>`;
  const h = divAtt('h3', 'class', 'msgHeading');
  h.innerHTML = infoText.inf_Mkr2 ? infoText.inf_Mkr2 : 'Information';
  const p = divAtt('p', 'class', '');
  p.innerHTML = `${msg}`;
  const br = document.createElement('br');
  const btn = divAtt('button', 'class', 'btn');
  btn.setAttribute('id', 'infoBoxBtn');
  btn.setAttribute('onclick', `hide('popupBox')`);
  btn.style.width = '150px';
  btn.innerHTML = infoText.inf_Mkr17 ? infoText.inf_Mkr17 : 'Okay';
  const div2 = divAtt('div', 'class', 'overlay');

  div.appendChild(svg);
  div.appendChild(h);
  div.appendChild(p);
  div.appendChild(br);
  div.appendChild(btn);
  page.appendChild(div);
  page.appendChild(div2);
  unhide('popupBox');
};

export const warningBox = (msg, onclickFun, proceedCancel) => {
  const page = id('popupBox');
  const infoText = window.infoText;
  page.innerHTML = '';
  const div = divAtt('div', 'class', 'popupMsg');
  div.style.borderColor = 'var(--warningColor)';
  const svg = divAtt('div', 'class', 'popIconInfo');
  svg.innerHTML = ` <svg id="warningSVG" xmlns="http://www.w3.org/2000/svg" width="27.61" height="23.73"
                viewBox="0 0 27.61 23.73">
                <defs>
                    <style>
                        .red1 {
                            fill: #ff9616;
                        }

                        .white1 {
                            fill: #fff;
                        }
                    </style>
                </defs>
                <path class="red1"
                    d="M27.21,19.64,16,1.22a2.49,2.49,0,0,0-3.4-.88,2.56,2.56,0,0,0-.88.88L.4,19.64a2.78,2.78,0,0,0-.09,2.71,2.54,2.54,0,0,0,2.23,1.38H25.07a2.52,2.52,0,0,0,2.23-1.38A2.76,2.76,0,0,0,27.21,19.64Z"
                    transform="translate(0 0)" />
                <path class="white1"
                    d="M13.92,15.49a1.4,1.4,0,0,0,1.4-1.4V7.8a1.4,1.4,0,0,0-2.8,0v6.29A1.4,1.4,0,0,0,13.92,15.49Z"
                    transform="translate(0 0)" />
                <path class="white1" d="M15.64,19a1.84,1.84,0,1,0-1.75,1.91h0A1.79,1.79,0,0,0,15.64,19Z"
                    transform="translate(0 0)" />
            </svg>`;
  const h = divAtt('h3', 'class', 'msgHeading');
  h.innerHTML = infoText.inf_Mkr50 ? infoText.inf_Mkr50 : 'Warning';
  h.style = 'color: var(--warningColor);';
  const p = divAtt('p', 'class', '');
  p.innerHTML = `${msg}`;
  const br = document.createElement('br');

  const btn = divAtt('button', 'class', 'btn');
  btn.setAttribute('id', 'infoBoxBtn');
  btn.setAttribute('onclick', `hide('popupBox')`);
  if (onclickFun) {
    eListen(
      btn,
      () => {
        onclickFun;
      },
      'click'
    );
  }

  btn.style = 'background-color: var(--warningColor); min-width:unset;';
  btn.innerHTML = infoText.inf_Mkr17 ? infoText.inf_Mkr17 : 'Okay';
  const div2 = divAtt('div', 'class', 'overlay');

  div.appendChild(svg);
  div.appendChild(h);
  div.appendChild(p);
  div.appendChild(br);

  if (proceedCancel) {
    const btnDiv = divAtt('div', 'class', 'gridSpan btnCenterGrid');
    const btn2 = divAtt('button', 'class', 'btnCancel warningColor');
    btn2.id = 'warningCancelBtn';
    btn2.setAttribute('onclick', `hide('popupBox')`);
    btn2.style = 'border-color: var(--warningColor); min-width:unset;';
    btn2.innerHTML = 'Cancel';
    btn.innerHTML = 'Proceed';
    btnDiv.appendChild(btn2);
    btnDiv.appendChild(btn);
    div.appendChild(btnDiv);
  } else {
    div.appendChild(btn);
  }
  page.appendChild(div);
  page.appendChild(div2);
  unhide('popupBox');
};

export const custardBox = (html, heading) => {
  const page = id('custardContent');
  page.innerHTML = '';
  const h = divAtt('h3', 'class', 'custardHeading');
  h.innerHTML = heading;
  const content = divAtt('div', 'class', '');
  content.appendChild(html);
  page.appendChild(h);
  page.appendChild(content);
  //eListen
  unhide('custardBox');
};

export const eCustardBox = async (html, heading, evnts) => {
  //eCustardBox(div, `Reason For Removing Report` , [btn,()=>{report.remove(`${reportRegisterID}`)},"click"]);
  const page = id('custardContent');
  page.innerHTML = '';
  const h = divAtt('h3', 'class', 'custardHeading');
  h.innerHTML = heading;
  const content = divAtt('div', 'class', '');
  content.appendChild(html);
  page.appendChild(h);
  page.appendChild(content);

  if (evnts.length > 0) {
    if (Array.isArray(evnts[0])) {
      for (let i = 0; i < evnts.length; i++) {
        const [elem, evnt, evntType] = evnts[i];
        eListen(elem, evnt, evntType);
      }
    } else {
      const [elem, evnt, evntType] = evnts;
      eListen(elem, evnt, evntType);
    }
  }
  unhide('custardBox');
};

export const countryCode = (cellNum, clientID, ccID, inpID) => {
  const cellNumString = isNaN(cellNum) ? cellNum : cellNum.toString();
  const number = cellNumString.replace(/\D/g, '');

  if (number.charAt(0) == '0') {
    if (!isNaN(number.substring(1))) {
      ccRegex('27' + number.substring(1), clientID, ccID, inpID);
    }
  } else {
    if (!isNaN(number)) {
      ccRegex(number, clientID, ccID, inpID);
    } else {
      ccRegex('', clientID, ccID, inpID);
    }
  }
};

export const ccRegex = (number, clientID, ccID, inpID) => {
  const defalut = [
    {
      ALLOCMOBILEPREFIXHCLIENTID: 14,
      DDMOBILEPREFIXID: 245,
      PREFIXMASK: '+27',
      PREFIXDISPLAY: '27',
      COUNTRYREGION: 'South Africa',
      COUNTRYREGIONABBR: 'ZA',
      ICON: '\\u{1F1FF}\\u{1F1E6}',
    },
  ];
  // let currPage;
  // getDataS('currentPage', 1)
  //   .then(data => {
  //     currPage = data.page;
  //   })

  PREP(['getMobilePrefixByClient'], {
    HclientID: clientID,
  })
    .then((res) => {
      let countries = cObj(res.data.getMobilePrefixByClient);
      const countryCodes = ccID ? id(`${ccID}`) : id('countryCodes');
      const numInpt = inpID ? id(`${inpID}`) : id('f_cellNr');
      // countryCodes.innerHTML = '';

      class RegExp1 extends RegExp {
        [Symbol.replace](str) {
          return RegExp.prototype[Symbol.replace].call(this, str, '');
        }
      }

      countries = countries.length ? countries : defalut;
      // let isValid = false;
      countryCodes.innerHTML = '';
      if (countries.length >= 100) {
        // deal with more than 100
        for (var i = 0; i < countries.length; i++) {
          const country = countries[i];
          const regexp = new RegExp(`^${country.PREFIXDISPLAY}`);
          const opt = divAtt('option', 'value', country.PREFIXDISPLAY);
          const flag = formatEmoji(country.ICON);
          opt.innerHTML = `${flag}  ${country.PREFIXMASK}`;

          countryCodes.appendChild(opt);
          if (regexp.test(number)) {
            countryCodes.value = country.PREFIXDISPLAY;
            const result = number.replace(new RegExp1(`^${country.PREFIXDISPLAY}`));
            numInpt.value = result;
          }
        }
      } else {
        for (let country of countries) {
          const regexp = new RegExp(`^${country.PREFIXDISPLAY}`);
          const opt = divAtt('option', 'value', country.PREFIXDISPLAY);
          const flag = formatEmoji(country.ICON);
          opt.innerHTML = `${flag}  ${country.PREFIXMASK}`;

          countryCodes.appendChild(opt);
          if (regexp.test(number)) {
            countryCodes.value = country.PREFIXDISPLAY;
            const result = number.replace(new RegExp1(`^${country.PREFIXDISPLAY}`));
            numInpt.value = result;
            // isValid = true;
          }
        }
      }

      // const pageArr = ['AUt_addUser', 'VAt_nominate', 'VAt_choose', 'jobSpec', 'MJt_jobSpecs', 'MJt_applications', 'EUt_profile'];

      // if (!isValid && !pageArr.includes(currPage)) {
      //   infoBox('Please update your mobile number.')
      // }
    })
    .catch(console.error());
};

export const ccRegexMulti = (number, clientID) => {
  const defalut = [
    {
      ALLOCMOBILEPREFIXHCLIENTID: 14,
      DDMOBILEPREFIXID: 245,
      PREFIXMASK: '+27',
      PREFIXDISPLAY: '27',
      COUNTRYREGION: 'South Africa',
      COUNTRYREGIONABBR: 'ZA',
      ICON: '\\u{1F1FF}\\u{1F1E6}',
    },
  ];

  PREP(['getMobilePrefixByClient'], {
    HclientID: clientID,
  })
    .then((res) => {
      let countries = cObj(res.data.getMobilePrefixByClient);
      const countryCodes = document.querySelectorAll('.countryCodes');

      class RegExp1 extends RegExp {
        [Symbol.replace](str) {
          return RegExp.prototype[Symbol.replace].call(this, str, '');
        }
      }

      // let isValid = false;
      countryCodes.forEach((elem) => {
        elem.innerHTML = '';
        countries = countries.length ? countries : defalut;
        for (let country of countries) {
          const regexp = new RegExp(`^${country.PREFIXDISPLAY}`);
          const opt = divAtt('option', 'value', country.PREFIXDISPLAY);
          const flag = formatEmoji(country.ICON);
          opt.innerHTML = `${flag}  ${country.PREFIXMASK}`;

          elem.appendChild(opt);
          if (regexp.test(number)) {
            id('countryCodes').value = country.PREFIXDISPLAY;
            const result = number.replace(new RegExp1(`^${country.PREFIXDISPLAY}`));
            id('f_cellNr').value = result;
            // isValid = true;
          }
        }
      });

      // if (!isValid) {
      //   infoBox('Please update your mobile number.')
      // }
    })
    .catch(console.error());
};

export const dragDrop = (callback) => {
  const bar = document.querySelectorAll('.draggable');
  const dropZone = document.querySelectorAll('.dropZone');

  /* Adding and removing .dragging class on draggable element */
  bar.forEach((draggable) => {
    draggable.addEventListener('dragstart', () => {
      draggable.classList.add('dragging');
    });
    draggable.addEventListener('dragend', () => {
      draggable.classList.remove('dragging');
      callback && callback();
    });
  });
  /* dropping within the container/DropZone div */
  dropZone.forEach((zone) => {
    zone.addEventListener('dragover', (e) => {
      e.preventDefault(); //enable dropping
      const afterElement = afterDragElement(zone, e.clientY); // element after with Y position
      const draggable = document.querySelector('.dragging');
      afterElement ? zone.insertBefore(draggable, afterElement) : zone.appendChild(draggable);
    });
  });
};

export const afterDragElement = (dropZone, y) => {
  /* Determine the position of the drag */
  const draggableElements = [...dropZone.querySelectorAll('.draggable:not(.dragging)')];

  return draggableElements.reduce(
    (closestAfterElement, child) => {
      const box = child.getBoundingClientRect();
      const offSet = y - box.top - box.height / 2; // distance between center of drag element and mouse cursor
      if (offSet < 0 && offSet > closestAfterElement.offset) {
        return {
          offSet: offSet,
          element: child,
        };
      } else {
        return closestAfterElement;
      }
    },
    {
      offset: Number.NEGATIVE_INFINITY,
    }
  ).element;
};

export const invisible = (eli) => {
  const el = id(eli);
  if (!el.classList.contains('invisible')) {
    el.classList.add('invisible');
  }
};

export const uninvisible = (eli) => {
  const el = id(eli);
  if (el.classList.contains('invisible')) {
    el.classList.remove('invisible');
  }
};

export const btnModalOn = (eli) => {
  const btn = id(eli);
  if (!btn.classList.contains('btnLoading')) {
    btn.classList.add('btnLoading');
    btn.disabled = true;
    invisible('loadingText');
  }
};

export const btnModalOff = (eli) => {
  const btn = id(eli);
  if (btn.classList.contains('btnLoading')) {
    btn.classList.remove('btnLoading');
    btn.disabled = false;
    uninvisible('loadingText');
  }
};

export const createToggle = (inpID, inpVal, checked, action) => {
  if (checked !== true && checked !== false) {
    checked = toggleSliderYN(checked);
  }
  const label = divAtt('label', 'class', 'switch');
  const inp = divAtt('input', 'type', 'checkbox');
  inp.id = inpID;
  inp.checked = checked;
  inp.value = inpVal;
  // inp.setAttribute("onchange", action);
  const span = divAtt('span', 'class', 'toggle round');
  label.appendChild(inp);
  label.appendChild(span);
  return label;
};

export const tglActive = (classGroup, target) => {
  const elList = document.querySelectorAll(classGroup);
  elList.forEach((element) => {
    if (element.classList.contains('msgTabActive')) {
      element.classList.remove('msgTabActive');
    }
  });
  document.getElementById(target).classList.add('msgTabActive');
};

export const leftScroll = () => {
  console.log('Left');
  const left = id('tabList');
  left.scrollBy(-200, 0);
};
window.leftScroll = leftScroll;

export const rightScroll = () => {
  const right = id('tabList');
  right.scrollBy(200, 0);
};
window.rightScroll = rightScroll;

export const checkTabScroll = () => {
  const tabList = id('tabList');
  let isOverflowing = false;
  if (tabList) {
    isOverflowing = tabList.clientWidth < tabList.scrollWidth ? true : false;
  }

  if (isOverflowing) {
    if (id('leftScroll')) {
      unhide('leftScroll');
      unhide('rightScroll');
    }
  } else {
    if (id('leftScroll')) {
      hide('leftScroll');
      hide('rightScroll');
    }
  }
};

export const formatEmoji = (string) => {
  if (string) {
    const htmlText1 = string.slice(3, 8);
    const htmlText2 = string.slice(12, 17);
    return `&#x${htmlText1};&#x${htmlText2};`;
  }
};

export const convertHMS = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
};

export function submitNewUsers() {
  var batchID = id('batchID').innerHTML;
  var clientID = id('clientDD').value;
  if (!clientID) {
    infoBox('Please select Client');
    return;
  }
  getDataS('uploadedUsers', batchID).then(function (newUsers) {
    for (let i = 0; i < newUsers.list.length; i++) {
      const element = newUsers.list[i];
      if ('mobileNo' in element) {
      } else {
        newUsers.list[i].mobileNumber = '';
      }
    }
    for (let i = 0; i < newUsers.list.length; i++) {
      const element = newUsers.list[i];
      if ('employeeNo' in element) {
      } else {
        newUsers.list[i].employeeNo = '';
      }
    }
    // for (let i = 0; i < newUsers.list.length; i++) {
    //   newUsers.list[i].HclientID = parseInt(id('clientDD').value);
    // }

    if (id('groupDD').value == '-') {
      infoBox('Please select a user group.');
      return;
    } else if (id('bulkRoleDD').value === '-') {
      infoBox('Please select a role.');
      return;
    } else if (id('groupDD').value !== 'new') {
      newUsers.HuserGroupID = id('groupDD').value;
      newUsers.HclientID = parseInt(id('clientDD').value);
      newUsers.DDroleID = id('bulkRoleDD').value;
    } else if (id('NewGroupName').value == '' && id('groupDD').value == 'new') {
      infoBox('Please fill in the Group Name for the new Group.');
      return;
    } else {
      newUsers.HuserGroupID = id('groupDD').value;
      newUsers.newHuserGroupName = id('NewGroupName').value;
      newUsers.newHuserGroupDescription = id('NewGroupdescription').value;
      newUsers.newHuserGroupPrivateYN = id('NewGroupPrivate').checked;
      newUsers.HclientID = parseInt(id('clientDD').value);
      newUsers.DDroleID = id('bulkRoleDD').value;
    }

    const vars = {};
    vars.entityType = 'submitNewUsers';
    vars.entityUUID = uuidvMain();
    vars.payload = JSON.stringify(newUsers);
    PREP(['uploadNewRecords'], vars)
      .then((res) => {
        clearAllDataS('uploadedUsers');
        infoBox(`New Users have been submitted for upload. Once New Users have been successfuly uploaded they will be available under Manage Users. If you do not see these users under manage users, <strong>do not </strong> upload them again. Please contact your administrator. Entity UUID: ${newUsers.batchUUID}`);
        changeTabN('AUt_bulkUser');
      })
      .catch((err) => {
        infoBox(`Please contact your Administarator for Support.  Error : Entity UUID ${newUsers.batchUUID}, ${err}`);
      });
  });
}

export const notEmptyVal = (elID) => {
  const feedback = `${elID}_FB`;
  const infoText = window.infoText;

  if (!id(elID).value || id(elID).value == '-') {
    id(elID).classList.add('incorrect');

    if (id(feedback)) {
      if (id(elID).value == '-') {
        id(feedback).innerHTML = infoText.inf_Mkr18 ? infoText.inf_Mkr18 : 'Please select an option.';
      } else {
        id(feedback).innerHTML = infoText.inf_Mkr19 ? infoText.inf_Mkr19 : 'Please fill in required field.';
      }
      unhide(feedback);
    }
  } else {
    id(elID).classList.remove('incorrect');
    if (id(feedback)) {
      id(feedback).innerHTML = '';
      hide(feedback);
    }
  }

  // id(elID).classList.add('correct');
};
window.notEmptyVal = notEmptyVal;

export const mobNumVal = (elID) => {
  const feedback = `${elID}_FB`;
  const mobNum = id(elID).value;
  const infoText = window.infoText;

  if (valP(mobNum, elID)) {
    id(elID).classList.remove('incorrect');
    id(feedback).innerHTML = '';
    hide(feedback);
  } else {
    id(elID).classList.add('incorrect');
    id(feedback).innerHTML = infoText.inf_Mkr5;
    unhide(feedback);
  }
};
window.mobNumVal = mobNumVal;

export const eAddressVal = (elID) => {
  const feedback = `${elID}_FB`;
  const email = id(elID).value;
  const infoText = window.infoText;

  if (valE(email)) {
    id(elID).classList.remove('incorrect');
    id(feedback).innerHTML = '';
    hide(feedback);
  } else {
    id(elID).classList.add('incorrect');
    id(feedback).innerHTML = infoText.inf_Mkr7;
    unhide(feedback);
  }
};
window.eAddressVal = eAddressVal;

export const DOBVal = (elID) => {
  const feedback = `${elID}_FB`;
  const infoText = window.infoText;
  const DOB = id(elID).value;

  if (valDOB(DOB)) {
    id(elID).classList.remove('incorrect');
    id(feedback).innerHTML = '';
    hide(feedback);
  } else {
    id(elID).classList.add('incorrect');
    id(feedback).innerHTML = infoText.inf_Mkr20 ? infoText.inf_Mkr20 : 'Invalid date of birth.';
    unhide(feedback);
  }
};

export const inpFeedbackBanner = (msg, divID) => {
  const bannerID = divID ? `feedbackBanner_${divID}` : `feedbackBanner`;
  const feedbackID = divID ? `feedback_${divID}` : `feedback`;

  const err = id(`${feedbackID}`);
  err.innerHTML = '';
  err.innerHTML = `${msg}`;
  unhide(`${bannerID}`);
};

export const validatePage = () => {
  const userInputs = document.querySelectorAll('input, select, textarea');
  const returnMessage = [];
  let passValue = true;

  // Class list:
  // mobNum
  // notEmpty
  // eAddress
  // notDash
  // IDnum

  if (userInputs.length) {
    userInputs.forEach((inp) => {
      const continueV = inp.closest('.hide') ? false : true;
      const infoText = window.infoText;
      const parentHide = inp.parentElement.classList.contains('hide') ? true : false;

      if (!inp.classList.contains('hide') && !parentHide && continueV) {
        // Is the input a mobile number
        if (inp.classList.contains('mobNum')) {
          // Does the mobile number also have a notEmpty class
          if (inp.classList.contains('notEmpty')) {
            // Is the mobile number empty
            if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push(infoText.inf_Mkr4);
            } else {
              // Vlidate mobile number
              if (valP(inp.value, inp.id)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr5);
              }
            }
          } else {
            // If notEmpty class absent but a value is still inputted
            if (inp.value.trim()) {
              if (valP(inp.value, inp.id)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr5);
              }
            }
          }
        } else if (inp.classList.contains('eAddress')) {
          // Does the email address also have a notEmpty class
          if (inp.classList.contains('notEmpty')) {
            // Is the mobile number empty
            if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push(infoText.inf_Mkr6);
            } else {
              // Validate emailAddress
              if (valE(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr7);
              }
            }
          } else {
            // If notEmpty class absent but a value is still inputted
            if (inp.value.trim()) {
              if (valE(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr7);
              }
            }
          }
        } else if (inp.classList.contains('IDnum')) {
          // Does the ID also have a notEmpty class
          if (inp.classList.contains('notEmpty')) {
            // Is the ID number empty
            if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push('ID Number is a required field.');
            } else {
              // Validate ID
              if (valIDsa(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push('Invalid ID Number.');
              }
            }
          } else {
            // If notEmpty class absent but a value is still inputted
            if (inp.value.trim()) {
              if (valIDsa(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push('Invalid ID Number.');
              }
            } else if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push('ID Number is a required field.');
            }
          }
        } else if (inp.classList.contains('notEmpty')) {
          // Simple validation check to make sure not empty
          if (!inp.value.trim()) {
            passValue = false;
            rejectElem(inp);
            returnMessage.push(infoText.inf_Mkr8);
          } else {
            passElem(inp);
          }
        } else if (inp.classList.contains('notDash')) {
          // Simple validation check to make sure not empty
          if (!inp.value.trim() || inp.value === '-') {
            passValue = false;
            rejectElem(inp);
            returnMessage.push(infoText.inf_Mkr9);
          } else {
            passElem(inp);
          }
        }
      }
    });
  }

  if (id('feedback')) {
    if (returnMessage.length) {
      inpFeedbackBanner(returnMessage[0]);
    } else {
      id('feedbackBanner') && hide('feedbackBanner');
    }
  }

  return passValue;
};

export const validateDiv = (divID) => {
  const userInputs = document.querySelectorAll(`#${divID} input, #${divID} select, #${divID} textarea`);

  const returnMessage = [];
  let passValue = true;

  // Class list:
  // mobNum
  // notEmpty
  // eAddress
  // notDash
  // IDnum

  if (userInputs.length) {
    userInputs.forEach((inp) => {
      const continueV = inp.closest('.hide') ? false : true;
      const infoText = window.infoText;
      const parentHide = inp.parentElement.classList.contains('hide') ? true : false;

      if (!inp.classList.contains('hide') && continueV && !parentHide) {
        // Is the input a mobile number
        if (inp.classList.contains('mobNum')) {
          // Does the mobile number also have a notEmpty class
          if (inp.classList.contains('notEmpty')) {
            // Is the mobile number empty
            if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push(infoText.inf_Mkr4);
            } else {
              // Vlidate mobile number
              if (valP(inp.value, inp.id)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr5);
              }
            }
          } else {
            // If notEmpty class absent but a value is still inputted
            if (inp.value.trim()) {
              if (valP(inp.value, inp.id)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr5);
              }
            }
          }
        } else if (inp.classList.contains('eAddress')) {
          // Does the email address also have a notEmpty class
          if (inp.classList.contains('notEmpty')) {
            // Is the mobile number empty
            if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push(infoText.inf_Mkr6);
            } else {
              // Validate emailAddress
              if (valE(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr7);
              }
            }
          } else {
            // If notEmpty class absent but a value is still inputted
            if (inp.value.trim()) {
              if (valE(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push(infoText.inf_Mkr7);
              }
            }
          }
        } else if (inp.classList.contains('IDnum')) {
          // Does the ID also have a notEmpty class
          if (inp.classList.contains('notEmpty')) {
            // Is the ID number empty
            if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push('ID Number is a required field.');
            } else {
              // Validate ID
              if (valIDsa(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push('Invalid ID Number.');
              }
            }
          } else {
            // If notEmpty class absent but a value is still inputted
            if (inp.value.trim()) {
              if (valIDsa(inp.value)) {
                passElem(inp);
              } else {
                passValue = false;
                rejectElem(inp);
                returnMessage.push('Invalid ID Number.');
              }
            } else if (!inp.value.trim()) {
              passValue = false;
              rejectElem(inp);
              returnMessage.push('ID Number is a required field.');
            }
          }
        } else if (inp.classList.contains('notEmpty')) {
          // Simple validation check to make sure not empty
          if (!inp.value.trim()) {
            passValue = false;
            rejectElem(inp);
            returnMessage.push(infoText.inf_Mkr8);
          } else {
            passElem(inp);
          }
        } else if (inp.classList.contains('notDash')) {
          // Simple validation check to make sure not empty
          if (!inp.value.trim() || inp.value === '-') {
            passValue = false;
            rejectElem(inp);
            returnMessage.push(infoText.inf_Mkr9);
          } else {
            passElem(inp);
          }
        }
      }
    });
  }

  const banner = `feedbackBanner_${divID}`;
  const feedback = `feedback_${divID}`;

  if (id(feedback)) {
    if (returnMessage.length) {
      inpFeedbackBanner(returnMessage[0], divID);
    } else {
      id(banner) && hide(banner);
    }
  }

  return passValue;
};

export const removeVal = (elID) => {
  id(elID).classList.remove('incorrect');
};

export const rejectElem = (elem) => {
  console.log('rejected: ', elem.id);

  const elemID = elem.id;

  if (!id(elemID).classList.contains('incorrect')) {
    id(elemID).classList.add('incorrect');
  }
};

export const passElem = (elem) => {
  const elemID = elem.id;
  if (id(elemID).classList.contains('incorrect')) {
    id(elemID).classList.remove('incorrect');
  }
};

export const showCheckboxes = () => {
  if (id('selectCheckboxes').classList.contains('hide')) {
    unhide('selectCheckboxes');
  } else {
    hide('selectCheckboxes');
  }
};

export const divAtt = (element, att, value) => {
  const div = document.createElement(element);
  if (att) {
    const attribute = document.createAttribute(att);
    attribute.value = value || '';
    div.setAttributeNode(attribute);
  }
  return div;
};

export const completedPercent = (outstanding, total) => {
  const balance = (parseInt(outstanding) / parseInt(total)) * 100;
  const completed = 100 - balance;
  return completed;
};

export const navSel = (navID) => {
  const navItems = document.querySelectorAll('.navItem');
  navItems.forEach((navItem) => {
    navItem.classList.remove('navActive');
  });

  if (navID) {
    id(navID).classList.add('navActive');
  }
};
window.navSel = navSel;

export function addAtt(el, attribute, value) {
  let a = document.createAttribute(attribute);
  a.value = value;
  el.setAttributeNode(a);
  return el;
}

export const changeTabN = async (newTab, vars) => {
  const tabList = document.querySelectorAll('.tablinks');
  const recipe = await getRecipe();
  recipe.page = newTab;
  recipe.div = 'contentContainer';
  tabList.forEach((tab) => {
    tab.classList.contains('active') && tab.classList.remove('active');

    if (tab.id == newTab) {
      tab.classList.add('active');
    }
  });
  PREP(recipe, vars || {}).catch(console.error());
};
window.changeTabN = changeTabN;

export const changeSubTab = async (newTab, vars) => {
  const subList = document.querySelectorAll('.subTab');
  const recipe = await getRecipe();
  recipe.page = newTab;
  recipe.div = 'subContainer';
  subList.forEach((tab) => {
    tab.classList.contains('subTabActive') && tab.classList.remove('subTabActive');
    tab.id == newTab && tab.classList.add('subTabActive');
  });

  // if Submenu is in block style
  const blocks = document.querySelectorAll('.blockItem');
  blocks.forEach((block) => {
    block.classList.remove('blockActive');
    block.id == newTab && id(newTab).classList.add('blockActive');
  });

  PREP(recipe, vars || {}).catch(console.error());
};
window.changeSubTab = changeSubTab;

export function OnImageLoad(evt, size) {
  var img = evt.currentTarget;

  // what's the size of this image and it's parent
  var w = img.clientWidth; //$(img).width();
  var h = img.clientHeight; // $(img).height();
  var tw = size; //$(img).parent().width();
  var th = size; //$(img).parent().height();

  // compute the new size and offsets
  var result = ScaleImage(w, h, tw, th, false);
  // adjust the image coordinates and size

  if (result.width == 0 || result.height == 0) {
    img.width = size;
    img.height = size;
  } else {
    img.width = result.width;
    img.height = result.height;
  }

  // img.width = 50;
  // img.height = 50;
  img.style.left = result.targetleft + 'px'; //$(img).css("left", result.targetleft);
  img.style.top = result.targettop + 'px'; //$(img).css("top", result.targettop);
}
window.OnImageLoad = OnImageLoad;

export function ScaleImage(srcwidth, srcheight, targetwidth, targetheight, fLetterBox) {
  var result = {
    width: 0,
    height: 0,
    fScaleToTargetWidth: true,
  };

  if (srcwidth <= 0 || srcheight <= 0 || targetwidth <= 0 || targetheight <= 0) {
    return result;
  }

  // scale to the target width
  var scaleX1 = targetwidth;
  var scaleY1 = (srcheight * targetwidth) / srcwidth;

  // scale to the target height
  var scaleX2 = (srcwidth * targetheight) / srcheight;
  var scaleY2 = targetheight;

  // now figure out which one we should use
  var fScaleOnWidth = scaleX2 > targetwidth;
  if (fScaleOnWidth) {
    fScaleOnWidth = fLetterBox;
  } else {
    fScaleOnWidth = !fLetterBox;
  }

  if (fScaleOnWidth) {
    result.width = Math.floor(scaleX1);
    result.height = Math.floor(scaleY1);
    result.fScaleToTargetWidth = true;
  } else {
    result.width = Math.floor(scaleX2);
    result.height = Math.floor(scaleY2);
    result.fScaleToTargetWidth = false;
  }
  result.targetleft = Math.floor((targetwidth - result.width) / 2);
  result.targettop = Math.floor((targetheight - result.height) / 2);

  return result;
}

export function getLocalTimeUCT() {
  const today = new Date();
  const stringDate = today.toString();
  const split = stringDate.split(' ');
  const timezone = split[5];
  return timezone;
}

export function formatDateTime(date) {
  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // const date = new Date(dateString);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var time = [hours, minutes]; // Convert time to an array of components

  function formatTwoDigits(number) {
    return number.toString().padStart(2, '0');
  }

  return `${day} ${monthNames[monthIndex]} ${year} ${formatTwoDigits(hours)}:${formatTwoDigits(minutes)}`;
}

export function dateFromDateTime(date) {
  const infoText = window.infoText;

  const January = infoText.inf_Mkr33 ? infoText.inf_Mkr33 : 'January';
  const February = infoText.inf_Mkr34 ? infoText.inf_Mkr34 : 'February';
  const March = infoText.inf_Mkr35 ? infoText.inf_Mkr35 : 'March';
  const April = infoText.inf_Mkr36 ? infoText.inf_Mkr36 : 'April';
  const May = infoText.inf_Mkr37 ? infoText.inf_Mkr37 : 'May';
  const June = infoText.inf_Mkr38 ? infoText.inf_Mkr38 : 'June';
  const July = infoText.inf_Mkr39 ? infoText.inf_Mkr39 : 'July';
  const August = infoText.inf_Mkr40 ? infoText.inf_Mkr40 : 'August';
  const September = infoText.inf_Mkr41 ? infoText.inf_Mkr41 : 'September';
  const October = infoText.inf_Mkr42 ? infoText.inf_Mkr42 : 'October';
  const November = infoText.inf_Mkr43 ? infoText.inf_Mkr43 : 'November';
  const December = infoText.inf_Mkr44 ? infoText.inf_Mkr44 : 'December';

  var monthNames = [January, February, March, April, May, June, July, August, September, October, November, December];

  var date = new Date(date);
  // console.log(date);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export function dateShortFromDateTime(date) {
  var date = new Date(date);
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function filterOptionsHandler(j_filter, j_options) {
  document.getElementById(j_options).value = '';
  var searchLetters = id(j_filter).value.toLowerCase();
  var selectOptions = id(j_options);
  for (var i = 0; i < selectOptions.length; i++) {
    var txtToTest = selectOptions.options[i].text.toLowerCase();
    var tested = txtToTest.indexOf(searchLetters);
    if (tested > -1) {
      selectOptions.options[i].style.display = 'block';
    } else {
      selectOptions.options[i].style.display = 'none';
    }
  } // end loop over optionsList
}
window.filterOptionsHandler = filterOptionsHandler;

export const offsetTime = function (strDate, offsetHrs) {
  const datum = Date.parse(strDate);
  const offsetTimeSec = datum + offsetHrs * 60 * 60 * 1000;
  returnDate = formatDateAndTime(offsetTimeSec);
  return returnDate;
};

export const fileSelected = function () {
  var idiv = document.getElementById('preview1_div');
  var tdiv = document.getElementById('imgUploadTips');
  // idiv.className = "flex_row profile_imageContainer_preview";
  // console.log(idiv.className);
  var iMaxFilesize = 250000; // 1MB
  // hide different warnings
  document.getElementById('error1').style.display = 'none';
  document.getElementById('error2').style.display = 'none';
  document.getElementById('abort1').style.display = 'none';
  document.getElementById('warnsize1').style.display = 'none';
  // get selected file element
  var oFile = document.getElementById('image_file').files[0];
  // filter for image files
  var rFilter = /^(image\/gif|image\/jpeg|image\/png)$/i;
  if (!rFilter.test(oFile.type)) {
    document.getElementById('error1').style.display = 'block';
    return;
  }
  // little test for filesize
  if (oFile.size > iMaxFilesize) {
    document.getElementById('warnsize1').style.display = 'block';
    return;
  }

  // get preview element
  var oImage = document.getElementById('preview4');
  sResultFileSize = bytesToSize(oFile.size);

  // prepare HTML5 FileReader
  var oReader = new FileReader();
  oReader.onload = function (e) {
    var img = new Image();
    img.onload = function () {
      oImage.src = e.target.result;
      oImage.setAttribute('onload', 'OnImageLoad(event, 150)'); //OnImageLoad(event, 150)
      oImage.setAttribute('name', oFile.name);
      idiv.className = 'profile_imageContainer_preview floatItem float';
      tdiv.className = 'float';
    };
    img.src = e.target.result;
  };
  // read selected file as DataURL
  oReader.readAsDataURL(oFile);
};

export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB'];
  if (bytes == 0) return 'n/a';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}

export function checkFeedback(type) {
  const vars = {};
  PREP(['getAllocatedHuserHcourse'], vars).then((res) => {
    if (res.data.length > 0) {
      if (type === 'eFeedback') {
        switchBox('eFeedback', 'type=' + type);
      } else if (type === 'Assessment Guide') {
        console.log(type);
        switchBox('eFeedback', 'type=' + type + '&HcourseID=' + res.data[0].HCOURSEID);
      }
    } else {
      errBox('No eFeedback');
    }
  });
}

export function createSelectOptions2(dataObject, idKey, descKeyList, locID) {
  // console.log(locID);
  // console.log('createSelectOptions', dataObject);
  var selectb = document.getElementById(locID);
  selectb.options.length = 0;
  for (let d = 0; d < dataObject.length; d++) {
    var optionb = divAtt('option', 'value', dataObject[d][idKey]);
    if (descKeyList.length > 1) {
      var combo = '';
      for (let i = 0; i < descKeyList.length; i++) {
        if (dataObject[d][descKeyList[i]] !== undefined) {
          combo = combo + dataObject[d][descKeyList[i]] + ' ';
        } else {
          combo = combo + ' - ';
        }
      }
      optionb.innerHTML = combo;
    } else {
      // console.log('else: ', dataObject[d][descKeyList[0]]);
      optionb.innerHTML = dataObject[d][descKeyList[0]];
    }
    selectb.appendChild(optionb);
  }
}

// security
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
}

export function formatDateAndTime(val) {
  const time = new Date(val).toLocaleTimeString();
  const date = new Date(val).toLocaleDateString().split('/').join('-');

  const comb = date + ', ' + time;

  return comb;
}

export function formatDateforInput(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function rawTable(data, domID) {
  if (document.getElementById(domID).innerHTML !== undefined) {
    document.getElementById(domID).innerHTML = '';
  }

  // console.log(data);
  var thead = document.createElement('thead');
  //build Table Headers

  for (let key in data[0]) {
    if (key === 'Initial Date') {
      for (let i = 0; i < data.length; i++) {
        data[i]['Initial Date'] = offsetTime(data[i]['Initial Date'], 8);
      }
      console.log(data);
    }
    var th = document.createElement('th');
    var classname = key.replace(/\s/g, '');
    if (key === 'HUSERID' || key === 'HUSERGROUPID' || key === 'group Type' || key === 'ALLOCHUSERHUSERGROUPID') {
      continue;
    } else if (key === 'Group Active') {
      th.innerHTML = 'Group Active';
    } else if (key === 'User Active') {
      th.innerHTML = 'User Status';
    } else if (key !== 'Update' && key !== 'Status') {
      th.innerHTML = key;
      var searchcolumn = divAtt('input', 'class', 'searchBoxInput');
      searchcolumn = addAtt(searchcolumn, 'type', 'text');
      searchcolumn = addAtt(searchcolumn, 'onkeyup', 'filter("filteBy' + classname + '", "' + classname + '")');
      searchcolumn = addAtt(searchcolumn, 'placeholder', 'find . . . ');
      searchcolumn = addAtt(searchcolumn, 'id', 'filteBy' + classname);
      searchcolumn = addAtt(searchcolumn, 'style', 'display:none');
      th.appendChild(searchcolumn);
    } else {
      th.innerHTML = key;
    }
    thead.appendChild(th);
  }
  document.getElementById(domID).appendChild(thead);
  for (let u = 0; u < data.length; u++) {
    const el = data[u];
    var tr = document.createElement('tr');
    for (const key in el) {
      if (el.hasOwnProperty(key)) {
        const field = el[key];
        var td = divAtt('td', 'nowrap', '');
        var classname = key.replace(/\s/g, '');
        td = addAtt(td, 'class', classname);
        if (key === 'HUSERID' || key === 'HUSERGROUPID' || key === 'group Type' || key === 'ALLOCHUSERHUSERGROUPID') {
          continue;
        } else if (key === 'Group Name') {
          if (typeof field === 'string') {
            td.innerHTML = field;
          } else {
            td = addAtt(td, 'class', 'link');
            td.appendChild(field);
          }
        } else if (key === 'Group Active') {
          if (el['group Type'] === 'System') {
            if (field) {
              td.innerHTML = 'Active';
            } else {
              td.innerHTML = 'Inactive';
            }
          } else {
            var label = divAtt('label', 'class', 'container');
            var check = divAtt('input', 'type', 'checkbox');
            check = addAtt(check, 'id', 'HUSERGROUPID=' + el.HUSERGROUPID);
            check = addAtt(check, 'onclick', 'toggleActive("HUSERGROUPID=' + el.HUSERGROUPID + '")');
            var span = divAtt('span', 'class', 'checkmark');
            label.appendChild(check);
            label.appendChild(span);
            td.appendChild(label);
            if (field) {
              check.checked = true;
            } else {
              check.checked = false;
            }
          }
        } else if (key === 'User Active in Group') {
          var label = divAtt('label', 'class', 'container');
          var check = divAtt('input', 'type', 'checkbox');
          check = addAtt(check, 'id', el.ALLOCHUSERHUSERGROUPID);
          check = addAtt(check, 'onclick', 'toggleActive("' + el.ALLOCHUSERHUSERGROUPID + '")');
          var span = divAtt('span', 'class', 'checkmark');
          label.appendChild(check);
          label.appendChild(span);
          td.appendChild(label);
          if (field) {
            check.checked = true;
          } else {
            check.checked = false;
          }
        } else if (key === 'User Active') {
          if (field) {
            td.innerHTML = 'User Active';
          } else {
            td.innerHTML = 'User Inactive';
          }
        } else if (key == 'LINK' || key === 'VIEW' || key === 'Update') {
          var btn = divAtt('a', 'class', 'btn');
          btn = addAtt(btn, 'onclick', field);
          var text = key.toLowerCase();
          btn.innerHTML = text;
          td.appendChild(btn);
        } else {
          td.innerHTML = field;
        }
        tr.appendChild(td);
      }
      document.getElementById(domID).appendChild(tr);
    }
  }
}

// === Quick build Form Elements

export const buildInpText = (elID, requiredField, label) => {
  const div = divAtt('div', 'class', 'group');
  const input = divAtt('input', 'id', `${elID}`);
  input.setAttribute('type', 'text');
  input.setAttribute('onkeyup', 'notEmptyVal(this.id)');
  const bar = divAtt('span', 'class', 'bar');
  const valFeedback = divAtt('p', 'class', 'inpFB hide');
  valFeedback.setAttribute('id', `${elID}_FB`);
  const inptLabel = divAtt('label', 'for', `${elID}`);

  if (requiredField) {
    input.classList.add('notEmpty');
    inptLabel.innerHTML = `${label}*`;
  } else {
    inptLabel.innerHTML = `${label}`;
  }

  div.appendChild(input);
  div.appendChild(bar);
  div.appendChild(valFeedback);
  div.appendChild(inptLabel);

  return div;
};

export const buildInpEmail = (elID, requiredField, label) => {
  const div = divAtt('div', 'class', 'group');
  const input = divAtt('input', 'id', `${elID}`);
  input.setAttribute('type', 'email');
  input.setAttribute('class', 'eAddress');
  input.setAttribute('onkeyup', 'eAddressVal(this.id)');
  const bar = divAtt('span', 'class', 'bar');
  const valFeedback = divAtt('p', 'class', 'inpFB hide');
  valFeedback.setAttribute('id', `${elID}_FB`);
  const inptLabel = divAtt('label', 'for', `${elID}`);

  if (requiredField) {
    input.classList.add('notEmpty');
    if (label) {
      inptLabel.innerHTML = `${label}*`;
    } else {
      inptLabel.innerHTML = 'Email Address*';
    }
  } else {
    if (label) {
      inptLabel.innerHTML = `${label}`;
    } else {
      inptLabel.innerHTML = 'Email Address';
    }
  }

  div.appendChild(input);
  div.appendChild(bar);
  div.appendChild(valFeedback);
  div.appendChild(inptLabel);

  return div;
};

export const buildInpMobNum = (elID, requiredField, label) => {
  const numDiv = divAtt('div', 'class', 'CCdiv group');
  const prefixes = divAtt('select', 'id', 'countryCodes');
  prefixes.setAttribute('class', 'countryCodes');
  const numInput = divAtt('input', 'onkeyup', 'mobNumVal(this.id)');
  numInput.setAttribute('id', `${elID}`);
  numInput.setAttribute('class', 'CCdigits mobNum');
  numInput.setAttribute('type', 'number');
  const numBar = divAtt('span', 'class', 'bar');
  const numValFeedback = divAtt('p', 'class', 'inpFB hide');
  numValFeedback.setAttribute('id', `${elID}_FB`);
  const numLabel = divAtt('label', 'for', `${elID}`);
  numLabel.innerHTML = 'Mobile Number';

  if (requiredField) {
    numInput.classList.add('notEmpty');
    if (label) {
      numLabel.innerHTML = `${label}*`;
    } else {
      numLabel.innerHTML = 'Mobile Number*';
    }
  } else {
    if (label) {
      numLabel.innerHTML = `${label}`;
    } else {
      numLabel.innerHTML = 'Mobile Number';
    }
  }

  numDiv.appendChild(prefixes);
  numDiv.appendChild(numInput);
  numDiv.appendChild(numBar);
  numDiv.appendChild(numValFeedback);
  numDiv.appendChild(numLabel);

  return numDiv;
};

export const buildSelectInp = (id, requiredField, label) => {
  const div = divAtt('div', 'class', 'group');
  const select = divAtt('select', 'id', `${id}`);
  select.setAttribute('onchange', 'notEmptyVal(this.id)');
  const bar = divAtt('span', 'class', 'bar');
  const valFeedback = divAtt('p', 'class', 'inpFB hide');
  valFeedback.setAttribute('id', `${id}_FB`);
  const selectLabel = divAtt('label', 'for', `${id}`);

  if (requiredField) {
    select.classList.add('notDash');
    selectLabel.innerHTML = `${label}*`;
  } else {
    selectLabel.innerHTML = `${label}`;
  }

  div.appendChild(select);
  div.appendChild(bar);
  div.appendChild(valFeedback);
  div.appendChild(selectLabel);

  return div;
};

export const buildTextarea = (elID, requiredField, label) => {
  const div = divAtt('div', 'class', 'group gridSpan marginBottom');
  const textarea = divAtt('textarea', 'id', `${elID}`);
  textarea.style = 'resize: none;';
  textarea.setAttribute('col', '30');
  textarea.setAttribute('row', '10');
  textarea.setAttribute('onkeyup', 'notEmptyVal(this.id)');
  const inptLabel = divAtt('label', 'for', `${elID}`);

  if (requiredField) {
    textarea.classList.add('notEmpty');
    inptLabel.innerHTML = `${label}*`;
  } else {
    inptLabel.innerHTML = `${label}`;
  }

  div.appendChild(textarea);
  div.appendChild(inptLabel);

  return div;
};

export const buildDateInp = (id, requiredField, label) => {
  const div = divAtt('div', 'class', 'group');
  const input = divAtt('input', 'id', `${id}`);
  input.setAttribute('type', 'date');
  input.setAttribute('onkeyup', 'notEmptyVal(this.id)');
  const bar = divAtt('span', 'class', 'bar');
  const valFeedback = divAtt('p', 'class', 'inpFB hide');
  valFeedback.setAttribute('id', `${id}_FB`);
  const inptLabel = divAtt('label', 'for', `${id}`);
  inptLabel.innerHTML = `${label}`;

  if (requiredField) {
    input.classList.add('notEmpty');
    inptLabel.innerHTML = `${label}*`;
  }

  div.appendChild(input);
  div.appendChild(bar);
  div.appendChild(valFeedback);
  div.appendChild(inptLabel);

  return div;
};

export const buildBtn = (id, onclick, name) => {
  const btn = divAtt('button', 'onclick', `${onclick}`);
  btn.setAttribute('id', `${id}`);
  btn.setAttribute('class', 'btn');
  btn.innerHTML = `${name}`;
  return btn;
};

export const buildBtnForm = (id, onclick, name) => {
  const div = divAtt('div', 'class', 'btnEndGrid');
  const btn = divAtt('button', 'onclick', `${onclick}`);
  btn.setAttribute('class', 'btn');
  btn.innerHTML = `${name}`;
  btn.setAttribute('id', `${id}`);
  div.appendChild(btn);

  return div;
};

export const buildBtnFormCenter = (id, onclick, name) => {
  const div = divAtt('div', 'class', 'btnCenterGrid');
  // const btn = divAtt("button", "onclick", `${onclick}`);
  const btn = divAtt('button', 'id', `${id}`);
  btn.setAttribute('class', 'btn');
  btn.innerHTML = `${name}`;
  //btn.setAttribute("id", `${id}`);
  div.appendChild(btn);
  return div;
};

export const buildFeedbackBanner = (divID) => {
  const bannerID = divID ? `feedbackBanner_${divID}` : `feedbackBanner`;
  const feedbackID = divID ? `feedback_${divID}` : `feedback`;

  const div = divAtt('div', 'id', `${bannerID}`);
  div.setAttribute('class', 'hide gridSpan feedbackBanner');
  const warningIcon = divAtt('div');
  warningIcon.innerHTML = warningSuccSVGs;
  const feedback = divAtt('p', 'id', `${feedbackID}`);
  feedback.setAttribute('class', 'feedback');
  const x = divAtt('div');
  x.setAttribute('onclick', `hide('${bannerID}')`);
  x.innerHTML = FBX;

  div.appendChild(warningIcon);
  div.appendChild(feedback);
  div.appendChild(x);

  return div;
};

export const buildRadio = (opt1name, opt1IDval, opt2name, opt2IDval, radioName, name) => {
  const div = divAtt('div', 'class', 'group');

  const opt1 = divAtt('label', 'class', 'radioLabel');
  opt1.innerHTML = `<input type="radio" id="${opt1IDval}" value="${opt1IDval}"
                    name="${radioName}">${opt1name}`;

  const opt2 = divAtt('label', 'class', 'radioLabel');
  opt2.innerHTML = `<input type="radio" id="${opt2IDval}" value="${opt2IDval}"
                    name="${radioName}">${opt2name}`;

  const radioLabel = divAtt('label', 'class', `radioDescript`);
  radioLabel.innerHTML = name;

  div.appendChild(opt1);
  div.appendChild(opt2);
  div.appendChild(radioLabel);

  return div;
};

export const paginate = (pagination, callback, direction) => {
  const pagDiv = id('pagDiv');
  pagDiv.innerHTML = '';
  const varble = direction ? direction : '';
  if (pagination.totalPages == 1) {
    hide('pagDiv');
  } else if (pagination.totalPages <= 10) {
    for (let i = 1; i <= pagination.totalPages; i++) {
      const button = divAtt('button', 'class', 'pagNum');
      button.setAttribute('onclick', `${callback}(${i}, '${varble}')`);
      button.innerHTML = i;
      if (i == pagination.currentPage) {
        button.classList.add('pagNumActive');
      }
      pagDiv.appendChild(button);
    }
    unhide('pagDiv');
  } else if (pagination.totalPages > 10) {
    paginateA(pagination, callback, varble);
  }
};

export const paginateA = (pagination, callback, direction) => {
  const pagDiv = id('pagDiv');
  const currPage = parseInt(pagination.currentPage);
  const first = ('' + currPage).slice(0, -1);
  const lastDigit = parseInt(('' + currPage).slice(-1));
  const firstDigit = lastDigit == 0 ? first - 1 : first;

  const currBlockStart = currPage > 10 ? parseInt(`${firstDigit}1`) : 1;
  const currBlockEnd = currBlockStart + 9 > pagination.totalPages ? pagination.totalPages : currBlockStart + 9;

  const firstPage = divAtt('button', 'class', 'pagArrow');
  firstPage.setAttribute('onclick', `${callback}(1, '${direction}')`);
  firstPage.innerHTML = '&#171;';
  pagDiv.appendChild(firstPage);

  const minusOne = currPage - 10 > 1 ? currPage - 10 : 1;
  const oneDown = divAtt('button', 'class', 'pagArrow');
  oneDown.setAttribute('onclick', `${callback}(${minusOne}, '${direction}')`);
  oneDown.innerHTML = '&#8249;';
  pagDiv.appendChild(oneDown);

  for (let i = currBlockStart; i <= currBlockEnd; i++) {
    const button = divAtt('button', 'class', 'pagNum');
    button.setAttribute('onclick', `${callback}(${i}, '${direction}')`);
    button.innerHTML = i;
    if (i == currPage) {
      button.classList.add('pagNumActive');
    }
    pagDiv.appendChild(button); //()=>{report.remove(`${reportRegisterID}`)
  }

  const plusOne = currPage + 10 < pagination.totalPages ? currPage + 10 : pagination.totalPages;

  const oneUp = divAtt('button', 'class', 'pagArrow');
  oneUp.setAttribute('onclick', `${callback}(${plusOne}, '${direction}')`);
  oneUp.innerHTML = '&#8250;';
  pagDiv.appendChild(oneUp);

  const lastPage = divAtt('button', 'class', 'pagArrow');
  lastPage.setAttribute('onclick', `${callback}(${pagination.totalPages}, '${direction}')`);
  lastPage.innerHTML = '&#187;';
  pagDiv.appendChild(lastPage);

  unhide('pagDiv');
};

export const clientListener = () => {
  const clientList = id('clientDD');
  clientList.addEventListener('change', () => {
    getDataS('currentPage', 1).then((page) => {
      if (pageObj.hasOwnProperty(page.page)) {
        pageObj[page.page]();
      }
    });
  });
};

export const buildTgl = async (inpClass, inpVal, checked, action, lbl, elID) => {
  const div = document.createElement('div');
  const label = divAtt('label', 'class', 'switch');
  const inp = divAtt('input', 'type', 'checkbox');
  inp.checked = checked;
  inp.classList.add(inpClass);
  inp.value = inpVal;
  //inp.setAttribute("onchange", action);
  if (elID) {
    inp.setAttribute('id', elID);
  }
  const span = divAtt('span', 'class', 'toggle round');
  label.appendChild(inp);
  label.appendChild(span);
  const nameLabel = divAtt('label', 'class', 'tgglLabel');
  lbl && (nameLabel.innerHTML = lbl);
  div.appendChild(label);
  div.appendChild(nameLabel);
  return div;
};

export const svgStringParse = async (svgString) => {
  console.log('here');
  const parser = new DOMParser();
  const svgDocument = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = svgDocument.documentElement;
  return svgElement;
};

//mike
export const buildTickBox = async (elId, checked, action, lbl) => {
  const label = divAtt('label', 'class', 'checkContainer');
  label.setAttribute('for', `${elId}`);
  const inpt = divAtt('input', 'type', 'checkbox');
  // if (action) {
  //   inpt.setAttribute("onchange", `${action}`);
  // }
  if (checked) {
    inpt.checked = true;
  }
  inpt.setAttribute('id', `${elId}`);
  const span = divAtt('span', 'class', 'tick');
  const p = divAtt('p');

  label.appendChild(inpt);
  label.appendChild(span);
  if (lbl) {
    p.innerHTML = lbl;
    label.appendChild(p);
  }

  return label;
};

export const lineBreaks = (text) => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export const sF = {
  buildEmailTab: (data, callback, direction) => {
    const msgList = data[0].ReturnList;
    const pagination = data[0].pagination;
    const table = id('emailTable');
    table.innerHTML = '';
    if (msgList.length) {
      const thead1 = document.createElement('thead');
      const th1 = document.createElement('th');
      const th2 = document.createElement('th');
      const th3 = document.createElement('th');
      const th4 = document.createElement('th');
      const th5 = document.createElement('th');
      const th6 = document.createElement('th');
      th1.innerHTML = 'Date';
      th2.innerHTML = 'Initiated By';
      th3.innerHTML = 'Received By';
      th4.innerHTML = 'Subject';
      th5.innerHTML = 'Short Description';
      th6.innerHTML = 'Action';
      thead1.appendChild(th1);
      thead1.appendChild(th2);
      thead1.appendChild(th3);
      thead1.appendChild(th4);
      thead1.appendChild(th5);
      thead1.appendChild(th6);
      table.appendChild(thead1);

      for (let message of msgList) {
        const tr = document.createElement('tr');
        const date = document.createElement('td');
        const to = document.createElement('td');
        const from = document.createElement('td');
        const subj = document.createElement('td');
        const SDesc = document.createElement('td');
        const view = document.createElement('td');
        const viewbtn = divAtt('a', 'href', '#');
        date.innerHTML = offsetTime(message.Spooled, 8);
        to.innerHTML = message.receiverName == 'This user' ? 'Me' : message.receiverName;
        from.innerHTML = message.senderName == 'This user' ? 'Me' : message.senderName;
        subj.innerHTML = message.emailSubject;
        SDesc.innerHTML = message.shortDescription;
        viewbtn.setAttribute('class', 'btn viewMail');
        viewbtn.setAttribute('onclick', `sF.viewEmail(${message.commslogID})`);
        viewbtn.id = `commsID_${message.commslogID}`;
        viewbtn.innerHTML = 'View';
        view.appendChild(viewbtn);
        tr.appendChild(date);
        tr.appendChild(from);
        tr.appendChild(to);
        tr.appendChild(subj);
        tr.appendChild(SDesc);
        tr.appendChild(view);
        table.appendChild(tr);
      }
      sF.paginate(pagination[0], callback, direction);
    }
  },
  buildSMSTab: (data, callback, direction) => {
    const msgList = data[0].ReturnList;
    const pagination = data[0].pagination;
    const table = id('smsTable');
    table.innerHTML = '';
    if (msgList.length) {
      const thead1 = document.createElement('thead');
      const th1 = document.createElement('th');
      const th2 = document.createElement('th');
      const th3 = document.createElement('th');
      const th4 = document.createElement('th');
      const th5 = document.createElement('th');
      th1.innerHTML = 'Date';
      th2.innerHTML = 'Initiated By';
      th3.innerHTML = 'Received By';
      th4.innerHTML = 'Short Description';
      th5.innerHTML = 'Action';
      thead1.appendChild(th1);
      thead1.appendChild(th2);
      thead1.appendChild(th3);
      thead1.appendChild(th4);
      thead1.appendChild(th5);
      table.appendChild(thead1);

      for (let message of msgList) {
        const tr = document.createElement('tr');
        const date = document.createElement('td');
        const to = document.createElement('td');
        const from = document.createElement('td');
        const SDesc = document.createElement('td');
        const view = document.createElement('td');
        const viewbtn = divAtt('a', 'href', '#');
        date.innerHTML = offsetTime(message.Spooled, 8);
        message.senderName == 'This user' && (message.senderName = 'Me');
        message.receiverName == 'This user' && (message.receiverName = 'Me');
        to.innerHTML = message.receiverName;
        from.innerHTML = message.senderName;
        SDesc.innerHTML = message.shortDescription;
        viewbtn.setAttribute('class', 'btn viewMail');
        viewbtn.setAttribute('onclick', `sF.viewSMS(${message.commslogID})`);
        viewbtn.id = `commsID_${message.commslogID}`;
        viewbtn.innerHTML = 'View';
        view.appendChild(viewbtn);
        tr.appendChild(date);
        tr.appendChild(from);
        tr.appendChild(to);
        tr.appendChild(SDesc);
        tr.appendChild(view);
        table.appendChild(tr);
      }
      sF.paginate(pagination[0], callback, direction);
    }
  },
  paginate: (pagination, callback, direction) => {
    const pagDiv = id('pagDiv');
    pagDiv.innerHTML = '';
    const varble = direction ? direction : '';
    if (pagination.totalPages == 1) {
      hide('pagDiv');
    } else if (pagination.totalPages <= 10) {
      for (let i = 1; i <= pagination.totalPages; i++) {
        const button = divAtt('button', 'class', 'pagNum');
        button.setAttribute('onclick', `${callback}(${i}, '${varble}')`);
        button.innerHTML = i;
        if (i == pagination.currentPage) {
          button.classList.add('pagNumActive');
        }
        pagDiv.appendChild(button);
      }
      unhide('pagDiv');
    } else if (pagination.totalPages > 10) {
      paginateA(pagination, callback, varble);
    }
  },
  viewEmail: (cLogID) => {
    PREP(['getMessageDetail'], {
      commslogID: cLogID,
    })
      .then((res) => {
        const recieved = id('mailDate');
        const to = id('mailTo');
        const from = id('mailFrom');
        const subject = id('mailSubject');
        const body = id('mailBody');
        const resend = id('resendBtn');
        const dets = res.data.getMessageDetail[0];

        recieved.innerHTML = `${dets.recipientEmailAddress}`;
        to.innerHTML = `${dets.recipientEmailAddress}`;
        from.innerHTML = `${dets.senderEmailAddress}`;
        subject.innerHTML = `${dets.emailSubject}`;
        body.innerHTML = `${dets.emailBody}`;
        resend.setAttribute('onclick', `sF.resend(${dets.commslogID})`);
        unhide('viewEmail_popupdiv');
      })
      .catch(console.error());
  },
  viewSMS: (cLogID) => {
    PREP(['getMessageDetail'], {
      commslogID: cLogID,
    })
      .then((res) => {
        const recieved = id('date');
        const to = id('to');
        const from = id('from');
        const body = id('msgText');
        const resend = id('resendBtn');
        const dets = res.data.getMessageDetail[0];

        recieved.innerHTML = `${dets.spooled}`;
        to.innerHTML = `${dets.recipientName}`;
        from.innerHTML = `${dets.senderName}`;
        body.innerHTML = `${dets.emailBody}`;
        resend.setAttribute('onclick', `sF.resend(${dets.commslogID})`);
        unhide('viewSMS_popupdiv');
      })
      .catch(console.error());
  },
  resend: (commsLogID) => {
    PREP(['resendEmail'], {
      commsLogID: commsLogID,
    })
      .then((res) => {
        note(res.data.resendEmail);
      })
      .catch(console.error());
  },
};

//clientSelect clientDD on change redraw page
export const pageObj = {
  adminApplications() {
    pgstr.adminApplications.loadPage();
  },

  manageApplications() {
    pgstr.manageApplications.loadPage();
  },

  recordJob() {
    pgstr.recordJob.loadPage();
  },

  jobSpec() {
    this.recordJob();
  },
  manageJobs() {
    pgstr.manageJobs.loadPage();
  },

  MUt_users() {
    pgstr.MUt_users.loadPage();
  },

  MUt_advSearch() {
    PREP('MUt_advSearch');
  },

  MUt_compareUsers() {
    PREP('MUt_compareUsers');
  },

  EUt_developmentPlan() {
    PREP('EUt_developmentPlan');
  },

  EUt_LMS() {
    PREP('EUt_LMS');
  },

  addReport() {
    pgstr.addReport.loadPage();
  },
  manageReports() {
    pgstr.manageReports.loadPage();
  },
  arep_appstatus() {
    PREP('arep_appstatus');
  },
  arep_assstatus() {
    PREP('arep_assstatus');
  },
  arep_asssla() {
    PREP('arep_asssla');
  },
  arep_repdel() {
    PREP('arep_repdel');
  },
  arep_assbooksla() {
    PREP('arep_assbooksla');
  },
  arep_asspurpose() {
    PREP('arep_asspurpose');
  },
  arep_reportRegister() {
    PREP('arep_reportRegister');
  },
  arep_reportReview() {
    PREP('arep_reportReview');
  },
  arep_reportFailures() {
    PREP('arep_reportFailures');
  },
  reportAutoRules() {
    PREP('reportAutoRules');
  },
  rar_edit() {
    PREP('rar_edit');
  },
  resourceRegister() {
    PREP('resourceRegister');
  },
  JFt_jobLevels() {
    changeTabN('JFt_jobLevels');
  },
  JFt_servicePerBisinessUnit() {
    changeTabN('JFt_servicePerBisinessUnit');
  },
  JFt_publication() {
    changeTabN('JFt_publication');
  },
  JFt_family() {
    changeTabN('JFt_family');
  },
  JFt_costCenter() {
    changeTabN('JFt_costCenter');
  },
  MOt_reports() {
    PREP('MOt_reports');
  },
};

export const exPageMethod = (className, method, vars) => {
  console.log({
    className: className,
    method: method,
    vars: vars,
  });

  if (!pgstr) {
    console.error('pgstr is not defined');
    return;
  }

  if (!pgstr[className]) {
    console.error(`pgstr.${className} is not defined`);
    return;
  }

  if (typeof pgstr[className][method] !== 'function') {
    console.error(`pgstr.${className}.${method} is not a function`);
    return;
  }

  if (vars) {
    pgstr[className][method](vars);
  } else {
    pgstr[className][method]();
  }
};
window.exPageMethod = exPageMethod;
