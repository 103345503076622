import { forEach } from 'lodash';
import { domainPreFix, clientLogoLogin, domainSuffix, noLoginLogos, SSOauth, domainClient, navClientLogo, clientImages, noSocial, LMSiFrameLink, isLive } from './config';

import {
  idleStart,
  initSession,
  createCookie,
  getCookie,
  PREP,
  createLangObj,
  insertDiv,
  id,
  loader,
  formatYearMonthDay,
  isEmpty,
  cObj,
  sanatizeNum,
  buildDropDownMain,
  hide,
  unhide,
  uuidvMain,
  note,
  errBox,
  infoBox,
  custardBox,
  countryCode,
  ccRegex,
  validatePage,
  divAtt,
  completedPercent,
  addAtt,
  changeTabN,
  dateFromDateTime,
  dateShortFromDateTime,
  bytesToSize,
  buildInpText,
  buildInpEmail,
  buildInpMobNum,
  buildBtnFormCenter,
  eListen,
  eCustardBox,
  passwordReminder,
  btnModalOn,
  validatePasswordConfirmation
} from './core';
import { additionalService } from './adam';
import { repeatSVG } from './svgIcons';

// =======================================================

export const login = {
  loginPage: async () => {
    const image = clientImages[`${domainPreFix}`] ? clientImages[`${domainPreFix}`] : clientImages['integratezone'];
    document.getElementById('coverImg').src = image.loginBG;

    login.clientLogo();

    if (SSOauth.includes(domainPreFix) || SSOauth.includes(domainSuffix)) {
      if (id('ssoBtn')) {
        unhide('ssoBtn');
      }
    }

    if (!isLive) {
      document.getElementById('borderQA').classList.remove('hide');
    }
  },
  clientLogo: () => {
    const image = clientImages[`${domainPreFix}`] ? clientImages[`${domainPreFix}`] : clientImages['integratezone'];

    // Client logo
    const companyLogo = document.getElementById('loginLogos');
    if (clientLogoLogin.includes(domainPreFix) || clientLogoLogin.includes(domainSuffix)) {
      if (image.clientLogo !== 'clientLogo_integrate.svg') {
        const img = document.createElement('img');
        img.src = image.clientLogo;
        companyLogo.appendChild(img);
      }
    }

    // No login logo requirement
    if (noLoginLogos.includes(domainPreFix) || noLoginLogos.includes(domainSuffix)) {
      document.getElementById('integrateLogo').style = 'display: none;';
    }
  },
  langLoginDD: () => {
    id('langDDLogin').classList.toggle('hide');
    id('DDOverlay').classList.toggle('hide');
    id('langChev').classList.toggle('chevRotate');
  },
  forgotPass: () => {
    // const lang = parseInt(getCookie('mbohvbhfje')) - 1;
    // const btn = id("loginBtn");
    // btn.setAttribute("onclick", "passwordReminder()");

    const btnText = id('forgotLoadingText');
    btnText.innerHTML = infoText.inf_Mkr32;

    hide('loginBtn');
    unhide('forgotPassBtn');

    hide('loginDiv');
    unhide('forgotPassDiv');

    hide('supportDiv');
    unhide('backToLoginDiv');
  },
  backToLogin: () => {
    // const lang = parseInt(getCookie('mbohvbhfje')) - 1;
    // const btn = id("loginBtn");
    // btn.setAttribute("onclick", "authLogin2()");

    unhide('loginBtn');
    hide('forgotPassBtn');

    const btnText = id('loadingText');
    btnText.innerHTML = infoText.inf_Mkr31;

    unhide('loginDiv');
    hide('forgotPassDiv');

    unhide('supportDiv');
    hide('backToLoginDiv');
  },
  supportRequest: () => {
    if (!validatePage()) {
      return infoBox('Please fill in all required fields.');
    }

    const loader1 = divAtt('div', 'id', 'loader1');
    document.body.appendChild(loader1);

    const vars = {};
    vars.fname = id('fname').value;
    vars.sname = id('sname').value;
    vars.emailaddress = id('email').value;
    vars.issueType = id('issueType').value;
    vars.usercomments = id('description').value;
    vars.languageID = id('langDD').value;
    vars.HuserID = 0;

    if (id('fileAttachInp').value) {
      vars.uploadfile = id('fileAttachInp').files[0];
      vars.f_filename = id('fileAttachInp').files[0].name;
    } else {
      vars.uploadfile = '';
      vars.f_filename = '';
    }

    PREP(['contactForm'], vars) //doContactForm
      .then((res) => {
        note(res.data.contactForm);
        document.body.removeChild(loader1);
        insertDiv('loginContactUs', 'primaryContainer');
      })
      .catch((err) => {
        console.log(err);
        document.body.removeChild(loader1);
      });
  },
  attachSupFile: () => {
    const file = id('fileAttachInp');
    if (file.files[0]) {
      id('fileNameDisplay').innerHTML = file.files[0].name;

      hide('addFile');
      unhide('fileDisplay');
    } else {
      unhide('addFile');
      hide('fileDisplay');
      infoBox('Please choose a valid file.');
    }
  },
  rmvFile: () => {
    id('fileAttachInp').value = '';
    unhide('addFile');
    hide('fileDisplay');
  },
  openSupportVid: () => {
    const langValue = id('langDD').value;

    //Videos availible in langs
    let supportVidsArr = ['10', '11'];

    if (supportVidsArr.includes(langValue)) {
      window.open(`https://integratezone.com/${langValue}/support/story.html`, '_blank');
    } else {
      window.open('https://integratezone.com/1/support/story.html', '_blank');
    }
  },
  validateReset: async (resetUUID, resetHuserID) => {
    const vars = {};
    vars.resetUUID = resetUUID;
    vars.resetHuserID = resetHuserID;
    vars.resetlanguageID = getCookie('mbohvbhfje');

    PREP(['validateReset'], vars)
      .then(async (res) => {
        const data = res.data.validateReset;

        if (data) {
          initSession();
          errBox(`${data}<br> Please submit your email address under "Forgot Password" for a new link.`);

          setTimeout(() => {
            login.forgotPass();
          }, 1500);
        } else {
          await initSession(resetUUID, resetHuserID);
          login.resetPassBox();
        }
      })
      .catch(console.error());
  },
  resetPassBox: () => {
    const vars = {};
    vars.HTMLPageName = 'UPt_profile';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getHTMLLexicon'], vars)
      .then((res) => {
        const langObj = {};

        const data = res.data.getHTMLLexicon;
        for (let row of data) {
          langObj[row.marker] = row.display;
        }

        const div = divAtt('div', 'class', '');
        const p = divAtt('p', 'class', 'sectionGap');
        p.innerHTML = langObj.upp_Mkr18;

        const inputDiv = divAtt('div', 'class', 'grid-2col-input-custard marginBottom upp_Mkr18');
        inputDiv.innerHTML = `<div>
        <div class="group">
            <input id="f_password" type="password" autocomplete="new-password" onblur="hidePassBox()"
                oninput="inpPass(this.id)">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label for="">${langObj.upp_Mkr6}</label>

            <svg id="eye1" class="viewPass" onclick="viewPass('f_password', this.id)" xmlns="http://www.w3.org/2000/svg" width="25.7" height="15.32" viewBox="0 0 25.7 15.32"><path d="M25.5,7.06C22.22,2.69,17.77,0,12.86,0S3.49,2.69.2,7.05a1.05,1.05,0,0,0,0,1.22c3.29,4.37,7.74,7.05,12.66,7.05s9.36-2.68,12.64-7A1,1,0,0,0,25.5,7.06ZM18.25,8a5.4,5.4,0,1,1,0-.7A4.09,4.09,0,0,1,18.25,8Z" transform="translate(0 0)"/><path d="M12.67,4.76A2.91,2.91,0,1,0,13,10.57h0a2.92,2.92,0,0,0,2.72-2.72,2.28,2.28,0,0,0,0-.37A2.9,2.9,0,0,0,12.67,4.76Z" transform="translate(0 0)"/></svg>
        </div>

        <!-- Password validation -->
        <div id="passValBox" class="hide">
            <h5 class="upp_Mkr19">${langObj.upp_Mkr19}</h5>
            <ul>
                <li id="minChar" class="upp_Mkr20">${langObj.upp_Mkr20}</li>
                <li id="upCase" class="upp_Mkr21">${langObj.upp_Mkr21}</li>
                <li id="lowCase" class="upp_Mkr22">${langObj.upp_Mkr22}</li>
                <li id="num" class="upp_Mkr23">${langObj.upp_Mkr23}</li>
                <li id="special" class="upp_Mkr24">${langObj.upp_Mkr24}</li>
            </ul>
        </div>
    </div>

    <div>

        <div class="group">
            <input id="f_confirm_password" type="password" onblur="hidePassBox()"
                oninput="matchPass('f_password',this.id)" autocomplete="new-password">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label for="">${langObj.upp_Mkr7}</label>

            <svg id="eye2" class="viewPass" onclick="viewPass('f_confirm_password', this.id)" xmlns="http://www.w3.org/2000/svg" width="25.7" height="15.32" viewBox="0 0 25.7 15.32"><path d="M25.5,7.06C22.22,2.69,17.77,0,12.86,0S3.49,2.69.2,7.05a1.05,1.05,0,0,0,0,1.22c3.29,4.37,7.74,7.05,12.66,7.05s9.36-2.68,12.64-7A1,1,0,0,0,25.5,7.06ZM18.25,8a5.4,5.4,0,1,1,0-.7A4.09,4.09,0,0,1,18.25,8Z" transform="translate(0 0)"/><path d="M12.67,4.76A2.91,2.91,0,1,0,13,10.57h0a2.92,2.92,0,0,0,2.72-2.72,2.28,2.28,0,0,0,0-.37A2.9,2.9,0,0,0,12.67,4.76Z" transform="translate(0 0)"/></svg>

        </div>
        <!-- match password validation -->
        <div id="passMatchBox" class="hide">
            <h5 class="upp_Mkr25">${langObj.upp_Mkr25}</h5>
        </div>
    </div>`;
        const btn = divAtt('btn', 'class', 'btn upp_Mkr17');
        // btn.setAttribute('onclick', `login.resetPassword()`);
        btn.innerHTML = langObj.upp_Mkr17;
        div.append(p);
        div.append(inputDiv);
        div.append(btn);

        // custardBox(div, `${langObj.upp_Mkr17}`);

        eCustardBox(div, `${langObj.upp_Mkr17}`, [
          [
            btn,
            () => {
              login.resetPassword();
            },
            'click',
          ],
        ]);
      })
      .catch(console.error());
  },
  resetPassword: () => {
    if (id('f_password').value === id('f_confirm_password').value) {
      const vars = {};
      vars.currentPassword = id('f_password').value;

      PREP(['updCandiPassword'], vars)
        .then((res) => {
          infoBox(`${res.data.updCandiPassword}<br> Please login with your new password.`);
          initSession();
          hide('custardBox');
          window.location.search = '';
        })
        .catch(console.error());
    } else {
      const vars = {};
      vars.HTMLPageName = 'UPt_profile';
      vars.languageID = getCookie('mbohvbhfje');

      PREP(['getHTMLLexicon'], vars).then((res) => {
        const langObj = {};

        const data = res.data.getHTMLLexicon;
        for (let row of data) {
          langObj[row.marker] = row.display;
        }

        errBox(langObj.upp_Mkr25);
        hide('custardBox');
        login.resetPassBox();
      });
    }
  },
  SSOauth: async () => {
    await createCookie('risutuqedue', uuidvMain()); //requestUUID
    const vars = {};
    vars.requestUUID = getCookie('risutuqedue');

    PREP(['getAccessTokenAADB'], vars).then((res) => {
      const newURL = res.data.getAccessTokenAADB;
      window.location.href = newURL;
    });
  },
  SSOauthLogin: (accessToken) => {
    loader(1);

    const vars = {};
    vars.accessTokenAADB = accessToken;
    vars.languageID = getCookie('mbohvbhfje') || '1';
    vars.requestUUID = getCookie('risutuqedue');

    PREP(['oauthAADB'], vars)
      .then((res) => {
        history.pushState(null, null, '/');
        login.setLoggedIn(res.data.oauthAADB);
        loader(0);
      })
      .catch((err) => {
        history.pushState(null, null, '/');
        console.log(err);
        //Here new custard msg
        loader(0);
      });
  },
  setLoggedIn: (loginData) => {
    const vars2 = {};
    vars2.languageID = getCookie('mbohvbhfje') || '1'; //id("langDD").value;
    vars2.HTMLPageName = 'infoText';
    PREP(['getHTMLLexicon'], vars2).then(async (res) => {
      window.infoText = await createLangObj(res.data.getHTMLLexicon);
    });

    const data = cObj(loginData);
    const addDataList = {
      localUserID: 1,
      allocatedClients: JSON.parse(data[0].ALLOCATEDHCLIENTS),
      primaryClientID: data[0].PRIMARYCLIENT,
    };

    createCookie('ewttgpvwwkf', data[0].CURRENTUUID);
    createCookie('jwugtkf', data[0].HUSERID);
    createCookie('mbohvbhfje', vars2.languageID);
    createCookie('primaryClient', data[0].PRIMARYCLIENT);

    clearAllDataS('userProfile').then(() => {
      addDataS('userProfile', addDataList);
    });

    insertDiv('homePage', 'primaryContainer');
    idleStart();

    id('defaultLoggedInHomepage').value = data[0].DEFAULTLOGGEDINHOMEPAGE;
    id('cookedAcceptedYN').value = data[0].COOKIEACCEPTYN;
  },
};

export const contactUs = {
  supportRequest: () => {
    if (!validatePage()) {
      return infoBox('Please fill in all required fields.');
    }

    const vars = {};
    vars.fname = id('fname').value;
    vars.sname = id('sname').value;
    vars.emailAddress = id('email').value;
    vars.issueType = id('issueType').value;
    vars.userComments = id('description').value;
    vars.languageID = id('langDD').value;
    vars.HuserID = getCookie('jwugtkf');

    if (id('fileAttachInp').value) {
      vars.uploadfile = id('fileAttachInp').files[0];
      vars.f_filename = id('fileAttachInp').files[0].name;
    } else {
      vars.uploadfile = '';
      vars.f_filename = '';
    }

    PREP(['contactForm'], vars) //doContactForm
      .then((res) => {
        note(res.data.doContactForm);
        PREP('contactUs');
      })
      .catch(console.error());
  },
};

export const homePage = {
  buildNav: (data) => {
    const langData = data.getHTMLLexicon;

    // USER ===
    const userNav = data.getCandiNavigationElements[0].verticalNav;
    homePage.userNav(userNav);

    // CANDIDATE ===
    const candiNav = data.getCandiNavigationElements[0].horizontalNavCandi;
    homePage.candiNav(candiNav, langData);

    // ADMIN ===
    const adminNav = data.getCandiNavigationElements[0].horizontalArrayAdmin;
    homePage.adminNav(adminNav, langData);

    // LANGUAGE ===
    homePage.buildLangDD();
    // LOGGEDIN HOMEPAGE ===
    homePage.home();
    // COOKIES BANNER ===
    homePage.cookiesNote();

    // CLIENT LOGO NAV
    // @steph

    const image = clientImages[`${domainPreFix}`] ? clientImages[`${domainPreFix}`] : clientImages['integratezone'];
    if (navClientLogo.includes(domainPreFix) || navClientLogo.includes(domainSuffix)) {
      const companyLogo = id('logos');
      const img = document.createElement('img');
      img.src = image.clientNavLogo;
      companyLogo.appendChild(img);
    }

    if (noSocial.includes(domainPreFix) || noSocial.includes(domainSuffix)) {
      hide('social');
    }

    const vars = {};
    vars.detailsUserID = getCookie('jwugtkf');

    PREP(['getUserDets'], vars)
      .then((res) => {
        homePage.setLang(res.data);
        homePage.personalize(res.data);
        createCookie('userClientID', res.data.getUserDets[0].HclientID);
      })
      .catch(console.error());
  },
  userNav: (data) => {
    // Verticle Nav

    id('userDD').innerHTML = '';

    const relObj = {
      UPt: 'userProfile',
    };

    for (let nav of data) {
      let split = nav.entityName.split('_');
      let attr = '';
      attr = split.length > 1 ? `PREP('${relObj[split[0]]}','dTab=${nav.entityName}'), navSel()` : `PREP('${nav.entityName}'), navSel()`;

      const navItem = document.createElement('div');

      if (nav.entityName === 'support') {
        navItem.setAttribute('onclick', 'login.openSupportVid()');
      } else {
        navItem.setAttribute('onclick', attr);
      }

      navItem.setAttribute('class', 'userDDItem');
      navItem.innerHTML = `${nav.iconSVG}${nav.shortDescription}`;
      id('userDD').append(navItem);
    }

    // LOGOUT ===
    const lang = parseInt(getCookie('mbohvbhfje')) - 1;

    const hr = divAtt('hr', 'class', '');
    id('userDD').append(hr);
    const logout = document.createElement('div');
    logout.setAttribute('onclick', `logout(2)`);
    logout.setAttribute('class', 'userDDItem');
    logout.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="21.16" height="21.16" viewBox="0 0 21.16 21.16">
    <path
        d="M10.89,14.07a1,1,0,0,0,.69,1.65.93.93,0,0,0,.68-.28l4.18-4.17a1,1,0,0,0,.28-.69,1,1,0,0,0-.28-.68L12.26,5.72a1,1,0,0,0-1.37,0,.94.94,0,0,0,0,1.36l2.52,2.53H1a1,1,0,0,0,0,1.94H13.41Z"
        transform="translate(0 0)" />
    <path
        d="M19.45,0H10.58a1,1,0,0,0-1,1,1,1,0,0,0,1,1h8.64V19.22H10.58a1,1,0,0,0,0,1.94h8.87a1.73,1.73,0,0,0,1.71-1.71V1.71A1.72,1.72,0,0,0,19.45,0Z"
        transform="translate(0 0)" /></svg>
    <span style="font-weight: bold;">${infoText.inf_Mkr30}</span>`;

    id('userDD').append(logout);

    hide('userDD');
  },
  candiNav: (data, langData) => {
    // Horizontal Nav

    id('candiNav').innerHTML = '';

    // HOME Button ===
    const home = divAtt('div', 'onclick', `homePage.home()`);
    home.setAttribute('id', 'homeBtn');
    home.style.display = 'none';
    home.innerHTML = `<svg class="bar2SVG" xmlns="http://www.w3.org/2000/svg" width="27.08" height="26.99" viewBox="0 0 27.08 26.99"><g id="Group_1078" data-name="Group 1078"><path id="Path_965" data-name="Path 965" d="M24.1,27H2.9a1.23,1.23,0,0,1-.83-1.33c0-3.78,0-7.56,0-11.34V14l-.22.21A1.06,1.06,0,1,1,.27,12.79l.1-.09L12.7.36a1.32,1.32,0,0,1,.49-.31,1.11,1.11,0,0,1,1.18.37l12.1,12.12a4.24,4.24,0,0,1,.46.65.81.81,0,0,1,.12.29.67.67,0,0,1,0,.37,1.15,1.15,0,0,1-1.48.65,1.19,1.19,0,0,1-.42-.28,1.83,1.83,0,0,0-.24-.22v.36c0,3.77,0,7.54,0,11.3A1.24,1.24,0,0,1,24.1,27m-1.28-2.13V12.09a.64.64,0,0,0-.18-.4L13.7,2.74l-.2-.18-.13.12-9,9a.66.66,0,0,0-.15.42q0,6.21,0,12.42v.31H8.3v-.34c0-2.23,0-4.46,0-6.69a1.18,1.18,0,0,1,1.09-1.27,1.29,1.29,0,0,1,.39,0s7.5,0,7.51,0a1.18,1.18,0,0,1,1.41.88,1.09,1.09,0,0,1,0,.36c0,2.24,0,4.48,0,6.72v.32Zm-12.4,0h6.16V18.72H10.42Z" transform="translate(0 0)"/></g></svg>Home`;
    id('candiNav').append(home);

    if (data.length) {
      for (let nav of data) {
        const navItem = divAtt('div', 'onclick', `PREP('${nav.entityName}'), navSel(this.id)`);
        navItem.innerHTML = `${nav.iconSVG}${nav.shortDescription}`;
        navItem.setAttribute('class', 'navItem');
        navItem.setAttribute('id', `${nav.entityName}`);
        id('candiNav').append(navItem);

        const langObj = {};
        for (let row of langData) {
          langObj[row.marker] = row.display;
        }

        if (nav.entityName === 'myassessments') {
          navItem.classList.add('toolTip');
          const toolTip = divAtt('span', 'class', 'toolTipText');
          toolTip.innerHTML = langObj.hpn_Mkr27 ? langObj.hpn_Mkr27 : 'Please click on this tab to access your individual assessments and reports.';
          navItem.append(toolTip);
        }
      }
    }
  },
  adminNav: (data, langData) => {
    id('adminNav').innerHTML = '';

    const submenus = [];
    const applications = [];

    if (data.length) {
      for (let subs of data) {
        subs.parentEntityName && submenus.push(subs);
      }

      for (let apps of data) {
        apps.navCategory === 'applications' && applications.push(apps);
      }

      const prepArr = ['profileMaster', 'manageUsers', 'adminApplications', 'manageJobs', 'myassessments', 'manageApplications', 'applications', 'manageGroups'];

      for (let nav of data) {
        if (!nav.parentEntityName && nav.navCategory !== 'applications') {
          const navItemMain = divAtt('div');
          const navItem = divAtt('div');
          navItem.innerHTML = `${nav.iconSVG}${nav.shortDescription}`;
          navItem.setAttribute('class', 'navItem');
          navItem.setAttribute('id', `${nav.entityName}`);

          //Onclick action
          // let call;
          // if (nav.entityName === 'eFeedback') {
          //   call = 'checkFeedback("eFeedback")';
          // } else if (nav.entityName === 'myDevelopment') {
          //   call = 'checkDevPlan()'
          // } else {
          //   if (prepArr.includes(nav.entityName)) {
          //     call = `PREP("${nav.entityName}")`
          //   } else {
          //     call = 'switchBox("' + nav.entityName + '")'
          //   }
          //   navItem.setAttribute('onclick', call);
          navItem.setAttribute('onclick', `PREP("${nav.entityName}"), navSel(this.id)`);
          navItemMain.appendChild(navItem);

          // submenus
          const prepSubArr = ['manageQuestions', 'manageRoles', 'mobilePrefix', 'messageTemplates', 'addClient', 'addReport', 'addUser', 'emailAttachments', 'recordJob', 'sysCompetencies', 'addApplications', 'arep_repdel', 'arep_asssla', 'arep_jobs', 'arep_appstatus', 'arep_assstatus'];

          const subDiv = divAtt('div', 'class', 'subMenu');
          for (let sub of submenus) {
            if (sub.parentEntityName === nav.entityName) {
              const subitem = divAtt('div', 'class', 'subItem');
              subitem.setAttribute('id', sub.entityName);
              subitem.setAttribute('onclick', `PREP("${sub.entityName}"), navSel("${sub.parentEntityName}")`);
              // if (prepSubArr.includes(sub.entityName)) {
              //   subitem.setAttribute('onclick', 'PREP("' + sub.entityName + '")');
              // } else {
              //   subitem.setAttribute('onclick', 'switchBox("' + sub.entityName + '")');
              // }
              subitem.innerHTML = sub.shortDescription;
              subDiv.appendChild(subitem);
              navItemMain.appendChild(subDiv);
            }
          }

          id('adminNav').append(navItemMain);
        }
      }
    } else {
      hide('adminNav');
    }
    // Admin Nav
    if (applications.length) {
      homePage.applicationNav(applications, langData);
    }
  },
  applicationNav: (data, langData) => {
    const submenus = [];
    for (let subs of data) {
      subs.parentEntityName && submenus.push(subs);
    }
    const mainNavs = [];
    for (let main of data) {
      !main.parentEntityName && mainNavs.push(main);
    }

    const navItemMain = divAtt('div');
    if (mainNavs.length === 1) {
      for (let nav of data) {
        if (!nav.parentEntityName) {
          // const navItemMain = divAtt('div');
          const navItem = divAtt('div');
          navItem.innerHTML = `${nav.iconSVG}${nav.shortDescription}`;
          navItem.setAttribute('class', 'navItem');
          navItem.setAttribute('id', `${nav.entityName}`);

          navItem.setAttribute('onclick', `PREP("${nav.entityName}"), navSel(this.id)`);
          navItemMain.appendChild(navItem);

          // submenus
          const subDiv = divAtt('div', 'class', 'subMenu');
          for (let sub of submenus) {
            if (sub.parentEntityName === nav.entityName) {
              const subitem = divAtt('div', 'class', 'subItem');
              subitem.setAttribute('id', sub.entityName);
              subitem.setAttribute('onclick', `PREP("${sub.entityName}"), navSel("${sub.parentEntityName}")`);
              subitem.innerHTML = sub.shortDescription;
              subDiv.appendChild(subitem);
              navItemMain.appendChild(subDiv);
            }
          }
          id('adminNav').append(navItemMain);
        }
      }
    } else {
      // Build New sub-sub Nav

      // const navItemMain = divAtt('div');
      const navItem = divAtt('div');
      navItem.innerHTML = `Applications`;
      navItem.setAttribute('class', 'navItem');
      navItem.setAttribute('id', `apps`);
      navItem.setAttribute('onclick', ``);
      navItemMain.appendChild(navItem);

      const subDiv = divAtt('div', 'class', 'subMenu');
      for (let nav of data) {
        const subitem = divAtt('div', 'class', 'subItem');
        subitem.setAttribute('id', nav.entityName);
        subitem.setAttribute('onclick', `PREP("${nav.entityName}"), navSel("apps")`);

        if (nav.entityName === 'angloApplications') {
          subitem.innerHTML = 'Anglo Applications';
        } else if (nav.entityName === 'adminApplications') {
          subitem.innerHTML = 'Admin Applications';
        } else if (nav.entityName === 'applications') {
          subitem.innerHTML = 'Discovery Applications';
        } else if (nav.entityName === 'addApplications') {
          subitem.innerHTML = 'Record Discovery Application';
        } else if (nav.entityName === 'recordAngloApp') {
          subitem.innerHTML = 'Record Anglo Application';
        } else {
          subitem.innerHTML = nav.shortDescription;
        }

        subDiv.appendChild(subitem);
        navItemMain.appendChild(subDiv);
        id('adminNav').append(navItemMain);

        // if (!nav.parentEntityName) {
        //   const subitem = divAtt('div', 'class', 'subItem');
        //   subitem.setAttribute('id', nav.entityName);
        //   subitem.setAttribute('onclick', `PREP("${nav.entityName}"), navSel("apps")`);

        //   if (nav.entityName === 'angloApplications') {
        //     subitem.innerHTML = 'Anglo Applications';
        //   } else if (nav.entityName === 'adminApplications') {
        //     subitem.innerHTML = 'Admin Applications';
        //   } else if (nav.entityName === 'applications') {
        //     subitem.innerHTML = 'Discovery Applications';
        //   } else {

        //     subitem.innerHTML = nav.shortDescription;
        //   }

        //   subDiv.appendChild(subitem);
        //   navItemMain.appendChild(subDiv);

        //   // sub-sub menus
        //   const subSubDiv = divAtt('div', 'class', 'subSubMenu');
        //   for (let sub of submenus) {
        //     if (sub.parentEntityName === nav.entityName) {
        //       const subitem2 = divAtt('div', 'class', 'subItem');
        //       subitem2.setAttribute('id', sub.entityName);
        //       subitem2.setAttribute('onclick', `PREP("${sub.entityName}"), navSel("apps")`);
        //       subitem2.innerHTML = sub.shortDescription;
        //       subSubDiv.appendChild(subitem2);
        //       subDiv.appendChild(subSubDiv);
        //     }
        //   }
        //   id('adminNav').append(navItemMain);
        // }
      }
    }

    const langObj = {};
    for (let row of langData) {
      langObj[row.marker] = row.display;
    }

    navItemMain.classList.add('toolTip');
    const toolTip = divAtt('span', 'class', 'toolTipText');
    toolTip.classList.add('appTip');
    toolTip.innerHTML = langObj.hpn_Mkr28 ? langObj.hpn_Mkr28 : 'Please  click  on  this  tab  to  access  candidate applications and reports.';
    navItemMain.append(toolTip);

    unhide('adminNav');
  },
  buildLangDD: () => {
    const vars = {};
    vars.HclientID = domainClient;

    PREP(['getLanguagesPerClient'], vars)
      .then((res) => {
        const data = res.data.getLanguagesPerClient;

        const el = id('langDD');
        el.options.length = '';

        if (data.length) {
          data.forEach((arr) => {
            const opt = divAtt('option', 'value', `${arr.HlanguageID}`);
            opt.innerHTML = arr.shortname;
            el.appendChild(opt);
          });
        }

        el.value = getCookie('mbohvbhfje') || 1;
        el.setAttribute('onchange', 'homePage.changeLang()');
      })
      .catch(console.error());
  },
  setLang: (data) => {
    const lang = data.getUserDets[0].languageID;
    if (lang !== '') {
      id('langDD').value = lang;
    }
  },
  changeLang: async () => {
    hide('custardBox');
    await createCookie('mbohvbhfje', id('langDD').value);
    initSession();

    insertDiv('login', 'primaryContainer');
    hide('custardBox');
  },
  changeLangWarning: () => {
    const vars = {};
    vars.HTMLPageName = 'homePage';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getHTMLLexicon'], vars).then((res) => {
      const langObj = {};
      const data = res.data.getHTMLLexicon;
      for (let row of data) {
        langObj[row.marker] = row.display;
      }

      const div = divAtt('div', 'class', '');
      const p = divAtt('p', 'class', 'marginTop marginBottom');
      p.innerHTML = `${langObj.hpn_Mkr23}<br>${langObj.hpn_Mkr24}`;
      const cancelBtn = divAtt('button', 'class', 'btnCancel');
      cancelBtn.innerHTML = langObj.hpn_Mkr25;
      cancelBtn.setAttribute('onclick', `homePage.cancelLangChange()`);
      const btn = divAtt('button', 'class', 'btn upp_Mkr17');
      btn.innerHTML = langObj.hpn_Mkr26;
      btn.setAttribute('onclick', `homePage.changeLang()`);

      div.appendChild(p);
      div.appendChild(cancelBtn);
      div.appendChild(btn);

      custardBox(div, langObj.hpn_Mkr22);
    });
  },
  personalize: (data) => {
    id('userName').innerHTML = data.getUserDets[0].fname;
    id('userImg').src = data.getUserDets[0].profileImage;
  },
  cookiesNote: () => {
    const cookie = id('cookedAcceptedYN').value;
    if (cookie === 'false') {
      unhide('cookieContainer');
    } else {
      hide('cookieContainer');
    }
  },
  acceptCookies: () => {
    const vars = {};
    vars.cookieYN = 1;

    PREP(['updateCookieStatus'], vars)
      .then((res) => {
        note(res.data.updateCookieStatus);
        hide('cookieContainer');
      })
      .catch(console.error());
  },
  home: () => {
    // Load default logged in homePage ===
    const defaultHomePage = id('defaultLoggedInHomepage').value;
    PREP(defaultHomePage);
  },
  userDD: () => {
    id('userDD').classList.toggle('hide');
    id('DDOverlay').classList.toggle('hide');
    id('userChevron').classList.toggle('chevRotate');
  },
  closeDD: () => {
    id('userDD').classList.add('hide');
    id('DDOverlay').classList.add('hide');
    id('userChevron').classList.remove('chevRotate');
  },
  cancelLangChange: () => {
    id('langDD').value = getCookie('mbohvbhfje');
    hide('custardBox');
  },
  changeLandingTab: async (blockId) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
      const blockName = block.id;
      hide(`${blockName}_content`);
    });

    id(blockId).classList.add('blockActive');
    unhide(`${blockId}_content`);
  },
};
window.homePage = homePage;

export const UPt_profile = {
  profilePage: async (data, bioDIvs) => {
    id('HclientID').value = data.HclientID;
    id('f_firstName').value = data.fname;
    id('f_surname').value = data.sname;
    id('f_email').value = data.emailAddress;
    countryCode(data.mobileNumber, data.HclientID);
    id('reportTypeIDCV').value = data.reportTypeIDCV;
    id('detailsUserID').value = data.HuserID;
    id('userNameHeading').innerHTML = `${data.fname} ${data.sname}`;
    // ccRegex(0, 0);

    let profilePic = id('dp');
    let imageSrc;

    if (data.candidateCVID) {
      const viewCV = id('viewCV');
      viewCV.type = 'button';
      //viewCV.setAttribute("onclick", `report.download(${data.candidateCVID})`);
      eListen(
        'viewCV',
        () => {
          MAt_reports.download(data.candidateCVID);
        },
        'click'
      );
      unhide('viewCV');
    }
    if (data.linkedInURL) {
      const viewLI = id('viewLinkedIn');
      viewLI.type = 'button';
      // viewLI.setAttribute(
      //   "onclick",
      //   `UPt_profile.viewLI('${data.linkedInURL}')`
      // );
      eListen(
        'viewLinkedIn',
        () => {
          UPt_profile.viewLI(data.linkedInURL);
        },
        'click'
      );
      unhide('viewLinkedIn');
    }
    if (data.profileImage === '') {
      imageSrc = imgPlaceholderURL;
    } else {
      imageSrc = data.profileImage;
    }
    profilePic.name = data.profileImage;
    profilePic.src = imageSrc;
    profilePic.setAttribute('onload', 'OnImageLoad(event, 150)');
    id('userImg').scr = imageSrc;

    // BIO SECTION =========

    const vars = {};
    vars.detailsUserID = data.HuserID;
    vars.HclientID = data.HclientID;
    vars.grouping = 'bio';
    await PREP(['getDDethnicity', 'getDDgender', 'getDDCountry'], vars)
      .then((res) => {
        buildDropDownMain(cObj(res.data.getDDethnicity), 'f_ethnicity', 'DDETHNICITYID', 'DESCRIPTION');
        buildDropDownMain(cObj(res.data.getDDgender), 'f_gender', 'DDGENDERID', 'SHORTDESCRIPTION');
        buildDropDownMain(cObj(res.data.getDDCountry), 'f_resCountry', 'DDCOUNTRYID', 'DESCRIPTION');
      })
      .catch(console.error());

    //country of origin 'pacific grouping'
    const vars1 = {};
    vars1.detailsUserID = data.HuserID;
    vars1.HclientID = data.HclientID;
    vars1.grouping = 'application';
    await PREP(['getDDCountry'], vars1)
      .then((res) => {
        buildDropDownMain(cObj(res.data.getDDCountry), 'countryOfOrigin', 'DDCOUNTRYID', 'DESCRIPTION');
      })
      .catch(console.error());

    // Bio section
    for (let item of bioDIvs) {
      let elem = id(item.domElementID);
      if (elem) {
        if (elem.id === 'f_idPassport') {
          elem.classList.remove('hide');
          id('candidateIDnr').classList.add('ativeInp');
        } else {
          elem.parentElement.classList.remove('hide');
          elem.classList.add('ativeInp');
        }
      }
    }

    //Populate Dropdowns
    id('f_ethnicity').value = data.ethnicityID ? data.ethnicityID : '-';
    id('f_dob').value = data.DOB ? formatYearMonthDay(data.DOB) : '';
    id('f_gender').value = data.GenderID ? data.GenderID : '-';
    id('f_resCountry').value = data.residentCountryID ? data.residentCountryID : '-';
    id('candidateIDnr').value = data.IDnumber ? data.IDnumber : '';
    id('candidatePassportnr').value = data.Passportnumber === 'N/A' ? '' : data.Passportnumber;

    //CLIENT Pacific requirements
    //Discovery
    if (getCookie('primaryClient') == 4) {
      id('f_resCountry').setAttribute('onchange', `UPt_profile.selectCountryDISC(), notEmptyVal(this.id)`);
      id('f_ethnicity').setAttribute('onchange', `UPt_profile.selectEthnicityDISC(), notEmptyVal(this.id)`);

      UPt_profile.selectEthnicityDISC();
      UPt_profile.selectCountryDISC();
    }
  },
  selectDP: () => {
    var iMaxFilesize = 1024000; // 1MB
    var oFile = id('image_file').files[0];

    const vars = {};
    vars.HTMLPageName = 'UPt_profile';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getHTMLLexicon'], vars).then((res) => {
      const langObj = {};
      const data = res.data.getHTMLLexicon;
      for (let row of data) {
        langObj[row.marker] = row.display;
      }

      // filter for image files
      var rFilter = /^(image\/gif|image\/jpeg|image\/png)$/i;
      if (!rFilter.test(oFile.type)) {
        const formatMsg = langObj.upp_Mkr27 ? langObj.upp_Mkr27 : 'This image is an invalid format. Please select an image files ending in .jpeg or .png';
        return infoBox(formatMsg);
      }
      // little test for filesize
      if (oFile.size > iMaxFilesize) {
        const sizeMsg = langObj.upp_Mkr28 ? langObj.upp_Mkr28 : 'This image is too big. Please select a files size smaller than 1MB.';
        return infoBox(sizeMsg);
      }

      // get preview element
      var oImage = id('dp');
      //let sResultFileSize = bytesToSize(oFile.size);
      // prepare HTML5 FileReader
      var oReader = new FileReader();
      oReader.onload = function (e) {
        var img = new Image();
        img.onload = function () {
          oImage.src = e.target.result;
          oImage = addAtt(oImage, 'onload', 'OnImageLoad(event, 150)');
          oImage = addAtt(oImage, 'name', oFile.name);
        };
        img.src = e.target.result;
      };

      // read selected file as DataURL
      oReader.readAsDataURL(oFile);
    });
  },
  changePassBox: async () => {
    const vars = {};
    vars.HTMLPageName = 'UPt_profile';
    vars.languageID = getCookie('mbohvbhfje');

    await PREP(['getHTMLLexicon'], vars)
      .then((res) => {
        const langObj = {};

        const data = res.data.getHTMLLexicon;
        for (let row of data) {
          langObj[row.marker] = row.display;
        }

        const div = divAtt('div', 'class', '');
        const p = divAtt('p', 'class', 'sectionGap');
        p.innerHTML = langObj.upp_Mkr18;

        const inputDiv = divAtt('div', 'class', 'grid-2col-input-custard marginBottom upp_Mkr18');
        inputDiv.innerHTML = `<div>
        <div class="group">
            <input id="f_password" type="password" autocomplete="new-password" onblur="hidePassBox()"
                oninput="inpPass(this.id)">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label for="">${langObj.upp_Mkr6}</label>

            <svg id="eye1" class="viewPass" onclick="viewPass('f_password', this.id)" xmlns="http://www.w3.org/2000/svg" width="25.7" height="15.32" viewBox="0 0 25.7 15.32"><path d="M25.5,7.06C22.22,2.69,17.77,0,12.86,0S3.49,2.69.2,7.05a1.05,1.05,0,0,0,0,1.22c3.29,4.37,7.74,7.05,12.66,7.05s9.36-2.68,12.64-7A1,1,0,0,0,25.5,7.06ZM18.25,8a5.4,5.4,0,1,1,0-.7A4.09,4.09,0,0,1,18.25,8Z" transform="translate(0 0)"/><path d="M12.67,4.76A2.91,2.91,0,1,0,13,10.57h0a2.92,2.92,0,0,0,2.72-2.72,2.28,2.28,0,0,0,0-.37A2.9,2.9,0,0,0,12.67,4.76Z" transform="translate(0 0)"/></svg>
        </div>

        <!-- Password validation -->
        <div id="passValBox" class="hide">
            <h5 class="upp_Mkr19">${langObj.upp_Mkr19}</h5>
            <ul>
                <li id="minChar" class="upp_Mkr20">${langObj.upp_Mkr20}</li>
                <li id="upCase" class="upp_Mkr21">${langObj.upp_Mkr21}</li>
                <li id="lowCase" class="upp_Mkr22">${langObj.upp_Mkr22}</li>
                <li id="num" class="upp_Mkr23">${langObj.upp_Mkr24}</li>
                <li id="special" class="upp_Mkr24">${langObj.upp_Mkr24}</li>
            </ul>
        </div>
    </div>

    <div>

        <div class="group">
            <input id="f_confirm_password" type="password" onblur="hidePassBox()"
                oninput="validatePasswordConfirmation('f_password', 'f_confirm_password')" autocomplete="new-password">
            <span class="highlight"></span>
            <span class="bar"></span>
            <label for="">${langObj.upp_Mkr7}</label>

            <svg id="eye2" class="viewPass" onclick="viewPass('f_confirm_password', this.id)" xmlns="http://www.w3.org/2000/svg" width="25.7" height="15.32" viewBox="0 0 25.7 15.32"><path d="M25.5,7.06C22.22,2.69,17.77,0,12.86,0S3.49,2.69.2,7.05a1.05,1.05,0,0,0,0,1.22c3.29,4.37,7.74,7.05,12.66,7.05s9.36-2.68,12.64-7A1,1,0,0,0,25.5,7.06ZM18.25,8a5.4,5.4,0,1,1,0-.7A4.09,4.09,0,0,1,18.25,8Z" transform="translate(0 0)"/><path d="M12.67,4.76A2.91,2.91,0,1,0,13,10.57h0a2.92,2.92,0,0,0,2.72-2.72,2.28,2.28,0,0,0,0-.37A2.9,2.9,0,0,0,12.67,4.76Z" transform="translate(0 0)"/></svg>

        </div>
        <!-- match password validation -->
        <div id="passMatchBox" class="hide">
            <h5 class="upp_Mkr25">${langObj.upp_Mkr25}</h5>
        </div>
    </div>`;
        const btn = divAtt('button', 'class', 'btn upp_Mkr17');
        btn.id = 'changePassBoxBtn';
        btn.innerHTML = langObj.upp_Mkr17;
        div.append(p);
        div.append(inputDiv);
        div.append(btn);
        btn.setAttribute("disabled", "true");

        // custardBox(div, `${langObj.upp_Mkr17}`);
        eCustardBox(div, `${langObj.upp_Mkr17}`, [
          [
            btn,
            () => {
              UPt_profile.changePassword();
            },
            'click',
          ],
        ]);
      })
      .catch(console.error());
  },
  changePassword: () => {
    if (id('f_password').value === id('f_confirm_password').value) {
      const vars = {};
      vars.currentPassword = id('f_password').value;

      PREP(['updCandiPassword'], vars)
        .then((res) => {
          note(res.data.updCandiPassword);
          hide('custardBox');
        })
        .catch(console.error());
    } else {
      const vars = {};
      vars.HTMLPageName = 'UPt_profile';
      vars.languageID = getCookie('mbohvbhfje');

      PREP(['getHTMLLexicon'], vars).then((res) => {
        const langObj = {};

        const data = res.data.getHTMLLexicon;
        for (let row of data) {
          langObj[row.marker] = row.display;
        }

        errBox(langObj.upp_Mkr25);
        hide('custardBox');
        UPt_profile.changePassBox();
      });
    }
  },
  viewLI: (url) => {
    const splitUrl = url.split('www');
    window.open(`https://www${splitUrl[1]}`, '_blank');
  },
  selectCountry() {
    const countryDD = id('f_resCountry');
    const opt = countryDD.options[countryDD.selectedIndex].text;

    if (opt.toLowerCase() === 'south africa' || countryDD.value === '-') {
      id('candidateIDnr').classList.add('notEmpty');
      id('candidateIDnr').classList.add('ativeInp');
      id('candidatePassportnr').classList.remove('notEmpty');
      id('candidatePassportnr').classList.remove('ativeInp');
      id('candidatePassportnr').value = '';
      hide('candidatePassportnrInput');
      unhide('candidateIDnrInput');
    } else {
      id('candidateIDnr').classList.remove('notEmpty');
      id('candidateIDnr').classList.remove('ativeInp');
      id('candidateIDnr').value = '';
      id('candidatePassportnr').classList.add('notEmpty');
      id('candidatePassportnr').classList.add('ativeInp');
      unhide('candidatePassportnrInput');
      hide('candidateIDnrInput');
    }
  },
  selectCountryDISC() {
    const countryDD = id('f_resCountry');
    const opt = countryDD.options[countryDD.selectedIndex].text;

    if (opt.toLowerCase() === 'south africa' || countryDD.value === '-') {
      UPt_profile.selectCountry();
      //DOB
      id('f_dob').classList.remove('notEmpty');
      id('f_dob').classList.remove('ativeInp');
      hide('dobDiv');
      //Gender
      id('f_gender').classList.remove('notEmpty');
      id('f_gender').classList.remove('ativeInp');
      hide('genderDiv');
    } else {
      UPt_profile.selectCountry();
      //DOB
      id('f_dob').classList.add('notEmpty');
      id('f_dob').classList.add('ativeInp');
      unhide('dobDiv');
      //Gender
      id('f_gender').classList.add('notEmpty');
      id('f_gender').classList.add('ativeInp');
      unhide('genderDiv');
    }
  },
  selectEthnicityDISC() {
    if (id('f_ethnicity').value === '5') {
      unhide('countryOriginDiv');
      id('countryOfOrigin').classList.add('notEmpty');
      id('countryOfOrigin').classList.add('ativeInp');
    } else {
      if (id('countryOriginDiv')) {
        hide('countryOriginDiv');
        id('countryOfOrigin').classList.remove('notEmpty');
        id('countryOfOrigin').classList.remove('ativeInp');
      }
    }
  },
  updateProfile: async () => {
    //Client Pacific Update ---
    if (getCookie('primaryClient') == 4) {
      return UPt_profile.updateDiscProfile();
    }

    //Generic Update ---
    if (!validatePage()) {
      return;
    }
    const image_file = id('image_file').files[0];
    const phoneNumber = await sanatizeNum(id('f_cellNr').value, id('countryCodes').value);

    const vars = {};
    vars.cellNumber = phoneNumber;
    vars.fname = id('f_firstName').value;
    vars.sname = id('f_surname').value;
    vars.emailAddress = id('f_email').value;
    if (image_file) {
      vars.imageFileName = image_file.name;
      vars.imageFile = image_file;
    }

    const activeBioInp = document.querySelectorAll('.ativeInp');
    activeBioInp.forEach((elem) => {
      vars[elem.name] = elem.value;
    });

    PREP(['updCandiProfile'], vars)
      .then((res) => {
        id('userName').innerHTML = id('f_firstName').value;
        note(res.data.updCandiProfile);
        changeTabN('UPt_profile');
      })
      .catch(console.error());
  },
  updateDiscProfile: async () => {
    if (!validatePage()) {
      return;
    }

    // Update Bio Section ---
    const vars1 = {};

    // Forgien National
    vars1.ethnicityID = id('f_ethnicity').value;
    if (id('f_ethnicity').value == 5) {
      vars1.countryOfOrigin = id('countryOfOrigin').value;
    }

    //  Country of Res = south africa
    vars1.countryOfResidenceID = id('f_resCountry').value;
    if (vars1.countryOfResidenceID == 1) {
      vars1.idNumber = id('candidateIDnr').value;
    } else {
      vars1.passport = id('candidatePassportnr').value;
      vars1.DOB = id('f_dob').value;
      vars1.genderID = id('f_gender').value;
    }

    PREP(['updMyProfile3'], vars1)
      .then((res) => {
        console.log('updMyProfile3', res.data);
      })
      .catch(console.error());

    // Update Generic Section ---
    const image_file = id('image_file').files[0];
    const phoneNumber = await sanatizeNum(id('f_cellNr').value, id('countryCodes').value);

    const vars2 = {};
    vars2.cellNumber = phoneNumber;
    vars2.fname = id('f_firstName').value;
    vars2.sname = id('f_surname').value;
    vars2.emailAddress = id('f_email').value;
    if (image_file) {
      vars2.imageFileName = image_file.name;
      vars2.imageFile = image_file;
    }

    PREP(['updCandiProfile'], vars2)
      .then((res) => {
        id('userName').innerHTML = id('f_firstName').value;
        note(res.data.updCandiProfile);
        changeTabN('UPt_profile');
      })
      .catch(console.error());
  },
};

export const UPt_myCV = {
  displayFile: () => {
    const vars = {};
    vars.HTMLPageName = 'UPt_myCV';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getHTMLLexicon'], vars).then((res) => {
      const langObj = {};
      const data = res.data.getHTMLLexicon;
      for (let row of data) {
        langObj[row.marker] = row.display;
      }

      const resultFileInput = id('fileCV');
      const maxSize = 500000;
      const selectedFile = resultFileInput.files[0];

      if (selectedFile.type != 'application/pdf' && selectedFile.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const formatMsg = langObj.upc_Mkr14 ? langObj.upc_Mkr14 : 'Please select a valid file format to upload';
        infoBox(formatMsg);
        resultFileInput.value = '';
      } else if (selectedFile.size > maxSize) {
        const sizeMsg = langObj.upc_Mkr12 ? langObj.upc_Mkr12 : 'Please select a file no bigger that 500KB';
        infoBox(sizeMsg);
        resultFileInput.value = '';
      } else {
        id('resultFileDisplay').innerHTML = ` ${selectedFile.name}`;
        hide('uploadBtnContainer');
        hide('linkedInP');
        unhide('CVimgUploadTips');
      }
    });
  },
  removeSelectedFile: () => {
    id('fileCV').value = '';
    unhide('uploadBtnContainer');
    unhide('linkedInP');
    hide('CVimgUploadTips');
  },
  uploadCV: () => {
    const file = id('fileCV').files[0];
    const infoText = window.infoText;

    if (file === undefined) {
      const msg = infoText.inf_Mkr21 ? infoText.inf_Mkr21 : 'Please select a file to upload';
      infoBox(msg);
    } else {
      const vars = {};
      vars.participantID = id('detailsUserID').value;
      vars.comments = id('reportComments').value;
      vars.reportFile = file;
      vars.reportTypeID = id('reportTypeIDCV').value;
      vars.HapplicationID = 0;
      PREP(['uploadReport'], vars)
        .then((res) => {
          note(res.data.uploadReport);
          changeTabN('UPt_myCV');
        })
        .catch(console.error());
    }
  },
  uploadLI: () => {
    const vars = {};
    vars.HTMLPageName = 'UPt_myCV';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getHTMLLexicon'], vars).then((res) => {
      const langObj = {};
      const data = res.data.getHTMLLexicon;
      for (let row of data) {
        langObj[row.marker] = row.display;
      }

      if (id('linkedInLink').value == '') {
        const msg = langObj.upc_Mkr17 ? langObj.upc_Mkr17 : 'Please enter a URL link';
        infoBox(msg);
      } else {
        const vars = {};
        vars.linkedInURL = id('linkedInLink').value;
        vars.detailsUserID = id('detailsUserID').value;
        PREP(['updateLinkedInURL'], vars)
          .then((res) => {
            note(res.data.updateLinkedInURL);
            changeTabN('UPt_myCV');
          })
          .catch(console.error());
      }
    });
  },
};

export const UPt_myMessages = {
  buildMsgPage: async (data, langArr) => {
    const lang = await createLangObj(langArr);

    const messages = data[0].ReturnList;
    const msgList = id('listMsgs');
    msgList.innerHTML = '';

    const inboxDiv = divAtt('div', 'id', 'inboxDiv');
    const sentDiv = divAtt('div', 'class', 'hide');
    sentDiv.setAttribute('id', 'sentDiv');

    let dynamicElems = [];
    for (let row of messages) {
      const msg = divAtt('div', 'class', 'msg');
      msg.setAttribute('id', `${row.commslogID}`);
      const date = divAtt('p', 'class', 'msgDate');
      date.innerHTML = dateShortFromDateTime(`${row.Spooled}`);
      const subject = divAtt('p', 'class', 'msgSubject');
      let id1 = `${row.commslogID}`;
      dynamicElems.push([
        id1,
        () => {
          UPt_myMessages.viewMsg(id1);
        },
      ]);
      //msg.setAttribute("onclick",UPt_myMessages.viewMsg(this.id));
      if (row.emailSubject) {
        subject.innerHTML = row.emailSubject;
      } else {
        subject.innerHTML = lang.upm_Mkr2;
      }

      const msgPreview = divAtt('p', 'class', 'msgPreview');
      msgPreview.innerHTML = row.shortDescription;
      msg.appendChild(date);
      msg.appendChild(subject);
      msg.appendChild(msgPreview);

      if (row.receiverName === 'This user') {
        inboxDiv.appendChild(msg);
      } else {
        sentDiv.appendChild(msg);
      }
    }

    if (inboxDiv.innerHTML === '') {
      inboxDiv.innerHTML = lang.upm_Mkr8 ? lang.upm_Mkr8 : 'No Messages';
      inboxDiv.style = `margin-top: 2rem;
      color: var(--primaryColor);`;
    }

    if (sentDiv.innerHTML === '') {
      sentDiv.innerHTML = lang.upm_Mkr8 ? lang.upm_Mkr8 : 'No Messages';
      sentDiv.style = `margin-top: 2rem;
      color: var(--primaryColor);`;
    }

    msgList.appendChild(inboxDiv);
    msgList.appendChild(sentDiv);

    //Event Listeners
    // array.forEach(element => {
    //   eListen(element[0],element[1],"click");
    // });
    for (let i = 0; i < dynamicElems.length; i++) {
      eListen(dynamicElems[i][0], dynamicElems[i][1], 'click');
    }
  },
  inbox: (block) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
    });
    id(block).classList.add('blockActive');

    unhide('inboxDiv');
    hide('sentDiv');
  },
  sent: (block) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
    });
    id(block).classList.add('blockActive');

    hide('inboxDiv');
    unhide('sentDiv');
  },
  viewMsg: (msgId) => {
    // msg active
    const msgs = document.querySelectorAll('.msg');
    msgs.forEach((block) => {
      block.classList.remove('msgActive');
    });
    id(msgId).classList.add('msgActive');

    const vars = {};
    vars.commslogID = msgId;
    vars.HTMLPageName = 'UPt_myMessages';
    vars.languageID = getCookie('mbohvbhfje');
    PREP(['getMessageDetail', 'getHTMLLexicon'], vars)
      .then((res) => {
        const langObj = {};
        const data = res.data.getHTMLLexicon;
        for (let row of data) {
          langObj[row.marker] = row.display;
        }

        const detail = res.data.getMessageDetail[0];
        const msgDetail = id('msgDetail');
        msgDetail.innerHTML = '';

        const backDiv = divAtt('div', 'id', 'msgBack');
        backDiv.id = 'backDivBtn';
        backDiv.innerHTML = `<svg class="backChev" xmlns="http://www.w3.org/2000/svg" width="22.79" height="14.4" viewBox="0 0 22.79 14.4">
       <path d="M11.39,14.4,0,3.3,3.39,0l8,7.79,8-7.79,3.4,3.3Z"></path>
       </svg> Back`;

        const date = divAtt('p', 'id', 'msgDDate');
        date.innerHTML = dateFromDateTime(`${detail.spooled}`);
        const subject = divAtt('h3', 'id', 'msgDSubject');
        subject.innerHTML = detail.emailSubject;
        const sender = divAtt('p', 'id', 'msgDWho');
        if (detail.direction) {
          sender.innerHTML = `${langObj.upm_Mkr7} <span>${detail.senderName}</span>`;
        } else {
          sender.innerHTML = `${langObj.upm_Mkr6} <span>${detail.senderName}</span>`;
        }

        const hr = divAtt('hr', 'style', 'margin-top: 30px;');
        const msg = divAtt('p', 'id', 'text');
        msg.innerHTML = detail.emailBody;

        msgDetail.appendChild(backDiv);
        msgDetail.appendChild(date);
        msgDetail.appendChild(subject);
        msgDetail.appendChild(sender);
        eListen(
          'backDivBtn',
          () => {
            UPt_myMessages.back();
          },
          'click'
        );
        if (detail.messageType === 'Email') {
          const resendBtn = divAtt('button', 'class', 'btn');
          resendBtn.style = `margin-top: 1rem;`;
          resendBtn.id = 'resendBtn';
          resendBtn.innerHTML = langObj.upm_Mkr9 ? langObj.upm_Mkr9 : 'Resend';
          msgDetail.appendChild(resendBtn);
          eListen(
            'resendBtn',
            () => {
              UPt_myMessages.resend(detail.commslogID, detail.recipientEmailAddress);
            },
            'click'
          );
        }

        msgDetail.appendChild(hr);
        msgDetail.appendChild(msg);

        // media size
        const mediaSize = window.matchMedia('(max-width: 900px)');

        if (mediaSize.matches) {
          console.log('true');
          id('msgs').style = 'display:none ;';
          id('msgDetail').style = 'display: block ;';
        } else {
          id('msgs').style = 'display: block ;';
          id('msgDetail').style = 'display: block;';
        }
      })
      .catch(console.error());
  },
  resend: (commsLogID, email) => {
    const vars = {};
    vars.commsLogID = commsLogID;
    vars.targetEmailAddress = email;
    PREP(['resendEmail'], vars)
      .then((res) => {
        note(res.data.resendEmail);
      })
      .catch(console.error());
  },
  back: () => {
    const mediaSize = window.matchMedia('(max-width: 900px)');
    console.log('mediaSize', mediaSize);

    if (mediaSize.matches) {
      id('msgs').style = 'display: block !important;';
      id('msgDetail').style = 'display: none !important;';

      const msgs = document.querySelectorAll('.msg');
      msgs.forEach((block) => {
        block.classList.remove('msgActive');
      });
    } else {
      id('msgs').style = 'display: block ;';
      id('msgDetail').style = 'display: block;';
      //@steph plse fix this one day
    }
  },
};

export const MAt_guide = {
  openVid: () => {
    unhide('myForm');
    id('video1').play();
  },
  closeVid: () => {
    id('video1').pause();
    hide('myForm');
  },
};

export const MAt_consent = {
  consentPage: async (data, langArr) => {
    const langObj = await createLangObj(langArr);

    id('h1').innerHTML = langObj.mac_Mkr1;

    if (data.length === 0) {
      id('instructions').innerHTML = langObj.mac_Mkr3;
      hide('consentList');
      hide('consent');
    } else if (data[0].Status === 'Required') {
      // id('instructions').innerHTML = langObj.mac_Mkr4;
      hide('instructions');
      id('consentName').innerHTML = data[0].shortdescription;
      id('consentText').innerHTML = data[0].DisplayText;
      id('HconsentID').value = data[0].HconsentID;

      if (data[0].AuthType === 'OTP') {
        id('otp').innerHTML = data[0].OTP;
        id('OTPinstruction').innerHTML = langObj.mac_Mkr13 ? langObj.mac_Mkr13 : 'Please type in the following one time pin to give consent:';
        id('OTPsubmit').innerHTML = langObj.mac_Mkr14 ? langObj.mac_Mkr14 : 'Submit';
        unhide('concentCheckContainer');
        hide('consentTickContainer');
      } else {
        id('tickSubmit').innerHTML = langObj.mac_Mkr14 ? langObj.mac_Mkr14 : 'Submit';
        id('tickCopy').innerHTML = langObj.mac_Mkr18 ? langObj.mac_Mkr18 : 'I agree.';
        id('otpRepeat').value = data[0].OTP;
        hide('concentCheckContainer');
        unhide('consentTickContainer');
      }

      hide('consentList');
      unhide('consent');
    } else {
      id('instructions').innerHTML = langObj.mac_Mkr2;

      hide('consent');

      const consentListDiv = id('consentList');
      consentListDiv.innerHTML = '';
      let cntr = 1;
      for (let form of data) {
        const formContainer = divAtt('div', 'class', 'formContainer');

        const consentName = divAtt('div', 'class', 'consentName');
        consentName.setAttribute('id', `border_${cntr}`);
        consentName.innerHTML = `<svg id="arrowDD_${cntr}" class="arrowDD" xmlns="http://www.w3.org/2000/svg" width="9.05" height="14.62"
            viewBox="0 0 9.05 14.62">
            <path
                d="M11.58,6.49,6,.8a1.62,1.62,0,0,0-.8-.57l-.06,0a1,1,0,0,0-.59,0,2.56,2.56,0,0,0-1.25,1A1.29,1.29,0,0,0,3.42,3c.93.94,1.86,1.86,2.79,2.78L8,7.47,3.42,12l-.14.15a1.34,1.34,0,0,0,0,1.67,3.59,3.59,0,0,0,.47.48l.11.11a1.31,1.31,0,0,0,.89.34,1.26,1.26,0,0,0,1-.42l5.9-5.9A1.41,1.41,0,0,0,12,7.9,1.33,1.33,0,0,0,11.58,6.49Z"
                transform="translate(-2.98 -0.17)" /></svg>`;
        // consentName.setAttribute(
        //   "onclick",
        //   `MAt_consent.expandForm('arrowDD_${cntr}', 'form_${cntr}', 'border_${cntr}')`
        // );
        const h5 = divAtt('h5', 'class', '');
        h5.innerHTML = form.shortdescription;
        consentName.appendChild(h5);

        const textContainer = divAtt('div', 'class', 'textContainer hide');
        textContainer.setAttribute('id', `form_${cntr}`);
        const text = divAtt('p', 'class', '');
        text.innerHTML = form.DisplayText;
        textContainer.appendChild(text);

        formContainer.appendChild(consentName);
        formContainer.appendChild(textContainer);
        consentListDiv.appendChild(formContainer);
        let arrowId = `arrowDD_${cntr}`;
        let formId = `form_${cntr}`;
        let borderId = `border_${cntr}`;
        eListen(
          `border_${cntr}`,
          () => {
            MAt_consent.expandForm(arrowId, formId, borderId);
          },
          'click'
        );
        cntr++;
      }

      unhide('consentList');
    }
  },
  expandForm: (arrowID, formID, border) => {
    id(`${formID}`).classList.toggle('hide');
    id(`${arrowID}`).classList.toggle('arrowDown');
    id(`${border}`).classList.toggle('borderR');
  },
  giveConsentOTP: () => {
    const otp = id('otp').innerHTML;
    const repeat = id('otpRepeat').value.trim();

    if (otp === repeat) {
      const vars = {};

      vars.OTP = repeat;
      vars.HconsentID = id('HconsentID').value;

      PREP(['recordConsent'], vars)
        .then((res) => {
          id('otpRepeat').value = '';
          note(res.data.recordConsent[0].textResponse);

          if (res.data.recordConsent[0].outstandingConsent) {
            changeTabN('MAt_consent');
          } else {
            changeTabN('MAt_current');
          }
        })
        .catch(console.error());
    } else {
      const vars = {};
      vars.HTMLPageName = 'MAt_consent';
      vars.languageID = getCookie('mbohvbhfje');
      PREP(['getHTMLLexicon'], vars)
        .then((res) => {
          vars.HTMLPageName = 'assTable';
          vars.languageID = getCookie('mbohvbhfje');

          const langObj = createLangObj(res.data.getHTMLLexicon);

          if (langObj.mac_Mkr15) {
            infoBox(langObj.mac_Mkr15);
          } else {
            infoBox('Your one time pin does not match, please retype carefully.');
          }
        })
        .catch(console.error());
    }
  },
  giveConsentTick: () => {
    const otp = id('otpRepeat').value.trim();

    if (id('tickConsent').checked) {
      const vars = {};
      vars.OTP = otp;
      vars.HconsentID = id('HconsentID').value;

      PREP(['recordConsent'], vars)
        .then((res) => {
          id('otpRepeat').value = '';
          note(res.data.recordConsent[0].textResponse);

          if (res.data.recordConsent[0].outstandingConsent) {
            changeTabN('MAt_consent');
          } else {
            changeTabN('MAt_current');
          }
        })
        .catch(console.error());
    } else {
      const vars = {};
      vars.HTMLPageName = 'MAt_consent';
      vars.languageID = getCookie('mbohvbhfje');
      PREP(['getHTMLLexicon'], vars)
        .then((res) => {
          vars.HTMLPageName = 'assTable';
          vars.languageID = getCookie('mbohvbhfje');

          const langObj = createLangObj(res.data.getHTMLLexicon);

          if (langObj.mac_Mkr17) {
            infoBox(langObj.mac_Mkr17);
          } else {
            infoBox('Please tick the consent checkbox to submit.');
          }
        })
        .catch(console.error());
    }
  },
};

export const MAt_assessments = {
  currPage: (block) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
    });
    id(block).classList.add('blockActive');

    const vars = {};
    vars.HTMLPageName = 'assTable';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getAllocatedAssessments', 'getHTMLLexicon'], vars)
      .then((res) => {
        const data = cObj(res.data.getAllocatedAssessments);
        let newArr = [];

        for (let row of data) {
          if (!row.ARCHIVEDYN && !row.COMPLETEDYN) {
            //row.ASSESSMENTSTATUS !== 'Completed'
            newArr.push(row);
          }
        }
        MAt_assessments.buildAssTable(newArr, res.data.getHTMLLexicon);
      })
      .catch(console.error());
  },
  compPage: (block) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
    });
    id(block).classList.add('blockActive');

    const vars = {};
    vars.HTMLPageName = 'assTable';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getAllocatedAssessments', 'getHTMLLexicon'], vars)
      .then((res) => {
        const data = cObj(res.data.getAllocatedAssessments);
        let newArr = [];
        for (let row of data) {
          if (row.COMPLETEDYN) {
            //row.ASSESSMENTSTATUS === 'Completed'
            newArr.push(row);
          }
        }
        MAt_assessments.buildAssTable(newArr, res.data.getHTMLLexicon);
      })
      .catch(console.error());
  },
  archPage: (block) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
    });
    id(block).classList.add('blockActive');

    const vars = {};
    vars.HTMLPageName = 'assTable';
    vars.languageID = getCookie('mbohvbhfje');

    PREP(['getAllocatedAssessments', 'getHTMLLexicon'], vars)
      .then((res) => {
        const data = cObj(res.data.getAllocatedAssessments);
        let newArr = [];
        for (let row of data) {
          if (row.ARCHIVEDYN) {
            newArr.push(row);
          }
        }
        MAt_assessments.buildAssTable(newArr, res.data.getHTMLLexicon);
      })
      .catch(console.error());
  },
  buildAssTable: (data, langArr) => {
    const langObj = {};

    for (let row of langArr) {
      langObj[row.marker] = row.display;
    }

    if (data.length) {
      id('noAss') && hide('noAss');
      // Build Head
      const table = id('assTable');
      table.innerHTML = '';
      const thead = document.createElement('thead');

      const thDate = divAtt('th', 'class', 'maa_Mkr4');
      const thAss = divAtt('th', 'class', 'maa_Mkr5');
      const thStat = divAtt('th', 'class', 'maa_Mkr6');
      const thRatee = divAtt('th', 'class', 'maa_Mkr7');
      // const thProf = document.createElement('th');
      // const thRCnt = divAtt("th", "class", "rtrCntColumn  maa_Mkr8");
      const thRater = divAtt('th', 'class', 'maa_Mkr9');
      // const thSelR = divAtt("th", "class", "maa_Mkr10");
      const thTakeA = divAtt('th', 'class', 'maa_Mkr11');
      const thArch = divAtt('th', 'class', 'maa_Mkr12');

      thDate.innerHTML = langObj.ath_Mkr1;
      thAss.innerHTML = langObj.ath_Mkr2;
      thStat.innerHTML = langObj.ath_Mkr3;
      thRatee.innerHTML = langObj.ath_Mkr4;
      // thProf.innerHTML = 'Profile Image';
      // thRCnt.innerHTML = langObj.ath_Mkr5;
      thRater.innerHTML = langObj.ath_Mkr6; // Remove
      // thSelR.innerHTML = langObj.ath_Mkr7; // Remove
      thTakeA.innerHTML = langObj.ath_Mkr8;
      thArch.innerHTML = langObj.ath_Mkr9;

      thead.appendChild(thDate);
      thead.appendChild(thAss);
      thead.appendChild(thStat);
      thead.appendChild(thRatee);
      // thead.appendChild(thProf);
      // thead.appendChild(thRCnt);
      thead.appendChild(thRater);
      // thead.appendChild(thSelR);
      thead.appendChild(thTakeA);
      // thead.appendChild(thArch); Turned off 'Archive' functionality
      table.appendChild(thead);

      let displayRaterCount = false;
      let bioRequired = false;
      let bioProfilePage;
      let bioMsg;

      let guideTabRequired = false;
      let guideMsg;
      // Build table content

      // console.log('data', data);
      for (let row of data) {
        if (row.BIOREQUIREMENTSDESCRIPTION) {
          bioRequired = true;
          bioProfilePage = row.PROFILENAVPAGE;
          bioMsg = row.BIOREQUIREMENTSDESCRIPTION;
        }
        if (row.VIDEOOUTSTANDING) {
          guideTabRequired = true;
          guideMsg = row.VIDEOOUTSTANDING;
        }
        const ALLOCHUSERHASSESSMENTID = 'allocHuserHassessmentID=' + row.ALLOCHUSERHASSESSMENTID;
        let dynamicEvents = [];
        const tr = document.createElement('tr');
        table.appendChild(tr);
        const tdDate = document.createElement('td');
        const tdAss = document.createElement('td');
        const tdStat = document.createElement('td');
        const tdRatee = document.createElement('td');
        const tdRater = document.createElement('td');
        const tdTakeA = document.createElement('td');
        const tdArch = document.createElement('td');
        tdDate.innerHTML = dateFromDateTime(row.DATEON);
        tdAss.innerHTML = row.ASSESSMENT;
        tdStat.innerHTML = row.ASSESSMENTSTATUS;
        tdRatee.innerHTML = row.COMBONAME;
        if (row.ADDRATERSYN) {
          const btn = divAtt('a', 'href', '#');
          btn.setAttribute('class', 'btn');
          btn.setAttribute('onclick', `PREP("userNominations", "${ALLOCHUSERHASSESSMENTID}")`);
          if (row.NOMINATIONSAPPROVED || row.ASSESSMENTSTATUS === 'Nominations approved') {
            btn.innerHTML = langObj.ath_Mkr13 ? langObj.ath_Mkr13 : 'View';
            btn.classList.add('maa_Mkr16');
          } else {
            btn.innerHTML = langObj.ath_Mkr11 ? langObj.ath_Mkr11 : 'Nominate';
            btn.classList.add('maa_Mkr13');
          }
          tdRater.appendChild(btn);
        }
        const btn2 = divAtt('a', 'class', 'btn');
        btn2.setAttribute('id', `tblBtn2_${row.ALLOCHUSERHASSESSMENTID}`);
        if (row.CANSTARTASSESSMENT) {
          const consentYN = row.STILLREQUIRESCONSENTRATER || row.STILLREQUIRESCONSENTRATEE ? 1 : 0;
          btn2.setAttribute('href', '#');

          if (row.ALLOCATIONACTIVEYN && consentYN) {
            dynamicEvents.push([
              `tblBtn2_${row.ALLOCHUSERHASSESSMENTID}`,
              () => {
                MAt_assessments.consentNeeded(row.HCLIENTID, langObj.ath_Mkr23);
              },
              'click',
            ]);
          } else if (row.ALLOCATIONACTIVEYN && !consentYN && row.BIOREQUIREMENTSMET === false) {
            dynamicEvents.push([
              `tblBtn2_${row.ALLOCHUSERHASSESSMENTID}`,
              () => {
                MAt_assessments.updateBiographicalPrompt(row.BIOREQUIREMENTSDESCRIPTION);
              },
              'click',
            ]);
          } else if (row.ALLOCATIONACTIVEYN && row.EXTERNALLINK && !consentYN) {
            dynamicEvents.push([
              `tblBtn2_${row.ALLOCHUSERHASSESSMENTID}`,
              () => {
                MAt_assessments.startExternal(row.EXTERNALLINK, row.EXTERNALLINKMESSAGE, row.PREVIOUSLINKOUT, row.TESTOVERRIDEYN, row.ALLOCHUSERHASSESSMENTID);
              },
              'click',
            ]);
          } else if (row.ALLOCATIONACTIVEYN && !row.EXTERNALLINK) {
            btn2.setAttribute('onclick', `PREP("takeAssessment", "${ALLOCHUSERHASSESSMENTID}")`);
            // btn2.setAttribute("onclick",`PREP("userNominations", "${ALLOCHUSERHASSESSMENTID}"),
            // PREP("takeAssessment", "${ALLOCHUSERHASSESSMENTID}")`);
          } else {
            btn2.setAttribute('class', 'btn btn_inactive');
            dynamicEvents.push([
              `tblBtn2_${row.ALLOCHUSERHASSESSMENTID}`,
              () => {
                infoBox('This Assessment is not currently Active.');
              },
              'click',
            ]);
          }
          if (row.ASSESSMENTSTATUS === langObj.ath_Mkr15) {
            btn2.innerHTML = langObj.ath_Mkr20 ? langObj.ath_Mkr20 : 'Continue';
          } else {
            btn2.innerHTML = langObj.ath_Mkr10 ? langObj.ath_Mkr10 : 'Start';
          }
          tdTakeA.appendChild(btn2);
        }
        if (row.COMPLETEDYN === 1 && row.REPORTFILE) {
          btn2.setAttribute('href', row.REPORTFILE);
          btn2.setAttribute('target', '_blank');
          btn2.innerHTML = alangObj.ath_Mkr13 ? alangObj.ath_Mkr13 : 'View';
          tdTakeA.appendChild(btn2);
        } else if (row.COMPLETEDYN && !row.REPORTFILE) {
          tdTakeA.innerHTML = ' ';
        }
        if (row.PROVISIONNOTICE) {
          tdTakeA.innerHTML = row.PROVISIONNOTICE;
        }
        const label = divAtt('label', 'class', 'checkContainer');
        label.setAttribute('for', row.ALLOCHUSERHASSESSMENTID);
        label.style = `padding-left: 0px; margin-bottom: 0px;`;
        const span = divAtt('span', 'class', 'checkSVG');
        if (row.ARCHIVEDYN === true) {
          span.innerHTML = `<svg width="13.08" height="14.88" viewBox="0 0 13.08 14.88"><path d="M8,6.45a.64.64,0,0,0-1,0L4.71,8.73a1.49,1.49,0,0,0-.12.14.6.6,0,0,0,.34,1,.62.62,0,0,0,.61-.2L6.89,8.27v3.35a.62.62,0,0,0,.27.55.58.58,0,0,0,.62,0,.6.6,0,0,0,.33-.56V8.26L9.47,9.63a.61.61,0,1,0,.86-.86Z" transform="translate(-0.96 -0.06)"/><path d="M14,3.35a.73.73,0,0,0,0-.25L12.84.37a.51.51,0,0,0-.47-.31H2.63a.51.51,0,0,0-.47.31L1,3.1a.73.73,0,0,0,0,.25V14.44a.5.5,0,0,0,.51.5H13.53a.5.5,0,0,0,.51-.5V3.35ZM3,1.07H12l.73,1.72H2.23ZM13,13.93H2V3.87H13Z" transform="translate(-0.96 -0.06)"/></svg>`;
        } else {
          span.innerHTML = `<svg width="13.08" height="14.88" viewBox="0 0 13.08 14.88"><path d="M7,12a.63.63,0,0,0,1,0l2.28-2.28a.74.74,0,0,0,.12-.13.6.6,0,0,0-.34-1,.62.62,0,0,0-.61.2c-.43.44-.87.88-1.35,1.35V6.86a.65.65,0,0,0-.27-.56.58.58,0,0,0-.62,0,.6.6,0,0,0-.33.57v3.38L5.53,8.84a.61.61,0,0,0-.61-.19.6.6,0,0,0-.25,1Z" transform="translate(-0.96 -0.06)"/><path d="M14,3.35a.73.73,0,0,0,0-.25L12.84.37a.51.51,0,0,0-.47-.31H2.63a.51.51,0,0,0-.47.31L1,3.1a.73.73,0,0,0,0,.25V14.44a.5.5,0,0,0,.51.5H13.53a.5.5,0,0,0,.51-.5V3.35ZM3,1.07H12l.73,1.72H2.23ZM13,13.93H2V3.87H13Z" transform="translate(-0.96 -0.06)"/></svg>`;
        }
        const check = divAtt('input', 'type', 'checkbox');
        check.setAttribute('id', row.ALLOCHUSERHASSESSMENTID);
        //NOT USED LOL
        //dynamicEvents.push([`${row.ALLOCHUSERHASSESSMENTID}`,()=>{MAt_assessments.toggleArchive(`${row.ALLOCHUSERHASSESSMENTID}`)},"click"]);
        check.setAttribute('class', 'archiveTableField');
        check.checked = row.ARCHIVEDYN ? true : false;
        label.appendChild(check);
        label.appendChild(span);
        tdArch.appendChild(label);
        tr.appendChild(tdDate);
        tr.appendChild(tdAss);
        tr.appendChild(tdStat);
        tr.appendChild(tdRatee);
        tr.appendChild(tdRater);
        tr.appendChild(tdTakeA);
        for (let i = 0; i < dynamicEvents.length; i++) {
          eListen(dynamicEvents[i][0], dynamicEvents[i][1], dynamicEvents[i][2]); //tested
        }
      }
      if (bioRequired) {
        MAt_assessments.buildBioPopUp(bioProfilePage, bioMsg, langArr);
      } else if (guideTabRequired) {
        MAt_assessments.buildGuidePopUp(guideMsg, langArr);
      }
      if (displayRaterCount) {
        const columns = document.querySelectorAll('.rtrCntColumn');
        columns.forEach((row) => {
          if (row.classList.contains('hide')) {
            row.classList.remove('hide');
          }
        });
      }
    } else {
      id('assTable').innerHTML = '';
      unhide('noAss');
    }
  },
  buildBioPopUp(bioProfilePage, bioMsg, langArr) {
    const langObj = {};

    for (let row of langArr) {
      langObj[row.marker] = row.display;
    }

    const div = divAtt('div', 'class', '');
    const p = divAtt('p', 'class', 'marginTop marginBottom');
    p.innerHTML = bioMsg;

    const btn = divAtt('button', 'class', 'btn');
    btn.id = 'gotToProfile';
    btn.innerHTML = langObj.ath_Mkr19 ? langObj.ath_Mkr19 : 'Go to my profile';

    div.appendChild(p);
    div.appendChild(btn);
    // custardBox(div, `${langObj.ath_Mkr17}`);
    eCustardBox(div, `${langObj.ath_Mkr17}`, [
      'gotToProfile',
      () => {
        MAt_assessments.bioProfilePage(bioProfilePage);
      },
      'click',
    ]);

    // })
  },
  bioProfilePage(bioProfilePage) {
    if (bioProfilePage === 'userProfile') {
      PREP(`${bioProfilePage}`, 'dTab=UPt_profile');
    } else {
      PREP(`${bioProfilePage}`);
    }
    hide('custardBox');
  },
  buildGuidePopUp(guideMsg, langArr) {
    const langObj = {};

    for (let row of langArr) {
      langObj[row.marker] = row.display;
    }

    const div = divAtt('div', 'class', '');
    const p = divAtt('p', 'class', 'marginTop marginBottom');
    p.innerHTML = guideMsg;
    const btn = divAtt('button', 'class', 'btn');
    btn.innerHTML = langObj.ath_Mkr22 ? langObj.ath_Mkr22 : 'Okay';
    div.appendChild(p);
    div.appendChild(btn);
    // custardBox(div, `${langObj.ath_Mkr21}`);
    eCustardBox(div, `${langObj.ath_Mkr21}`, [
      [
        btn,
        () => {
          PREP('eFeedback'), hide('custardBox');
        },
        'click',
      ],
    ]);

    // })
  },
  toggleArchive: (ahh) => {
    const check = id(ahh).checked;
    const vars = {};
    vars.AllocHuserHassessmentID = ahh;
    vars.archiveYN = check ? 1 : 0;

    getDataS('currentPage', 1).then((page) => {
      PREP(['toggleAllocHuserHassessmentArchived'], vars)
        .then((res) => {
          changeTabN(page.page);
          note(res.data.toggleAllocHuserHassessmentArchived);
        })
        .catch(console.error());
    });
  },
  startExternal: function (link, message, previous, testOverride, allocHuserHassessmentID) {
    const div = divAtt('div', 'class', '');
    const p = divAtt('p', 'class', 'sectionGap');
    p.innerHTML = `${message}`;
    const btn = divAtt('btn', 'class', 'btn');
    // btn.setAttribute(
    //   "onclick",
    //   `MAt_assessments.openExternal('${link}', '${allocHuserHassessmentID}')`
    // );
    btn.innerHTML = 'Proceed';
    div.append(p);
    div.append(btn);
    // custardBox(div, `Start Assessment`);
    eCustardBox(div, `Start Assessment`, [
      [
        btn,
        () => {
          MAt_assessments.openExternal(`${link}`, `${allocHuserHassessmentID}`);
        },
        'click',
      ],
    ]);

    // var buttons = document.getElementById('btn_container');
    // var proceed = divAtt('div', 'class', 'btn');
    // proceed = addAtt(proceed, 'onclick', 'myAssessments.openExternal("' + link + '", "' + allocHuserHassessmentID + '")');
    // proceed = addAtt(proceed, 'id', 'openExternalBtn');
    // proceed.innerHTML = 'Proceed';
    // buttons.appendChild(proceed)
    // if (previous !== 'null') {
    //   var override = divAtt('div', 'class', 'btn');
    //   override = addAtt(override, 'onclick', 'myassessments.override( "'+allocHuserHassessmentID+'")');
    //   override = addAtt(override, 'id', 'overrideBtn');
    //   override.innerHTML = 'Override';
    //   buttons.appendChild(override)
    // }
    // custardBox()
    // showError(message, 'Please Note')
  },
  openExternal: (link, allocHuserHassessmentID) => {
    hide('custardBox');

    const vars = {};
    vars.allocHuserHassessmentID = allocHuserHassessmentID;
    vars.externalLink = link;
    PREP(['applicantLinkOut'], vars)
      .then(() => {
        // MAt_assessments.removeExtraBtns();
        window.open(link);
        // switchBox("myassessments");
      })
      .catch(console.error());
  },
  override: (allocHuserHassessmentID) => {
    PREP(['overrideExternalAssessment'], {
      allocHuserHassessmentID: allocHuserHassessmentID,
    })
      .then(() => {
        myassessments.removeExtraBtns();
        switchBox('myassessments');
      })
      .catch(console.error());
  },
  removeExtraBtns: () => {
    if (id('openExternalBtn') !== null) {
      const proceed = id('openExternalBtn');
      proceed.parentNode.removeChild(proceed);
    }
    if (id('overrideBtn') !== null) {
      const override = id('overrideBtn');
      override.parentNode.removeChild(override);
    }
  },
  consentNeeded: (HClientID, msg) => {
    id('HclientID').value = HClientID;
    changeTabN('MAt_consent');

    if (msg) {
      infoBox(msg);
    } else {
      infoBox('Before you may continue you must first approve the consent form.');
    }
  },
  updateBiographicalPrompt: (Message) => {
    PREP('userProfile', 'dTab=UPt_profile');
    infoBox(Message);
  },
};

export const takeAssessment = {
  userPreview: (data, langTrans) => {
    const langObj = {};
    for (let row of langTrans) {
      langObj[row.marker] = row.display;
    }

    id('profilePic').setAttribute('src', data[0].profileImage);
    id('assName').innerHTML = data[0].assessmentName;
    const person = `${data[0].rateeFname} ${data[0].rateeSname}`;
    const relationship = data[0].raterRelationship;

    const thePerson = langObj.tas_Mkr7 ? langObj.tas_Mkr7 : 'The person you are rating is';
    const withRel = langObj.tas_Mkr8 ? langObj.tas_Mkr8 : 'with the indicated relationship of';

    if (location.hostname !== 'aga.integratezone.com') {
      id('rel').innerHTML = `${thePerson} <span class="primaryColor">${person}</span> ${withRel} <span class="primaryColor">${relationship}</span>.`;
    }
  },
  getAssesment(assID, displayPage, EditHassessmentID, langLexicon) {
    const vars = {};
    if (EditHassessmentID) {
      vars.EditHassessmentID = EditHassessmentID;
      vars.allocHuserHassessmentID = 0;
    } else {
      vars.EditHassessmentID = 0;
      vars.allocHuserHassessmentID = assID;
    }
    vars.displayPage = displayPage;

    PREP(['getSurveyFullDev'], vars).then((res) => {
      const data = res.data.getSurveyFullDev[0];

      if (displayPage == 1000 || data.outstandingQuestions == 0) {
        return this.endPage(data, assID, langLexicon);
      }

      // INTRO ===
      if (data.headerText) {
        return this.buildIntro(data.headerText, assID);
      }

      // check if page is completed
      if (displayPage === '1' && res.data.getSurveyFullDev[1].completeYN == '1') {
        for (const row of res.data.getSurveyFullDev) {
          if (row.completeYN == '0') {
            return PREP('takeAssessment', `allocHuserHassessmentID=${assID}&displayPage=${row.pageNumber}`);
          }
        }
      }

      this.buildAss(data, assID, displayPage, langLexicon);
    });
  },
  buildAss(data, assID, displayPage, langLexicon) {
    // HEADERS ===
    const headers = {};
    if (data.pageHeader) {
      headers.pageHeader = data.pageHeader;
    }
    if (data.pageHeaderDescription) {
      headers.pageHeaderDescription = data.pageHeaderDescription;
    }
    if (data.measurementGuide) {
      headers.measurementGuide = data.measurementGuide;
    }
    this.buildHeaders(headers);

    // MEASURES HEADER  ======== FIX with example =====
    let showMeasureHeader = true;
    if (data.payLoad) {
      if (data.payLoad.length < 2) {
        // only one question
        const questionType = data.payLoad[0].measures[0][0].selectionType;
        if (questionType === 'Textarea') {
          // do not show Measurement Header & def
          showMeasureHeader = false;
        }
      }
    }
    // this.buildMeasuresHeader(survey, headers, currentPage, showMeasureHeader)

    // PROGRESS BAR ===
    this.progressBar(data.outstandingQuestions, data.totCompulsoryQuestions);

    // SURVEY ===
    this.buildSurvey(data.sectionArray, assID, displayPage, langLexicon);
  },
  buildIntro(intro, elID) {
    const infoText = window.infoText;

    if (intro) {
      id('surveyIntro').innerHTML = intro;

      const btnDiv = divAtt('div', 'class', 'sectionGap btnEndGrid marginTop');
      const btn = divAtt('button', 'class', 'btn');
      btn.innerHTML = infoText.inf_Mkr46 ? infoText.inf_Mkr46 : 'Start';
      btn.setAttribute('onclick', `PREP('takeAssessment','allocHuserHassessmentID=${elID.trim()}&displayPage=1')`);

      btnDiv.appendChild(btn);
      id('surveyIntro').appendChild(btnDiv);
    } else {
      id('surveyIntro').innerHTML = '';
    }
  },
  buildHeaders(headers) {
    if (!isEmpty(headers)) {
      if ('pageHeader' in headers) {
        const heading = divAtt('h4', 'class', '');
        heading.innerHTML = headers.pageHeader;
        id('surveyHeaders').appendChild(heading);
      }

      if ('pageHeaderDescription' in headers) {
        const desc = divAtt('h6', 'class', 'bold primaryColor');
        desc.innerHTML = headers.pageHeaderDescription;
        id('surveyHeaders').appendChild(desc);
      }

      if ('measurementGuide' in headers) {
        const measureDef = divAtt('p', 'class', '');
        // measureDef = addAtt(measureDef, 'id', 'survey_measureDef');
        measureDef.innerHTML = headers.measurementGuide;
        id('surveyHeaders').appendChild(measureDef);
      }
    }
  },
  // FIX -> Check measure Headers when you have example
  buildMeasuresHeader(survey, headers, currentPage, showMeasureHeader) {
    // headers for measures
    if (!isEmpty(headers) && currentPage != 0) {
      const sqs = divAtt('div', 'class', 'survey_q_set');
      sqs.setAttribute('id', 'measureHeaders');
      if (!showMeasureHeader) {
        sqs.setAttribute('style', 'display:none');
        measureDef.setAttribute('style', 'display:none');
      }
      var qPlace = divAtt('div', 'class', 'survey_q_set_section');
      sqs.appendChild(qPlace);

      // build headers for Measurements
      var qtyMeasurs = Object.keys(survey.questions[0].measures);
      for (var mn = 0; mn < qtyMeasurs.length; mn++) {
        var survey_q_set_section = divAtt('div', 'class', 'survey_q_set_section');
        var optionContainer = divAtt('div', 'class', 'optionContainer');
        var measureName = divAtt('div', 'class', 'measureName');
        measureName.setAttribute('id', 'measureName');
        measureName.innerHTML = survey.questions[0].measures[mn][0].measureName;
        optionContainer.appendChild(measureName);
        survey_q_set_section.appendChild(optionContainer);
        sqs.appendChild(survey_q_set_section);
      }

      id('surveyQs').appendChild(sqs);
    }
  },
  progressBar(outstanding, total) {
    // console.log(`${outstanding}/${total}`);
    const width = completedPercent(parseInt(outstanding), parseInt(total));
    id('progress').style.width = `${width}%`;
    unhide('progressBar');
  },
  buildSurvey(data, assID, pageNum, langLexicon) {
    const langObj = {};
    let dynEvents = [];
    for (let row of langLexicon) {
      langObj[row.marker] = row.display;
    }

    const surveyDiv = id('surveyQs');
    const assIDinput = divAtt('input', 'type', 'hidden');
    assIDinput.setAttribute('id', 'assID');
    assIDinput.setAttribute('value', `${assID}`);
    surveyDiv.appendChild(assIDinput);

    for (let row of data) {
      if (row) {
        const sectionDiv = divAtt('div', 'id', `section_${row.sectionNumber}`);
        sectionDiv.setAttribute('class', 'sectionGap');

        const prevSec = langObj.tas_Mkr10 ? langObj.tas_Mkr10 : 'Previous Section';
        const backDiv = divAtt('div', 'onclick', `hide('section_${row.sectionNumber}'), unhide('section_${row.sectionNumber - 1}')`);
        backDiv.setAttribute('class', 'back');
        backDiv.innerHTML = `<svg id="backChevron" class="backChevron" xmlns="http://www.w3.org/2000/svg" width="22.79" height="14.4"
      viewBox="0 0 22.79 14.4">
      <path d="M11.39,14.4,0,3.3,3.39,0l8,7.79,8-7.79,3.4,3.3Z"></path>
      </svg> ${prevSec}`;
        if (row.sectionNumber != 1) {
          sectionDiv.appendChild(backDiv);
        }

        const secHeading = document.createElement('h4');
        const sectionName = langObj.tas_Mkr1 ? langObj.tas_Mkr1 : 'Section';
        secHeading.innerHTML = `${sectionName}: ${row.sectionNumber}`;
        const secText = document.createElement('p');
        secText.innerHTML = row.sectionText;
        sectionDiv.appendChild(secHeading);
        sectionDiv.appendChild(secText);

        const Qtype = row.questions[0].measures[0][0];
        let dynamicEvents = [];
        // === Q style: doubleColumn SnakeBite
        if (Qtype.browserStyle === 'snakeBite') {
          //&& Qtype.measureName === 'devAreaStrengthArea'
          // console.log('snakeBite');

          const [Qdiv, dEvents] = this.doubleColumn(row, langLexicon);
          dynamicEvents = dEvents;
          dynEvents.push(...dEvents);
          sectionDiv.appendChild(Qdiv);
        } else {
          for (const questions of row.questions) {
            const Qdiv = divAtt('div', 'class', `question Qsec_${row.sectionNumber}`);
            Qdiv.setAttribute('id', `${questions.questionID}`);
            const question = document.createElement('h6');
            question.innerHTML = questions.assessmentQuestion;
            Qdiv.appendChild(question);

            const measures = questions.measures[0][0];
            // === Q style: RATING
            if (measures.browserStyle === 'iwprRadio') {
              // console.log('rating');
              const [rating, dynamicEvents] = this.rating(row, questions, measures);
              dynEvents.push(...dynamicEvents);
              Qdiv.appendChild(rating);
            }
            // === Q style: MULTIPLE 2 PART
            else if (questions.measures[1]) {
              // console.log('shortMulti 2 part');
              const [shortMulti, dynamicEvents] = this.multipleChoice2Part(row, questions, questions.measures);
              Qdiv.appendChild(shortMulti);
              dynEvents.push(...dynamicEvents);
            }
            // === Q style: SHORT MULTIPLE
            else if (measures.browserStyle === 'default' && measures.selectionType === 'radio') {
              // console.log('shortMulti');
              const [shortMulti, dynamicEvents] = this.shortMultipleChoice(row, questions, measures);
              Qdiv.appendChild(shortMulti);
              dynEvents.push(...dynamicEvents);
            }
            // === Q style: LONG MULTIPLE
            else if (measures.browserStyle === 'horizontal_6' || measures.browserStyle === 'fiveOptions' || measures.browserStyle === 'horizontal_4') {
              // console.log('longMulti');
              const [longMulti, dEvents] = this.longMultipleChoice(row, questions, measures); //mikeee
              dynamicEvents = dEvents;
              dynEvents.push(...dEvents);
              Qdiv.appendChild(longMulti);
            }
            // === Q style: TEXTAREA
            else if (measures.selectionType === 'Textarea') {
              // console.log('textarea')
              const [textarea, dynamicEvents] = this.textBox(row, questions, measures);
              dynEvents.push(...dynamicEvents);
              Qdiv.appendChild(textarea);
            }
            // === Q style: SLIDER
            if (measures.browserStyle === 'slidecontainer' || measures.selectionType === 'slider') {
              // console.log('slider');
              const [slider, dynamicEvents] = this.slider(row, questions, measures);
              Qdiv.appendChild(slider);
              dynEvents.push(...dynamicEvents);
            } // === Q style: DROPDOWN
            else if (measures.selectionType === 'dropDown') {
              // console.log('dropdown');
              let [dropDown, dynamicEvents] = this.dropDown(row, questions, measures);
              dynEvents.push(...dynamicEvents);
              Qdiv.appendChild(dropDown);
            }

            if (questions.requiredForComplete === 0) {
              Qdiv.classList.add('completed');
            }

            sectionDiv.appendChild(Qdiv);
          }
        }

        const btnDiv = divAtt('div', 'class', 'sectionGap btnEndGrid');
        const btn = divAtt('button', 'class', 'btn tooltip');
        btn.setAttribute('id', `btn_${row.sectionNumber}`);
        const btnSpin = divAtt('span', 'id', 'loadingText');
        btnSpin.setAttribute('class', 'btnText');
        const next = langObj.tas_Mkr12 ? langObj.tas_Mkr12 : 'Next';
        btnSpin.innerHTML = next;
        btn.disabled = true;
        const tooltip = divAtt('span', 'class', 'tooltiptext');
        const haventAnswered = langObj.tas_Mkr13 ? langObj.tas_Mkr13 : `You haven’t answered all the questions yet.`;
        tooltip.innerHTML = haventAnswered;
        btn.appendChild(btnSpin);
        btn.appendChild(tooltip);
        btnDiv.appendChild(btn);
        sectionDiv.appendChild(btnDiv);
        surveyDiv.appendChild(sectionDiv);

        console.log('dynEvents: ', dynEvents);
        //test
        for (let i = 0; i < dynEvents.length; i++) {
          eListen(dynEvents[i][0], dynEvents[i][1], dynEvents[i][2]);
        }

        let id2 = `btn_${row.sectionNumber}`;
        if (row.sectionNumber != data.length) {
          eListen(
            id2,
            () => {
              hide(`section_${row.sectionNumber}`);
              unhide(`section_${row.sectionNumber + 1}`);
              window.scrollTo(0, 0);
              takeAssessment.saveDoubleColumn();
            },
            'click'
          );
        } else {
          eListen(
            id2,
            () => {
              btnModalOn(`btn_${row.sectionNumber}`);
              PREP('takeAssessment', `allocHuserHassessmentID=${assID}&displayPage=${parseInt(pageNum) + 1}`, takeAssessment.saveDoubleColumn());
            },
            'click'
          );
          // btn.setAttribute(
          //   "onclick",
          //   `btnModalOn('btn_${
          //     row.sectionNumber
          //   }'), PREP('takeAssessment', 'allocHuserHassessmentID=${assID}&displayPage=${
          //     parseInt(pageNum) + 1
          //   }', takeAssessment.saveDoubleColumn())`
          // );
        }

        const sectionID = row.sectionNumber;
        if (sectionID != 1) {
          hide(`section_${sectionID}`);
        }

        this.activateNxtBtn(row.sectionNumber);
      }
    }
  },
  rating(row, questions, measures) {
    const rating = divAtt('div', 'class', 'rating');
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }

    const minText = document.createElement('p');
    minText.innerHTML = measures.minText;
    const maxText = document.createElement('p');
    maxText.innerHTML = measures.maxText;
    let dynamicEvents = [];
    const ratingSteps = divAtt('div', 'class', 'ratingSteps');
    for (const choices of measures.choices) {
      const ratingLine = divAtt('div', 'class', `ratingLine ratingLine_${questions.questionID}`);
      ratingLine.setAttribute('id', `line_${choices.displayOrder}`);

      const ratingItem = divAtt('label', 'class', `ratingItem rating_${questions.questionID}`);
      ratingItem.setAttribute('id', `dot_${choices.displayOrder}`);
      ratingItem.innerHTML = choices.choiceDescription;
      ratingItem.setAttribute('for', `${choices.measureID}`);
      // ratingItem.setAttribute(
      //   "onclick",
      //   `takeAssessment.saveRatingAnswer(${questions.questionID}, ${measures.measureTypeID}, ${choices.measureID}, this.id, ${row.sectionNumber})`
      // );
      let dId = `dot_${choices.displayOrder}`;
      dynamicEvents.push([
        dId,
        () => {
          takeAssessment.saveRatingAnswer(questions.questionID, measures.measureTypeID, choices.measureID, dId, row.sectionNumber);
        },
        'click',
      ]);

      ratingSteps.appendChild(ratingItem);
      if (choices.displayOrder != measures.choices.length) {
        ratingSteps.appendChild(ratingLine);
      }
    }

    rating.appendChild(minText);
    rating.appendChild(ratingSteps);
    rating.appendChild(maxText);

    return [rating, dynamicEvents];
  },
  shortMultipleChoice(row, questions, measures) {
    // console.log('shortMulti data:', row, questions, measures)

    const div = divAtt('div', 'class', 'sectionGap radioDivShort');
    div.setAttribute('id', `${questions.questionID}`);
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }
    let dynamicEvents = [];
    for (const choices of measures.choices) {
      const radioItem = divAtt('label', 'class', `radioItem radio_${questions.questionID}`);
      radioItem.setAttribute('id', `radio_${choices.displayOrder}_${questions.questionID}`);
      radioItem.innerHTML = choices.choiceDescription;
      radioItem.setAttribute('for', `${choices.measureID}`);
      // radioItem.setAttribute(
      //   "onclick",
      //   `takeAssessment.saveRadioAnswer(${questions.questionID}, ${measures.measureTypeID}, ${choices.measureID}, this.id, ${row.sectionNumber})`
      // );
      div.appendChild(radioItem);
      let Id1 = `radio_${choices.displayOrder}_${questions.questionID}`;
      dynamicEvents.push([
        Id1,
        () => {
          takeAssessment.saveRadioAnswer(questions.questionID, measures.measureTypeID, choices.measureID, Id1, row.sectionNumber);
        },
        'click',
      ]);
    }

    return [div, dynamicEvents];
  },
  longMultipleChoice(row, questions, measures) {
    // console.log('longMultipleChoice', row, questions, measures);

    const div = divAtt('div', 'class', 'sectionGap radioDivLong');
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }
    let dynamicEvents = [];
    for (const choices of measures.choices) {
      const radioItem = divAtt('label', 'class', `radioItem radio_${questions.questionID}`);
      radioItem.setAttribute('id', `radio_${choices.displayOrder}_${questions.questionID}`);
      radioItem.innerHTML = choices.choiceDescription;
      radioItem.setAttribute('for', `${choices.measureID}`);
      // radioItem.setAttribute(
      //   "onclick",
      //   `takeAssessment.saveRadioAnswer(${questions.questionID}, ${measures.measureTypeID}, ${choices.measureID}, this.id, ${row.sectionNumber})`
      // );
      let id1 = `radio_${choices.displayOrder}_${questions.questionID}`;
      dynamicEvents.push([
        id1,
        () => {
          takeAssessment.saveRadioAnswer(questions.questionID, measures.measureTypeID, choices.measureID, id1, row.sectionNumber);
        },
        'click',
      ]);
      div.appendChild(radioItem);
    }

    return [div, dynamicEvents];
  },
  multipleChoice2Part(row, questions, measures) {
    const div = divAtt('div', 'class', '');
    div.style = 'margin-bottom: 4rem;';
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }

    // Part 1
    const measure1 = measures[0][0];
    const measureName1 = divAtt('h6', 'class', 'measureName');
    measureName1.innerHTML = measure1.measureName;
    const radioDiv1 = divAtt('div', 'class', `radioDivShort radioDivShort_${questions.questionID}`);
    radioDiv1.setAttribute('id', `radioDivShort_1_${questions.questionID}`);

    let dynamicEvents = [];
    for (const choices1 of measure1.choices) {
      const radioItem1 = divAtt('label', 'class', `radioItem radio1_${questions.questionID}`);
      radioItem1.setAttribute('id', `radio1_${choices1.displayOrder}_${questions.questionID}`);
      radioItem1.innerHTML = choices1.choiceDescription;
      radioItem1.setAttribute('for', `${choices1.measureID}`);
      // radioItem1.setAttribute(
      //   "onclick",
      //   `takeAssessment.save2PartRadioAnswer(${questions.questionID}, ${measure1.measureTypeID}, ${choices1.measureID}, this.id, ${row.sectionNumber}, 'radioDivShort_1_${questions.questionID}')`
      // );
      let id1 = `radio1_${choices1.displayOrder}_${questions.questionID}`;
      dynamicEvents.push([
        id1,
        () => {
          takeAssessment.save2PartRadioAnswer(questions.questionID, measure1.measureTypeID, choices1.measureID, id1, row.sectionNumber, `radioDivShort_1_${questions.questionID}`);
        },
        'click',
      ]);
      radioDiv1.appendChild(radioItem1);
    }

    // Part 2
    const measure2 = measures[1][0];
    const measureName2 = divAtt('h6', 'class', 'measureName');
    measureName2.innerHTML = measure2.measureName;
    const radioDiv2 = divAtt('div', 'class', `radioDivShort radioDivShort_${questions.questionID}`);
    radioDiv2.setAttribute('id', `radioDivShort_2_${questions.questionID}`);
    for (const choices2 of measure2.choices) {
      const radioItem2 = divAtt('label', 'class', `radioItem radio2_${questions.questionID}`);
      radioItem2.setAttribute('id', `radio2_${choices2.displayOrder}_${questions.questionID}`);
      radioItem2.innerHTML = choices2.choiceDescription;
      radioItem2.setAttribute('for', `${choices2.measureID}`);
      // radioItem2.setAttribute(
      //   "onclick",
      //   `takeAssessment.save2PartRadioAnswer(${questions.questionID}, ${measure2.measureTypeID}, ${choices2.measureID}, this.id, ${row.sectionNumber}, 'radioDivShort_2_${questions.questionID}')`
      // );
      let id2 = `radio2_${choices2.displayOrder}_${questions.questionID}`;
      dynamicEvents.push([
        id2,
        () => {
          takeAssessment.save2PartRadioAnswer(questions.questionID, measure2.measureTypeID, choices2.measureID, id2, row.sectionNumber, `radioDivShort_2_${questions.questionID}`);
        },
        'click',
      ]);
      radioDiv2.appendChild(radioItem2);
    }

    div.appendChild(measureName1);
    div.appendChild(radioDiv1);
    div.appendChild(measureName2);
    div.appendChild(radioDiv2);

    return [div, dynamicEvents];
  },
  doubleColumn(row, langLexicon) {
    const langObj = {};
    for (let row of langLexicon) {
      langObj[row.marker] = row.display;
    }

    const Qdiv = divAtt('div', 'class', `question Qsec_${row.sectionNumber} snakeBiteGrid`);
    const div1 = divAtt('div');
    const devHeading = divAtt('h6', 'class', 'primaryColor');
    const strengthHeading = divAtt('h6', 'class', 'primaryColor');
    devHeading.innerHTML = row.questions[0].measures[0][0].choices[0].choiceDescription;
    strengthHeading.innerHTML = row.questions[0].measures[0][0].choices[1].choiceDescription;
    // devHeading.innerHTML = langObj.tas_Mkr14 ? langObj.tas_Mkr14 : 'Development Area';
    // strengthHeading.innerHTML = langObj.tas_Mkr15 ? langObj.tas_Mkr15 : 'Strength Area';

    Qdiv.appendChild(div1);
    Qdiv.appendChild(devHeading);
    Qdiv.appendChild(strengthHeading);
    let dynamicEvents = [];
    for (const questions of row.questions) {
      const measureTypeID = questions.measures[0][0].measureTypeID;
      const measureID = questions.measures[0][0].choices;

      const questionDiv = divAtt('div');
      const question = divAtt('p');
      question.innerHTML = questions.assessmentQuestion;
      questionDiv.appendChild(question);

      // Qid_measureTypeID_measureID

      const devDiv = divAtt('div', 'class', 'center');
      const devLabel = divAtt('label', 'class', 'checkContainer');
      const devInpt = divAtt('input', 'type', 'checkbox');
      devInpt.setAttribute('id', `${questions.questionID}_${measureTypeID}_${measureID[0].measureID}`);
      devInpt.classList.add('devArea');
      // devInpt.setAttribute(
      //   "onclick",
      //   `takeAssessment.checkDoubleColumn(${row.sectionNumber})`
      // );
      let id1 = `${questions.questionID}_${measureTypeID}_${measureID[0].measureID}`;
      let v1 = row.sectionNumber;
      dynamicEvents.push([
        id1,
        () => {
          takeAssessment.checkDoubleColumn(v1);
        },
        'click',
      ]);
      const tick = divAtt('span', 'class', 'tick');
      devLabel.appendChild(devInpt);
      devLabel.appendChild(tick);
      devDiv.appendChild(devLabel);

      // ==
      const strengthDiv = divAtt('div', 'class', 'center');
      const strengthLabel = divAtt('label', 'class', 'checkContainer');
      const strengthInpt = divAtt('input', 'type', 'checkbox');
      strengthInpt.setAttribute('id', `${questions.questionID}_${measureTypeID}_${measureID[1].measureID}`);
      strengthInpt.classList.add('strengthArea');
      // strengthInpt.setAttribute(
      //   "onclick",
      //   `takeAssessment.checkDoubleColumn(${row.sectionNumber})`
      // );
      let id2 = `${questions.questionID}_${measureTypeID}_${measureID[1].measureID}`;
      dynamicEvents.push([
        id2,
        () => {
          takeAssessment.checkDoubleColumn(row.sectionNumber);
        },
      ]);
      const tick2 = divAtt('span', 'class', 'tick');
      strengthLabel.appendChild(strengthInpt);
      strengthLabel.appendChild(tick2);
      strengthDiv.appendChild(strengthLabel);

      Qdiv.appendChild(questionDiv);
      Qdiv.appendChild(devDiv);
      Qdiv.appendChild(strengthDiv);
    }
    return [Qdiv, dynamicEvents];
  },
  textBox(row, questions, measures) {
    const div = divAtt('div', 'class', 'group gridSpan marginBottom');
    div.setAttribute('id', `${questions.questionID}`);
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }
    let dynamicEvents = [];
    const textarea = divAtt('textarea', 'id', `text_${questions.questionID}`);
    textarea.setAttribute('id', `text_${questions.questionID}`);
    // textarea.setAttribute(
    //   "onblur",
    //   `takeAssessment.saveValueAnswer(${questions.questionID}, ${measures.measureTypeID}, ${measures.choices[0].measureID}, this.id, ${row.sectionNumber})`
    // );
    dynamicEvents.push([
      `text_${questions.questionID}`,
      () => {
        takeAssessment.saveValueAnswer(questions.questionID, measures.measureTypeID, measures.choices[0].measureID, `text_${questions.questionID}`, row.sectionNumber);
      },
      'blur',
    ]);
    textarea.setAttribute('cols', '30');
    textarea.setAttribute('rows', '10');

    div.appendChild(textarea);

    return [div, dynamicEvents];
  },
  input(row, questions, measures) {
    // console.log('input', row, questions, measures);

    const div = divAtt('div', 'class', 'group gridSpan marginBottom');
    div.setAttribute('id', `${questions.questionID}`);
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }

    const input = divAtt('input', 'type', 'text');
    input.setAttribute('id', `input_${questions.questionID}`);
    input.setAttribute('onblur', `takeAssessment.saveValueAnswer(${questions.questionID}, ${measures.measureTypeID}, ${choices.measureID}, this.id, ${row.sectionNumber}), notEmptyVal(this.id)`);
    const bar = divAtt('span', 'class', 'bar');
    bar.style = `width: 100%;`;
    const valFeedback = divAtt('p', 'class', 'inpFB hide');
    valFeedback.setAttribute('id', `input_${questions.questionID}_FB`);

    div.appendChild(input);
    div.appendChild(bar);
    div.appendChild(valFeedback);

    return div;
  },
  dropDown(row, questions, measures) {
    const div = divAtt('div', 'class', 'group marginBottom');
    div.setAttribute('id', `${questions.questionID}`);
    if (questions.requiredForComplete === 0) {
      div.classList.add('completed');
    }
    let dynamicEvents = [];
    const select = divAtt('select', 'type', 'text');
    select.setAttribute('id', `select_${questions.questionID}`);
    // select.setAttribute(
    //   "onchange",
    //   `takeAssessment.saveValueAnswer(${questions.questionID}, ${measures.measureTypeID}, undefined, this.id, ${row.sectionNumber}), notEmptyVal(this.id)`
    // );
    dynamicEvents.push([
      `select_${questions.questionID}`,
      () => {
        takeAssessment.saveValueAnswer(questions.questionID, measures.measureTypeID, undefined, `select_${questions.questionID}`, row.sectionNumber);
        notEmptyVal(`select_${questions.questionID}`);
      },
      'change',
    ]);
    select.setAttribute('id', `select_${questions.questionID}`);
    const bar = divAtt('span', 'class', 'bar');
    bar.style = `width: 100%;`;
    const valFeedback = divAtt('p', 'class', 'inpFB hide');
    valFeedback.setAttribute('id', `select_${questions.questionID}_FB`);

    // build dropdown options
    let optOne = divAtt('option', 'value', '-');
    optOne.innerHTML = 'Please choose an option ---';
    select.appendChild(optOne);
    measures.choices.forEach((arr) => {
      const opt = divAtt('option', 'value', `${arr.measureID}`);
      opt.innerHTML = arr.choiceDescription;
      select.appendChild(opt);
    });

    div.appendChild(select);
    div.appendChild(bar);
    div.appendChild(valFeedback);

    return [div, dynamicEvents];
  },
  slider(row, questions, measures) {
    const sliderDiv = divAtt('div', 'class', 'slider');
    sliderDiv.setAttribute('id', `${questions.questionID}`);
    if (questions.requiredForComplete === 0) {
      sliderDiv.classList.add('completed');
    }
    const minText = document.createElement('p');
    if (measures.minText !== '') {
      minText.innerHTML = measures.minText;
    } else {
      minText.innerHTML = 'Never';
    }

    const maxText = document.createElement('p');
    if (measures.maxText !== '') {
      maxText.innerHTML = measures.maxText;
    } else {
      maxText.innerHTML = 'Always';
    }
    let dynamicEvents = [];
    const slider = divAtt('input', 'class', 'slider');
    slider.setAttribute('id', `slider_${questions.questionID}`);
    slider.setAttribute('type', 'range');
    slider.setAttribute('min', '1');
    slider.setAttribute('max', '5');
    slider.setAttribute('value', '0');
    // slider.setAttribute(
    //   "onclick",
    //   `takeAssessment.saveValueAnswer(${questions.questionID}, ${measures.measureTypeID}, ${measures.choices[0].measureID}, this.id, ${row.sectionNumber})`
    // );
    let id1 = `slider_${questions.questionID}`;
    dynamicEvents.push([
      id1,
      () => {
        takeAssessment.saveValueAnswer(questions.questionID, measures.measureTypeID, measures.choices[0].measureID, id1, row.sectionNumber);
      },
      'click',
    ]);
    sliderDiv.appendChild(minText);
    sliderDiv.appendChild(slider);
    sliderDiv.appendChild(maxText);

    return [sliderDiv, dynamicEvents];
  },
  saveRatingAnswer(Qid, measureTypeID, measureID, elID, secNum) {
    const vars = {};

    vars.allocHuserHassessmentID = id('assID').value;
    vars.DassessmentID = Qid;
    vars.HmeasureID = measureTypeID;
    vars.DmeasureID = measureID;

    PREP(['recordAssessmentChoice'], vars).then((res) => {
      const displayOrderNum = elID.split('_');
      this.colorRating(Qid, displayOrderNum[1], secNum);

      // UPDATE PROGRESS BAR
      const data = res.data.recordAssessmentChoice[0];
      this.progressBar(data.outstandingQuestions, data.totCompulsoryQuestions);
    });
  },
  saveRadioAnswer(Qid, measureTypeID, measureID, elID, secNum) {
    const vars = {};

    vars.allocHuserHassessmentID = id('assID').value;
    vars.DassessmentID = Qid;
    vars.HmeasureID = measureTypeID;
    vars.DmeasureID = measureID;

    PREP(['recordAssessmentChoice'], vars).then((res) => {
      const radios = document.querySelectorAll(`.radio_${Qid}`);
      radios.forEach((radio) => {
        radio.classList.remove('radioActive');
      });

      id(elID).classList.add('radioActive');

      // MARK AS COMPLETED
      id(Qid).classList.add('completed');

      // ACTIVATE BTN
      this.activateNxtBtn(secNum);

      // UPDATE PROGRESS BAR
      const data = res.data.recordAssessmentChoice[0];
      this.progressBar(data.outstandingQuestions, data.totCompulsoryQuestions);
    });
  },
  save2PartRadioAnswer(Qid, measureTypeID, measureID, elID, secNum, holdingDivID) {
    const vars = {};

    console.log('holdingDivID: ', holdingDivID);

    vars.allocHuserHassessmentID = id('assID').value;
    vars.DassessmentID = Qid;
    vars.HmeasureID = measureTypeID;
    vars.DmeasureID = measureID;

    PREP(['recordAssessmentChoice'], vars).then((res) => {
      if (id(elID).classList.contains(`radio1_${Qid}`)) {
        const radios1 = document.querySelectorAll(`.radio1_${Qid}`);
        radios1.forEach((radio1) => {
          radio1.classList.remove('radioActive');
        });
      } else {
        const radios2 = document.querySelectorAll(`.radio2_${Qid}`);
        radios2.forEach((radio2) => {
          radio2.classList.remove('radioActive');
        });
      }

      id(elID).classList.add('radioActive');

      // MARK AS COMPLETED
      id(holdingDivID).classList.add('completed');

      let partCompleted = true;
      const partDivs = document.querySelectorAll(`.radioDivShort_${Qid}`);
      partDivs.forEach((partDiv) => {
        if (!partDiv.classList.contains('completed')) {
          partCompleted = false;
        }
      });

      if (partCompleted) {
        id(Qid).classList.add('completed');
      }

      // ACTIVATE BTN
      this.activateNxtBtn(secNum);

      // UPDATE PROGRESS BAR
      const data = res.data.recordAssessmentChoice[0];
      this.progressBar(data.outstandingQuestions, data.totCompulsoryQuestions);
    });
  },
  saveValueAnswer(Qid, measureTypeID, measureID, elID, secNum) {
    if (!measureID) {
      if (id(elID).value === '-' || !id(elID).value) {
        id(Qid).classList.remove('completed');
        this.activateNxtBtn(secNum);
        return;
      }
    }

    const vars = {};

    vars.allocHuserHassessmentID = id('assID').value;
    vars.DassessmentID = Qid;
    vars.HmeasureID = measureTypeID;
    vars.DmeasureID = measureID ? measureID : id(elID).value;
    vars.textAnswer = id(elID).value;

    PREP(['recordAssessmentChoice'], vars).then((res) => {
      // MARK AS COMPLETED
      id(Qid).classList.add('completed');

      // ACTIVATE BTN
      this.activateNxtBtn(secNum);

      // UPDATE PROGRESS BAR
      const data = res.data.recordAssessmentChoice[0];
      this.progressBar(data.outstandingQuestions, data.totCompulsoryQuestions);
    });
  },
  async checkDoubleColumn(secNum) {
    const langObj = {};
    const vars = {};
    vars.HTMLPageName = 'takeAssessment';
    vars.languageID = getCookie('mbohvbhfje');

    await PREP(['getHTMLLexicon'], vars).then(async (res) => {
      const lang = res.data.getHTMLLexicon;
      for (let row of lang) {
        langObj[row.marker] = row.display;
      }
    });

    const devs = document.querySelectorAll('.devArea');
    const strengths = document.querySelectorAll('.strengthArea');

    let devCount = 0;
    devs.forEach((dev) => {
      if (dev.checked === true) {
        devCount++;
      }
    });

    let strengthCount = 0;
    strengths.forEach((strength) => {
      if (strength.checked === true) {
        strengthCount++;
      }
    });

    if (devCount !== 0 && devCount <= 2 && strengthCount !== 0 && strengthCount <= 2) {
      id(`btn_${secNum}`).disabled = false;
    } else {
      id(`btn_${secNum}`).disabled = true;
    }

    if (devCount > 2 || strengthCount > 2) {
      const tooManyCopy = langObj.tas_Mkr6 ? langObj.tas_Mkr6 : 'You have selected too many Checkboxes. Please select ONE or TWO per area.';
      infoBox(tooManyCopy);
    }
  },
  async saveDoubleColumn() {
    const selectedAnswers = document.querySelectorAll('.devArea,.strengthArea');
    selectedAnswers.forEach(async (answer) => {
      if (answer.checked === false) {
        return;
      }

      // Qid, measureTypeID, measureID
      const answerID = answer.id.split('_');
      console.log(answer.id);
      console.log('answerID:', answerID);

      const vars = {};
      vars.allocHuserHassessmentID = id('assID').value;
      vars.DassessmentID = answerID[0];
      vars.HmeasureID = answerID[1];
      vars.DmeasureID = answerID[2];

      await PREP(['recordAssessmentChoice'], vars).then((res) => {
        // UPDATE PROGRESS BAR
        const data = res.data.recordAssessmentChoice[0];
        this.progressBar(data.outstandingQuestions, data.totCompulsoryQuestions);
      });
    });
  },
  colorRating(Qid, displayOrderNum, secNum) {
    // DOT
    const ratingNums = document.querySelectorAll(`.rating_${Qid}`);
    ratingNums.forEach((ratingNum) => {
      const rating = ratingNum.id.split('_');
      if (parseInt(rating[1]) <= parseInt(displayOrderNum)) {
        ratingNum.classList.add('ratingActive');
      } else {
        ratingNum.classList.remove('ratingActive');
      }
    });

    // LINE
    const ratingLines = document.querySelectorAll(`.ratingLine_${Qid}`);
    ratingLines.forEach((ratingLine) => {
      const line = ratingLine.id.split('_');
      if (parseInt(line[1]) < parseInt(displayOrderNum)) {
        ratingLine.classList.add('ratingActive');
      } else {
        ratingLine.classList.remove('ratingActive');
      }
    });

    // MARK AS COMPLETED
    id(Qid).classList.add('completed');

    // ACTIVATE BTN
    this.activateNxtBtn(secNum);
  },
  activateNxtBtn(secNum) {
    const questions = document.querySelectorAll(`.Qsec_${secNum}`);

    let allCompleted = true;
    questions.forEach((question) => {
      if (!question.classList.contains('completed')) {
        allCompleted = false;
      }
    });

    if (allCompleted) {
      id(`btn_${secNum}`).disabled = false;
    } else {
      id(`btn_${secNum}`).disabled = true;
    }
  },
  endPage(data, assID, langLexicon) {
    const langObj = {};
    for (let row of langLexicon) {
      langObj[row.marker] = row.display;
    }

    id('surveyIntro').innerHTML = '';
    const TY = divAtt('p');

    const thanksCopy = langObj.tas_Mkr9 ? langObj.tas_Mkr9 : `Thank you very much for completing the survey. Please click Finish to complete this assessment.`;
    TY.innerHTML = data.headerText ? data.headerText : thanksCopy;
    id('surveyIntro').appendChild(TY);

    const btnDiv = divAtt('div', 'class', 'sectionGap btnEndGrid marginTop');
    const btn = divAtt('button', 'class', 'btn');
    btn.setAttribute('id', 'finishServBtn');
    const finishCopy = langObj.tas_Mkr5 ? langObj.tas_Mkr5 : 'Finish Assessment';
    btn.innerHTML = finishCopy;
    //btn.setAttribute("onclick", `takeAssessment.finishSurvey(${assID})`);

    btnDiv.appendChild(btn);
    id('surveyIntro').appendChild(btnDiv);
    eListen(
      'finishServBtn',
      () => {
        takeAssessment.finishSurvey(assID);
      },
      'click'
    );
  },
  finishSurvey(assID) {
    const vars = {};
    vars.allocHuserHassessmentID = assID;

    PREP(['completeAssessment'], vars)
      .then((res) => {
        infoBox(res.data.completeAssessment);
        PREP('myassessments', 'dTab=MAt_current');
      })
      .catch(console.error());
  },
  completeSurvey(id) {
    var countD = document.getElementById('countDown');
    var countDN = parseInt(countD.innerHTML) + 1;

    countD.innerHTML = countDN;

    var test = document.getElementsByTagName('textarea');
    count = test.length;

    for (var i = 0; i < test.length; i++) {
      if (test[i].innerHTML.length || test[i].id === 'NewGroupdescription') {
        count--;
      }
    }
    if (count != 0 && countDN === 1) {
      // when submittin with no text in any of the boxes
      var motivation = 'While it is only optional to enter your comments in the input fields in this section,  it would be very helpful if you could offer some constructive feedback to help the person you rated. Once you click the ‘Complete’ button you will not be able to go back and enter comments';
      // alert(motivation)
      if (alert(motivation)) {
      }
    } else {
      confirmSurvey(id);
    }
  },
};

export const MAt_reports = {
  listReports: async (data, langArr) => {
    const langObj = await createLangObj(langArr);
    id('applicationReports').innerHTML = '';
    if (data.length) {
      hide('reportMsg');
      const table = id('applicationReports');
      const thead = divAtt('thead', 'class', '');
      const dateH = divAtt('th', 'class', 'mar_Mkr1');
      dateH.innerHTML = langObj.mat_Mkr1 ? langObj.mat_Mkr1 : 'Date uploaded';
      const nameH = divAtt('th', 'class', 'mar_Mkr2');
      nameH.innerHTML = langObj.mat_Mkr2 ? langObj.mat_Mkr2 : 'Report name';
      const fileNameH = divAtt('th', 'class', 'mar_Mkr6');
      fileNameH.innerHTML = langObj.mat_Mkr4 ? langObj.mat_Mkr4 : 'File name';
      const downH = divAtt('th', 'class', '');
      downH.innerHTML = '';
      thead.appendChild(dateH);
      thead.appendChild(nameH);
      thead.appendChild(fileNameH);
      thead.appendChild(downH);
      table.appendChild(thead);

      for (let report of data) {
        const row = divAtt('tr', 'class', '');
        const date = dateFromDateTime(new Date(report.DATEON));
        const name = report.REPORTTYPE;
        const dateD = divAtt('td', 'class', '');
        dateD.innerHTML = date;
        const nameD = divAtt('td', 'class', '');
        nameD.innerHTML = name;
        const filenameD = divAtt('td', 'class', '');
        filenameD.innerHTML = report.ORIGINALFILE;
        const downD = divAtt('td', 'class', '');
        const btn = divAtt('div', 'class', 'btn mar_Mkr3');
        btn.setAttribute('id', `MAt_reportsDownloadBtn_${report.REPORTREGISTERID}`);
        // btn.setAttribute(
        //   "onclick",
        //   "MAt_reports.download(" + report.REPORTREGISTERID + ")"
        // );
        btn.setAttribute('style', 'margin-left: auto; margin-right: auto;');
        btn.innerHTML = langObj.mat_Mkr3 ? langObj.mat_Mkr3 : 'Download';
        downD.appendChild(btn);
        row.appendChild(dateD);
        row.appendChild(nameD);
        row.appendChild(filenameD);
        row.appendChild(downD);
        table.appendChild(row);
        eListen(
          `MAt_reportsDownloadBtn_${report.REPORTREGISTERID}`,
          () => {
            MAt_reports.download(report.REPORTREGISTERID);
          },
          'click'
        ); //test
      }
    } else {
      unhide('reportMsg');
    }
  },
  download: (reportRegisterID) => {
    PREP(['downloadReport'], {
      reportRegisterID: reportRegisterID,
    })
      .then((res) => {
        window.open(res.data.downloadReport, '_blank');
      })
      .catch(console.error());
  },
};

export const myDevelopment = {
  devPage: async (data, langArr) => {
    const lang = await createLangObj(langArr);

    const cardData = cObj(data.getAllocatedIDPElements);

    if (cardData.length) {
      const cards = id('Rcards');
      cards.innerHTML = '';

      const headingDiv = divAtt('div', 'class', 'gridSpan');
      const h3 = document.createElement('h3');
      h3.innerHTML = lang.mdp_Mkr26 ? lang.mdp_Mkr26 : 'Resourses:';
      headingDiv.appendChild(h3);
      cards.appendChild(headingDiv);

      const vars = {};
      PREP(['getAllocHuserIDPAssets'], vars)
        .then((res) => {
          const cardInfo = res.data.getAllocHuserIDPAssets;

          for (const row of cardInfo) {
            const card = divAtt('div', 'id', `${row.DDcategoryID}`);
            card.setAttribute('class', 'card cardCliclickable');
            card.style = 'display: unset;';
            // card.setAttribute(
            //   "onclick",
            //   `myDevelopment.resources('${row.DDcategoryID}')`
            // );
            const heading = document.createElement('h5');
            heading.innerHTML = row.shortDescription;
            const descript = divAtt('p', 'class', '');
            descript.innerHTML = row.longdescription;

            const resourceList = divAtt('ul', 'class', 'ulResources');
            const list = row.assetArray;
            for (const item of list) {
              const li = divAtt('li', 'class', '');
              li.innerHTML = `${item.AssetType} (${item.AssetCount})`;
              resourceList.appendChild(li);
            }

            card.appendChild(heading);
            card.appendChild(descript);
            card.appendChild(resourceList);
            cards.appendChild(card);
            eListen(
              `${row.DDcategoryID}`,
              () => {
                myDevelopment.resources(row.DDcategoryID);
              },
              'click'
            ); //test
          }
        })
        .catch(console.error());

      hide('noDevPlan');
      unhide('devPlan');
    } else {
      unhide('noDevPlan');
      hide('devPlan');
    }
  },
  resources: (catID) => {
    // block active
    const blocks = document.querySelectorAll('.cardCliclickable');
    blocks.forEach((block) => {
      block.classList.remove('cardActive');
    });
    id(catID).classList.add('cardActive');

    const vars = {};
    vars.DDcategoryID = catID;
    vars.HTMLPageName = 'myDevelopment';
    vars.languageID = getCookie('mbohvbhfje');
    PREP(['listAllocHuserIDPAssets', 'getHTMLLexicon'], vars)
      .then((res) => {
        const lang = {};
        const langData = res.data.getHTMLLexicon;
        for (let row of langData) {
          lang[row.marker] = row.display;
        }

        const tableData = res.data.listAllocHuserIDPAssets;
        const resources = id('resources');
        resources.innerHTML = '';
        const tabs = divAtt('div', 'class', 'blockTabs');
        resources.appendChild(tabs);
        let cntr = 0;

        let svgBooks = `<svg xmlns="http://www.w3.org/2000/svg" width="37.87" height="27.49" viewBox="0 0 37.87 27.49"><defs><style>.cls-1{fill-rule:evenodd;}</style></defs><path class="cls-1" d="M36.36,2.74V23.1s-5.79-1.63-10.1-.8a18,18,0,0,0-7.16,3.2v.06l0,0,0,0V25.5a18.14,18.14,0,0,0-7.17-3.2c-4.3-.83-10.66.8-10.66.8V2.68L0,2.86V24.79S6.93,23,11.64,23.91a15.87,15.87,0,0,1,7.43,3.58,15.93,15.93,0,0,1,7.44-3.57c4.71-.91,11.36.87,11.36.87V3S37.29,2.86,36.36,2.74Z" transform="translate(0 0)"/><path class="cls-1" d="M11.5,20.24a18,18,0,0,1,6.68,2.9v-20A18.08,18.08,0,0,0,11.48.21C7.65-.53,2.66.92,2.66.92V21S7.67,19.5,11.5,20.24Z" transform="translate(0 0)"/><path class="cls-1" d="M34.88,21h0V1a20.24,20.24,0,0,0-8.4-.72,19.13,19.13,0,0,0-6.8,2.91v20a19.3,19.3,0,0,1,6.77-2.9C30.29,19.57,34.88,21,34.88,21Z" transform="translate(0 0)"/></svg> `;
        let svgWorkshops = `<svg xmlns="http://www.w3.org/2000/svg" width="28.34" height="32.44" viewBox="0 0 28.34 32.44"><path d="M23.29,16.29h0l-.5-.39-.15.05-.13.61h0a2.19,2.19,0,0,1-2.12,1.71h-.1l-.77,0-.78,0a2.17,2.17,0,0,1-2.06-1.9v0l-.07-.62-.1,0L16,16a2.17,2.17,0,0,1-2.77-.36l-1-1.13a2.18,2.18,0,0,1-.11-2.8l.38-.47a1.21,1.21,0,0,1-.05-.14l-.64-.14A2.16,2.16,0,0,1,10,8.72l0-.78,0-.78a2.16,2.16,0,0,1,1.9-2L12.6,5l.06-.13-.35-.54h0A2.16,2.16,0,0,1,12,2.69,11.43,11.43,0,0,0,2.57,13.94v1.9L.25,20.28a1.29,1.29,0,0,0,1.24,2H2.58v4.23A2.42,2.42,0,0,0,5,29l3.3-.49V31.8a.66.66,0,0,0,.68.64l.14,0L21,30.32a1,1,0,0,0,.81-1V22.26a11.42,11.42,0,0,0,3.28-5.56A2.2,2.2,0,0,1,23.29,16.29Zm-17.4.65a.9.9,0,0,1,0-1.8.9.9,0,0,1,0,1.8Z" transform="translate(0.01 0)"/><path d="M27.85,7.35,26.32,7a7,7,0,0,0-.62-1.71l1-1.24a.58.58,0,0,0,0-.76l-1-1.15a.62.62,0,0,0-.77-.1l-1.32.85a6.09,6.09,0,0,0-1.68-.77L21.64.6a.61.61,0,0,0-.57-.53l-.78,0-.77,0a.6.6,0,0,0-.62.47L18.57,2a6.65,6.65,0,0,0-1.7.62l-1.22-1a.58.58,0,0,0-.76,0l-1.15,1a.61.61,0,0,0-.1.76l.86,1.33a6.3,6.3,0,0,0-.75,1.65l-1.55.19a.58.58,0,0,0-.52.56L11.6,8.79a.6.6,0,0,0,.48.61l1.54.34a6.58,6.58,0,0,0,.63,1.7l-1,1.22a.59.59,0,0,0,0,.77l1,1.14a.58.58,0,0,0,.76.1l1.33-.85a6.45,6.45,0,0,0,1.6.74l.19,1.56a.6.6,0,0,0,.56.53l.78,0,.78,0a.6.6,0,0,0,.61-.47l.33-1.54a6.09,6.09,0,0,0,1.7-.62l1.24,1A.59.59,0,0,0,25,15l1.14-1a.58.58,0,0,0,.1-.76l-.84-1.33a6.27,6.27,0,0,0,.76-1.64l1.56-.19a.62.62,0,0,0,.53-.57l0-.78,0-.77A.61.61,0,0,0,27.85,7.35Zm-4,1A3.89,3.89,0,0,1,20,12.24h0a3.89,3.89,0,1,1,3.88-3.89Z" transform="translate(0.01 0)"/></svg>`;
        let svgArticles = `<svg xmlns="http://www.w3.org/2000/svg" width="32.41" height="25.69" viewBox="0 0 32.41 25.69"><path d="M30.11,0H2.39A2.34,2.34,0,0,0,0,2.25V23.43a2.32,2.32,0,0,0,2.39,2.25H30a2.33,2.33,0,0,0,2.39-2.25V2.25A2.29,2.29,0,0,0,30.11,0ZM10.21,2.32h0a1,1,0,0,1,1,1,1,1,0,0,1-2,0A1,1,0,0,1,10.21,2.32Zm-3.1,0a1,1,0,0,1,1,1,1,1,0,0,1-2.05,0A1,1,0,0,1,7.11,2.32ZM4,2.32a1,1,0,0,1,1,1,1,1,0,0,1-1,1,1,1,0,0,1-1-1A1,1,0,0,1,4,2.32ZM29.29,22.85H3.17A.16.16,0,0,1,3,22.71v-16H29.41v16h0A.16.16,0,0,1,29.29,22.85Z" transform="translate(0 0)"/><path d="M25.87,18.34H16a.26.26,0,0,0-.26.26h0V20a.26.26,0,0,0,.26.26h9.88a.25.25,0,0,0,.26-.26h0V18.6A.25.25,0,0,0,25.87,18.34Z" transform="translate(0 0)"/><path d="M25.87,13.83H16a.26.26,0,0,0-.26.26h0v1.37a.26.26,0,0,0,.26.26h9.88a.26.26,0,0,0,.26-.26h0V14.09A.25.25,0,0,0,25.87,13.83Z" transform="translate(0 0)"/><path d="M25.87,9.32H16a.25.25,0,0,0-.26.25h0v1.36a.25.25,0,0,0,.26.26h9.88a.24.24,0,0,0,.26-.25h0V9.58A.24.24,0,0,0,25.87,9.32Z" transform="translate(0 0)"/><path d="M11.4,9.91H6.29a.27.27,0,0,0-.27.26H6v9.41a.27.27,0,0,0,.25.29H11.4a.26.26,0,0,0,.26-.26V10.18A.27.27,0,0,0,11.4,9.91Z" transform="translate(0 0)"/></svg> `;
        let svgPodcasts = `<svg xmlns="http://www.w3.org/2000/svg" width="18.9" height="33.53" viewBox="0 0 18.9 33.53"><path d="M16.8,11.55v4.2a7.35,7.35,0,0,1-14.7,0v-4.2H0v4.2A9.46,9.46,0,0,0,8.14,25.1V31H4.2V33.6H14.7V31H10.76V25.1a9.46,9.46,0,0,0,8.14-9.35v-4.2Z" transform="translate(0 -0.07)"/><path d="M9.38,20.55a4.72,4.72,0,0,0,4.79-4.66V4.73a4.73,4.73,0,0,0-9.45,0v11A4.74,4.74,0,0,0,9.38,20.55Z" transform="translate(0 -0.07)"/></svg> `;
        let svgVideos = `<svg xmlns="http://www.w3.org/2000/svg" width="37.63" height="37.71" viewBox="0 0 37.63 37.71"><path d="M37.78,18.14a18.23,18.23,0,0,0-10-15.8,16.18,16.18,0,0,0-7.4-2.1.37.37,0,0,0-.3-.1h-2.2c-.5.1-1.1.1-1.6.2A18.47,18.47,0,0,0,2,11a16.5,16.5,0,0,0-1.7,6.5.37.37,0,0,1-.1.3V20c.1.7.2,1.5.3,2.2a18.84,18.84,0,0,0,19.2,15.6,18.51,18.51,0,0,0,15.9-10A17.35,17.35,0,0,0,37.78,18.14ZM19,34.64a15.7,15.7,0,1,1,15.7-15.7A15.66,15.66,0,0,1,19,34.64Z" transform="translate(-0.18 -0.14)"/><path d="M23.35,18.1l-7.5-5.4a1.4,1.4,0,0,0-.9-.3,1,1,0,0,0-.8,1.1V24.4a.86.86,0,0,0,.3.9,1,1,0,0,0,1.4,0c2.5-1.8,5-3.6,7.5-5.5a1,1,0,0,0,.4-.5A.8.8,0,0,0,23.35,18.1Z" transform="translate(-0.18 -0.14)"/></svg> `;

        for (let row of tableData) {
          const div = divAtt('div', 'id', `${row.shortname}`);
          div.setAttribute('class', 'blockItem');
          let v_shrtName = `${row.shortname}`;
          let v_catID = catID;
          let v_cntr = cntr;

          //Icons
          let svgString;
          // div.innerHTML='<p>Herrr000</p>';
          if (row.shortname === 'Books') {
            div.innerHTML = svgBooks + '' + v_shrtName;
          } else if (row.shortname === 'Workshops') {
            div.innerHTML = svgWorkshops + '' + v_shrtName;
          } else if (row.shortname === 'Articles') {
            div.innerHTML = svgArticles + '' + v_shrtName;
          } else if (row.shortname === 'Podcasts') {
            div.innerHTML = svgPodcasts + '' + v_shrtName;
          } else if (row.shortname === 'Videos') {
            div.innerHTML = svgVideos + '' + v_shrtName;
          } else {
            div.innerHTML = v_shrtName;
          }
          tabs.appendChild(div);
          cntr++;
          if (!row.assetArray.length) {
            div.remove();
          } else {
            eListen(
              v_shrtName,
              () => {
                myDevelopment.buildResourceTable(v_catID, v_shrtName, v_cntr);
              },
              'click'
            ); //test
          }
        }
        const table = divAtt('table', 'id', 'resourceTable');
        // table.style = 'width: 100%;'
        resources.appendChild(table);
        // Build first table
        document.querySelector('.blockTabs').firstChild.click();
      })
      .catch(console.error());
  },
  buildResourceTable: (catID, resourceType, rowNum) => {
    // block active
    const blocks = document.querySelectorAll('.blockItem');
    blocks.forEach((block) => {
      block.classList.remove('blockActive');
    });
    id(resourceType).classList.add('blockActive');

    const vars = {};
    vars.DDcategoryID = catID;
    vars.HTMLPageName = 'myDevelopment';
    vars.languageID = getCookie('mbohvbhfje');
    PREP(['listAllocHuserIDPAssets', 'getHTMLLexicon'], vars).then((res) => {
      const lang = {};
      const data = res.data.getHTMLLexicon;
      for (let row of data) {
        lang[row.marker] = row.display;
      }

      const tableData = res.data.listAllocHuserIDPAssets;
      const assetArray = tableData[rowNum].assetArray;
      console.log(tableData[rowNum]);

      const table = id('resourceTable');
      table.innerHTML = '';
      // console.log('assetArray', assetArray);

      var thead = document.createElement('thead');
      var name = document.createElement('th');
      name.innerHTML = lang.mdp_Mkr6 ? lang.mdp_Mkr6 : 'Name';
      var description = document.createElement('th');
      description.innerHTML = lang.mdp_Mkr7 ? lang.mdp_Mkr7 : 'Description';
      var author = document.createElement('th');
      author.innerHTML = lang.mdp_Mkr8 ? lang.mdp_Mkr8 : 'Author';
      var ref = document.createElement('th');
      ref.innerHTML = lang.mdp_Mkr10 ? lang.mdp_Mkr10 : 'Reference';
      var pub = document.createElement('th');
      pub.innerHTML = lang.mdp_Mkr9 ? lang.mdp_Mkr9 : 'Published by';
      var edi = document.createElement('th');
      edi.innerHTML = lang.mdp_Mkr6 ? lang.mdp_Mkr6 : 'Edition';
      var rate = document.createElement('th');
      rate.innerHTML = lang.mdp_Mkr11 ? lang.mdp_Mkr11 : 'Your Rating';
      var link = document.createElement('th');
      link.innerHTML = lang.mdp_Mkr13 ? lang.mdp_Mkr13 : 'View';
      thead.appendChild(name);
      thead.appendChild(description);
      if (resourceType === 'Books' || resourceType === 'Articles') {
        thead.appendChild(author);
        if (resourceType === 'Books') {
          thead.appendChild(pub);
          thead.appendChild(rate);
          thead.appendChild(edi);
        }
        if (resourceType === 'Articles') {
          thead.appendChild(ref);
        }
      }

      if (resourceType !== 'Books') {
        thead.appendChild(rate);
        thead.appendChild(link);
      }
      table.appendChild(thead);

      const star = `<svg class="ratingStar" xmlns="http://www.w3.org/2000/svg" width="30.11" height="28.71" viewBox="0 0 30.11 28.71"><g id="Group_1116" data-name="Group 1116"><path id="Path_1075" data-name="Path 1075" d="M22.87,28.7a2.35,2.35,0,0,1-1.26-.37L15,24.15,8.49,28.33a2.37,2.37,0,0,1-3.57-2.58l2-7.52-6-5A2.36,2.36,0,0,1,2.25,9.09L10,8.62l2.88-7.25A2.47,2.47,0,0,1,16.16.25a2.43,2.43,0,0,1,1.12,1.12l2.83,7.26,7.76.49a2.36,2.36,0,0,1,2.24,2.49,2.39,2.39,0,0,1-.86,1.7l-6,4.94,0,0v.06l1.94,7.41a2.35,2.35,0,0,1-.9,2.51,2.22,2.22,0,0,1-1.38.45M15,21.82a2.14,2.14,0,0,1,1.29.4l6.52,4.15L21,18.85a2.39,2.39,0,0,1,.84-2.4l6-4.94L20,11A2.38,2.38,0,0,1,18,9.48L15.11,2.22,12.19,9.44a2.41,2.41,0,0,1-2.06,1.5l-7.75.47,6,5a2.39,2.39,0,0,1,.79,2.41l-2,7.52,6.59-4.13A2.31,2.31,0,0,1,15,21.82m7.47-4.46Z" transform="translate(0 0)"/></g></svg>`;
      const starFull = `<svg class="ratingStar" xmlns="http://www.w3.org/2000/svg" width="30.09" height="28.7" viewBox="0 0 30.09 28.7"><g id="Group_1114" data-name="Group 1114"><path id="Path_1074" data-name="Path 1074" d="M23.12,17.67l6.48-5.35a1.3,1.3,0,0,0,.2-1.83,1.34,1.34,0,0,0-1-.48l-8.35-.5a1.3,1.3,0,0,1-1.14-.83L16.28.83a1.32,1.32,0,0,0-1.7-.74,1.28,1.28,0,0,0-.74.74L10.77,8.65a1.31,1.31,0,0,1-1.14.82L1.25,10A1.3,1.3,0,0,0,0,11.32a1.28,1.28,0,0,0,.49,1L7,17.6a1.37,1.37,0,0,1,.44,1.33L5.29,27.07a1.3,1.3,0,0,0,.93,1.59,1.32,1.32,0,0,0,1-.17L14.32,24a1.22,1.22,0,0,1,1.39,0l7.09,4.52a1.3,1.3,0,0,0,1.8-.38,1.32,1.32,0,0,0,.17-1L22.64,19A1.3,1.3,0,0,1,23.12,17.67Z" transform="translate(0 0)"/></g></svg>`;

      for (let i = 0; i < assetArray.length; i++) {
        const row = assetArray[i];

        // Build Star Revied TD
        const starReview = divAtt('td', 'class', '');
        starReview.style = `display: flex; flex-direction: row; margin: auto; flex-wrap: wrap; justify-content: center;`;

        const starOneLabel = divAtt('label', 'class', '');
        const starOneImg = divAtt('div', 'id', `starOneImg_${i}`);
        starOneImg.innerHTML = star;
        const starOneRadio = divAtt('input', 'type', 'radio');
        starOneRadio.setAttribute('id', `starOneRadio_${i}`);
        // starOneRadio.setAttribute(
        //   "onclick",
        //   "myDevelopment.starRating(this.id, 1)"
        // );
        starOneRadio.setAttribute('style', 'position: absolute; opacity: 0; width: 0; height: 0;');
        starOneRadio.setAttribute('value', `allocHuserIDPAssetID`);
        starOneLabel.appendChild(starOneRadio);
        starOneLabel.appendChild(starOneImg);

        const starTwoLabel = divAtt('label', 'class', '');
        const starTwoImg = divAtt('div', 'id', `starTwoImg_${i}`);
        starTwoImg.innerHTML = star;
        const starTwoRadio = divAtt('input', 'type', 'radio');
        starTwoRadio.setAttribute('id', `starTwoRadio_${i}`);
        // starTwoRadio.setAttribute(
        //   "onclick",
        //   "myDevelopment.starRating(this.id, 2)"
        // );
        starTwoRadio.setAttribute('style', 'position: absolute; opacity: 0; width: 0; height: 0;');
        starTwoRadio.setAttribute('value', `allocHuserIDPAssetID`);
        starTwoLabel.appendChild(starTwoRadio);
        starTwoLabel.appendChild(starTwoImg);

        const starThreeLabel = divAtt('label', 'class', '');
        const starThreeImg = divAtt('div', 'id', `starThreeImg_${i}`);
        starThreeImg.innerHTML = star;
        const starThreeRadio = divAtt('input', 'type', 'radio');
        starThreeRadio.setAttribute('id', `starThreeRadio_${i}`);
        // starThreeRadio.setAttribute(
        //   "onclick",
        //   "myDevelopment.starRating(this.id, 3)"
        // );
        starThreeRadio.setAttribute('style', 'position: absolute; opacity: 0; width: 0; height: 0;');
        starThreeRadio.setAttribute('value', `allocHuserIDPAssetID`);
        starThreeLabel.appendChild(starThreeRadio);
        starThreeLabel.appendChild(starThreeImg);

        const starFourLabel = divAtt('label', 'class', '');
        const starFourImg = divAtt('div', 'id', `starFourImg_${i}`);
        starFourImg.innerHTML = star;
        const starFourRadio = divAtt('input', 'type', 'radio');
        starFourRadio.setAttribute('id', `starFourRadio_${i}`);
        // starFourRadio.setAttribute(
        //   "onclick",
        //   "myDevelopment.starRating(this.id, 4)"
        // );
        starFourRadio.setAttribute('style', 'position: absolute; opacity: 0; width: 0; height: 0;');
        starFourRadio.setAttribute('value', `allocHuserIDPAssetID`);
        starFourLabel.appendChild(starFourRadio);
        starFourLabel.appendChild(starFourImg);

        const starFiveLabel = divAtt('label', 'class', '');
        const starFiveImg = divAtt('div', 'id', `starFiveImg_${i}`);
        starFiveImg.innerHTML = star;
        const starFiveRadio = divAtt('input', 'type', 'radio');
        starFiveRadio.setAttribute('id', `starFiveRadio_${i}`);
        // starFiveRadio.setAttribute(
        //   "onclick",
        //   "myDevelopment.starRating(this.id, 5)"
        // );
        starFiveRadio.setAttribute('style', 'position: absolute; opacity: 0; width: 0; height: 0;');
        starFiveRadio.setAttribute('value', `allocHuserIDPAssetID`);
        starFiveLabel.appendChild(starFiveRadio);
        starFiveLabel.appendChild(starFiveImg);

        starReview.appendChild(starOneLabel);
        starReview.appendChild(starTwoLabel);
        starReview.appendChild(starThreeLabel);
        starReview.appendChild(starFourLabel);
        starReview.appendChild(starFiveLabel);

        const starArr = [starOneImg, starTwoImg, starThreeImg, starFourImg, starFiveImg];
        if (row.starRating !== undefined) {
          for (let j = 0; j < row.starRating; j++) {
            starArr[j].innerHTML = starFull;
          }
        }
        starOneRadio.setAttribute('value', `${row.allocHuserIDPAssetID}`);
        starTwoRadio.setAttribute('value', `${row.allocHuserIDPAssetID}`);
        starThreeRadio.setAttribute('value', `${row.allocHuserIDPAssetID}`);
        starFourRadio.setAttribute('value', `${row.allocHuserIDPAssetID}`);
        starFiveRadio.setAttribute('value', `${row.allocHuserIDPAssetID}`);

        var tr = document.createElement('tr');
        var name = document.createElement('td');
        name.innerHTML = row.shortName;
        var description = document.createElement('td');
        description.innerHTML = row.description;
        var author = document.createElement('td');
        author.innerHTML = row.author;
        var ref = document.createElement('td');
        ref.innerHTML = row.referenceOne;
        var pub = document.createElement('td');
        pub.innerHTML = row.publishedBy;
        var edi = document.createElement('td');
        edi.innerHTML = row.edition;
        var link = document.createElement('td');
        var btn = divAtt('a', 'class', 'btn');
        btn.setAttribute('id', 'hrefBtn');
        if (resourceType === 'eAcademyCourse') {
          // btn = addAtt(btn, "onclick", 'checkFeedback("Online Course")');
        } else {
          btn = addAtt(btn, 'href', row.assetURL);
          btn = addAtt(btn, 'target', '_blank');
        }
        btn.innerHTML = lang.mdp_Mkr13 ? lang.mdp_Mkr13 : 'View';
        link.appendChild(btn);
        tr.appendChild(name);
        tr.appendChild(description);
        if (resourceType === 'Books' || resourceType === 'Articles') {
          tr.appendChild(author);
          if (resourceType === 'Books') {
            tr.appendChild(pub);
            tr.appendChild(starReview);
            tr.appendChild(edi);
          }
          if (resourceType === 'Articles') {
            tr.appendChild(ref);
          }
        }
        if (resourceType !== 'Books') {
          tr.appendChild(starReview);
          tr.appendChild(link);
        }
        table.appendChild(tr);
        //test
        eListen(
          `starOneRadio_${i}`,
          () => {
            myDevelopment.starRating(`starOneRadio_${i}`, 1);
          },
          'click'
        );
        eListen(
          `starTwoRadio_${i}`,
          () => {
            myDevelopment.starRating(`starTwoRadio_${i}`, 2);
          },
          'click'
        );
        eListen(
          `starThreeRadio_${i}`,
          () => {
            myDevelopment.starRating(`starThreeRadio_${i}`, 3);
          },
          'click'
        );
        eListen(
          `starFourRadio_${i}`,
          () => {
            myDevelopment.starRating(`starFourRadio_${i}`, 4);
          },
          'click'
        );
        eListen(
          `starFiveRadio_${i}`,
          () => {
            myDevelopment.starRating(`starFiveRadio_${i}`, 5);
          },
          'click'
        );
        if (resourceType === 'eAcademyCourse') {
          eListen(
            'hrefBtn',
            () => {
              checkFeedback('Online Course');
            },
            'click'
          );
        }
      }
    });
  },
  starRating: (elID, rating) => {
    const allocHuserIDPAssetID = id(elID);

    const vars = {};
    vars.allocHuserIDPAssetID = allocHuserIDPAssetID.value;
    vars.starRating = rating;
    PREP(['ratingIDPAsset'], vars).then((res) => {
      if (res.data.ratingIDPAsset !== 'IDP asset rating recorded.') {
        errBox(res.data.ratingIDPAsset);
        return;
      } else {
        const star = `<svg class="ratingStar" xmlns="http://www.w3.org/2000/svg" width="30.11" height="28.71" viewBox="0 0 30.11 28.71"><g id="Group_1116" data-name="Group 1116"><path id="Path_1075" data-name="Path 1075" d="M22.87,28.7a2.35,2.35,0,0,1-1.26-.37L15,24.15,8.49,28.33a2.37,2.37,0,0,1-3.57-2.58l2-7.52-6-5A2.36,2.36,0,0,1,2.25,9.09L10,8.62l2.88-7.25A2.47,2.47,0,0,1,16.16.25a2.43,2.43,0,0,1,1.12,1.12l2.83,7.26,7.76.49a2.36,2.36,0,0,1,2.24,2.49,2.39,2.39,0,0,1-.86,1.7l-6,4.94,0,0v.06l1.94,7.41a2.35,2.35,0,0,1-.9,2.51,2.22,2.22,0,0,1-1.38.45M15,21.82a2.14,2.14,0,0,1,1.29.4l6.52,4.15L21,18.85a2.39,2.39,0,0,1,.84-2.4l6-4.94L20,11A2.38,2.38,0,0,1,18,9.48L15.11,2.22,12.19,9.44a2.41,2.41,0,0,1-2.06,1.5l-7.75.47,6,5a2.39,2.39,0,0,1,.79,2.41l-2,7.52,6.59-4.13A2.31,2.31,0,0,1,15,21.82m7.47-4.46Z" transform="translate(0 0)"/></g></svg>`;
        const starFull = `<svg class="ratingStar" xmlns="http://www.w3.org/2000/svg" width="30.09" height="28.7" viewBox="0 0 30.09 28.7"><g id="Group_1114" data-name="Group 1114"><path id="Path_1074" data-name="Path 1074" d="M23.12,17.67l6.48-5.35a1.3,1.3,0,0,0,.2-1.83,1.34,1.34,0,0,0-1-.48l-8.35-.5a1.3,1.3,0,0,1-1.14-.83L16.28.83a1.32,1.32,0,0,0-1.7-.74,1.28,1.28,0,0,0-.74.74L10.77,8.65a1.31,1.31,0,0,1-1.14.82L1.25,10A1.3,1.3,0,0,0,0,11.32a1.28,1.28,0,0,0,.49,1L7,17.6a1.37,1.37,0,0,1,.44,1.33L5.29,27.07a1.3,1.3,0,0,0,.93,1.59,1.32,1.32,0,0,0,1-.17L14.32,24a1.22,1.22,0,0,1,1.39,0l7.09,4.52a1.3,1.3,0,0,0,1.8-.38,1.32,1.32,0,0,0,.17-1L22.64,19A1.3,1.3,0,0,1,23.12,17.67Z" transform="translate(0 0)"/></g></svg>`;

        let newArr = [];
        newArr.push(elID.split('_'));

        console.log('newArr', newArr);
        const idArray = [`starOneImg_${newArr[0][1]}`, `starTwoImg_${newArr[0][1]}`, `starThreeImg_${newArr[0][1]}`, `starFourImg_${newArr[0][1]}`, `starFiveImg_${newArr[0][1]}`];

        console.log('idArray', idArray);
        for (let i = 0; i < idArray.length; i++) {
          if (i < parseInt(rating)) {
            id(idArray[i]).innerHTML = starFull;
          } else {
            id(idArray[i]).innerHTML = star;
          }
        }
      }
    });
  },
};

export const eFeedback = {
  buildeFeedback: async (data) => {
    const courseData = data.getAllocatedHuserHcourse;
    const iframe = id('lmsContainer');
    iframe.src = LMSiFrameLink;
    loader(1);

    const message = {};
    message['creds'] = {
      UUID: `${getCookie('ewttgpvwwkf')}`,
      HuserID: `${getCookie('jwugtkf')}`,
      deviceUUID: `${getCookie('fgxkegwwkf')}`,
      languageID: `${getCookie('mbohvbhfje')}`,
      client: domainClient,
    };

    message['courses'] = {
      data: courseData,
    };

    iframe.addEventListener('load', function () {
      iframe.contentWindow.postMessage(JSON.stringify(message), '*');
      loader(0);
    });

    // if (!data) {
    //   //message no eFeedback
    // } else {
    // }

    // eFeedback.myCoursesPage(data)
    // hide('lmsContainer');

    //Additional Services ===
    const addServicesAlloc = data.getAdditionalServicesAllocated;
    if (addServicesAlloc === 'No application on record') {
      id('noAppMsg').innerHTML = addServicesAlloc;
      unhide('noAppMsg');
    } else {
      const data1 = cObj(addServicesAlloc);
      const filteredData = data1.filter((item) => item.SERVICENAMEENG === 'Candidate feedback');

      if (filteredData.length) {
        additionalService.allocatedServices(filteredData);
      } else {
        id('noAppMsg').innerHTML = 'No application on record';
        unhide('noAppMsg');
      }
    }
  },
  myCoursesPage(data) {
    data = cObj(data);
    console.log('courseList', data);

    const table = id('allocatedCourses');
    table.innerHTML = '';
    const thead = document.createElement('thead');

    const th1 = document.createElement('th');
    th1.innerHTML = 'Name';
    const th2 = document.createElement('th');
    th2.innerHTML = 'Description';
    const th3 = document.createElement('th');
    th3.innerHTML = 'Status';
    const th4 = document.createElement('th');
    th4.innerHTML = 'Classification';
    const th5 = document.createElement('th');
    th5.innerHTML = 'Action';
    thead.appendChild(th1);
    thead.appendChild(th2);
    thead.appendChild(th3);
    thead.appendChild(th4);
    thead.appendChild(th5);
    table.appendChild(thead);

    for (let row of data) {
      const tr = document.createElement('tr');
      const td1 = document.createElement('td');
      td1.innerHTML = row.COURSENAME;
      const td2 = document.createElement('td');
      td2.innerHTML = row.COURSEDESCRIPTION;
      const td3 = document.createElement('td');
      td3.innerHTML = row.STATUSTEXT;
      const td4 = document.createElement('td');
      td4.innerHTML = row.CLASSIFICATION1TEXT;
      const td5 = document.createElement('td');
      const btn = divAtt('button', 'class', 'btn2');
      btn.innerHTML = 'Start';
      btn.setAttribute('onclick', `PREP("attendCourse", "HCOURSEUUID=${row.HCOURSEUUID}")`);
      td5.appendChild(btn);

      tr.appendChild(td1);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tr.appendChild(td4);
      tr.appendChild(td5);
      table.appendChild(tr);
    }
  },
  openVid: (vid) => {
    unhide('myForm');

    if (vid === 1) {
      id('video1').src = 'https://lms.integratezone.com/scratch/sample1.mp4';
    } else if (vid === 2) {
      id('video1').src = 'https://integratezone.com/demoAA/demoeFeedback1.mp4';
    }

    id('video1').play();
  },
  closeVid: () => {
    id('video1').pause();
    hide('myForm');
  },
};

// ===== NOMINATIONS =====

export class UserNominations {
  constructor(assID) {
    this.name = 'My Nominations';
    this.pageTier = 10;
    this.reference = 'userNominations';
    this.f_allocHuserHassessmentID = assID;
    this.pageID = null;
  }

  initPage() {
    this.loadPage();
  }

  loadPage() {
    changeTabN('UNt_nominations');
  }
}

export class UNtguide extends UserNominations {
  constructor(f_allocHuserHassessmentID) {
    super(f_allocHuserHassessmentID);
    this.name = 'Nomination Guide';
    this.pageTier = 15;
    this.reference = 'UNt_guide';
  }

  initPage() {
    this.loadPage();
  }

  loadPage() {
    // console.log('load the dragons')
  }
}

export class UNthistory extends UserNominations {
  constructor(f_allocHuserHassessmentID) {
    super(f_allocHuserHassessmentID);
    this.name = 'Nomination History';
    this.pageTier = 15;
    this.reference = 'UNt_history';
    this.pageID = null;
  }

  initPage() {
    this.loadPage();
  }

  loadPage() {
    // console.log('load the dragons')
  }
}

export class UNt_nominations extends UserNominations {
  // Generic Functions

  constructor(f_allocHuserHassessmentID) {
    // Inherited variables
    super(f_allocHuserHassessmentID);
    // Generic Variables
    this.name = 'Nominations';
    this.pageTier = 15;
    this.reference = 'UNt_nominations';
    // Page pecific variables
    this.noms = {};
    this.ApprovedYN = null;
    this.NominationType = null;
    this.SubmittedYN = null;
    this.chooseRulesCombo = null;
    this.nominateRulesCombo = null;
    this.nominationGuide = null;
    this.minimumNomineeWarning = null;
    this.rateeName = null;
    this.rateeProfileImage = null;
    this.relaxMinRule = null;
    this.surveyName = null;
    this.restrictUserListArray = [];
    this.relationshipObject = [];
    this.nomQty = {};
    this.qtyMax = null;
    this.qtyMin = null;
    this.completed = 0;
    this.history = null;
    this.prevCompleted = 0;
  }

  initPage(data) {
    // Set page pecific variables
    this.ApprovedYN = data.ApprovedYN;
    this.NominationType = data.NominationType;
    this.SubmittedYN = data.SubmittedYN;
    this.chooseRulesCombo = data.chooseRulesCombo;
    this.minimumNomineeWarning = data.minimumNomineeWarning;
    this.chooseRulesCombo = data.chooseRulesCombo;
    this.nominationGuide = data.nominationGuide;
    this.rateeName = data.rateeName;
    this.rateeProfileImage = data.rateeProfileImage;
    this.surveyName = data.surveyName;
    this.relaxMinRule = data.relaxMinRule;
    this.restrictUserListArray = data.restrictUserListArray;
    this.relationshipObject = data.rules[0].relationshipObject;
    this.history = data.history;
    this.nominateRulesCombo = data.nominateRulesCombo;
    if (data.NominationType === 'Prescribed') {
      for (let item of data.rules) {
        const nomObj = {};
        nomObj.qtyMin = item.qtyMin;
        nomObj.qtyMax = item.qtyMax;
        nomObj.relID = item.relationshipObject[0].DDRATERRELATIONSHIPID;
        this.nomQty[item.relationshipObject[0].DESCRIPTION] = nomObj;
      }
    } else {
      const nomObj = {};
      nomObj.qtyMin = data.rules[0].qtyMin;
      nomObj.qtyMax = data.rules[0].qtyMax;
      this.nomQty = nomObj;
    }
    if (data.history.length) {
      this.prevCompleted = data.history[0].historyRows.length;
    }
    this.appPending = data.ApprovedYN || data.SubmittedYN ? 1 : 0;
    this.loadPage();
  }

  loadPage() {
    this.buildNominations();
  }

  // Page pecific functions

  selectNom(nomID, replaceYN) {
    const ths = pgstr.UNt_nominations;

    const div = divAtt('div', 'class', '');
    const p = divAtt('p', 'class', 'marginBottom');
    if (ths.NominationType !== 'Prescribed') {
      p.innerHTML = 'Select a relationship and add a nominee.';
    } else {
      p.innerHTML = 'Select a name from the drop down or toggle New Nominee to fill in new nomination details.';
    }

    // Toggle new/existing Nominee
    const tgglDiv = divAtt('div', 'class', 'nomTggl');
    tgglDiv.id = 'nomTggl';
    const tgglLabel = divAtt('label', 'class', 'switch');
    const tggleInput = divAtt('input', 'type', 'checkbox');
    tggleInput.setAttribute('id', 'tgglNewExistingNom');
    tggleInput.checked = false;
    // tggleInput.setAttribute("onclick", "pgstr.UNt_nominations.tgglNewExistingNom()");
    // tggleInput.setAttribute(
    //   "onclick",
    //   "exPageMethod('UNt_nominations','tgglNewExistingNom')"
    // );//mikey
    const tgglSpan = divAtt('span', 'class', 'tggl round');
    const tgglName = divAtt('label', 'for', 'tgglNewExistingNom');
    tgglName.setAttribute('class', 'tgglLabel');
    tgglName.innerHTML = `New Nominee`;

    tgglLabel.appendChild(tggleInput);
    tgglLabel.appendChild(tgglSpan);
    tgglDiv.appendChild(tgglLabel);
    tgglDiv.appendChild(tgglName);

    // RELATIONSHIP DIV
    const relDiv = divAtt('div', 'class', 'relSelect marginTop');
    const relDDdiv = divAtt('div', 'class', 'group');
    const relBar = divAtt('span', 'class', 'bar');
    const relValFeedback = divAtt('p', 'class', 'inpFB hide');
    const relLabel = divAtt('label', 'for', 'name');
    relLabel.innerHTML = 'Relationship';
    let relChoice;
    if (ths.NominationType !== 'Prescribed') {
      relChoice = divAtt('select', 'id', 'relSelect');
      relChoice.setAttribute('select', 'onchange', 'notEmptyVal(this.id)');
      relValFeedback.setAttribute('id', 'relSelect_FB');
      const optOne = divAtt('option', 'value', '-');
      optOne.innerHTML = 'Please choose an option ---';
      relChoice.appendChild(optOne);
      for (let row of ths.relationshipObject) {
        const opt = divAtt('option', 'value', row.DDRATERRELATIONSHIPID);
        opt.innerHTML = row.DESCRIPTION;
        relChoice.appendChild(opt);
      }

      relDDdiv.appendChild(relChoice);
      relDDdiv.appendChild(relBar);
      relDDdiv.appendChild(relValFeedback);
      relDDdiv.appendChild(relLabel);
      relDiv.appendChild(relDDdiv);
    }

    // NOMINEE DROPDOWN
    const nomineesDiv = divAtt('div', 'class', 'grid-2col-input-custard marginBottom marginTop');
    nomineesDiv.setAttribute('id', 'existingNoms');
    const nomineeDDdiv = divAtt('div', 'class', 'group');
    const nomineeSelect = divAtt('select', 'onchange', 'notEmptyVal(this.id)');
    nomineeSelect.setAttribute('id', 'nomineeList');

    if (ths.nominateRulesCombo) {
      const optOne = divAtt('option', 'value', '-');
      optOne.innerHTML = 'Please choose an option ---';
      nomineeSelect.appendChild(optOne);
      for (let row of ths.restrictUserListArray) {
        const opt = divAtt('option', 'value', row.HuserID);
        opt.innerHTML = row.DisplayName;
        nomineeSelect.appendChild(opt);
      }
    }
    const nomBar = divAtt('span', 'class', 'bar');
    const nomValFeedback = divAtt('p', 'class', 'inpFB hide');
    // @ TIM give nomValFeedback same ID name as select but with _FB on end
    nomValFeedback.setAttribute('id', `${nomID}_FB`);
    const nomLabel = divAtt('label', 'for', 'name');
    nomLabel.innerHTML = 'Select Nominee';
    nomineeDDdiv.appendChild(nomineeSelect);
    nomineeDDdiv.appendChild(nomBar);
    nomineeDDdiv.appendChild(nomValFeedback);
    nomineeDDdiv.appendChild(nomLabel);
    nomineesDiv.appendChild(nomineeDDdiv);

    const btnClosedDiv = divAtt('div', 'class', 'btnCenterGrid');
    const btnClosed = divAtt('button', 'class', 'btn ');
    btnClosed.innerHTML = 'Add Nomination';
    btnClosedDiv.appendChild(btnClosed);
    nomineesDiv.appendChild(btnClosedDiv);
    // ADD NEW NOMINEE TO UNHIDE ON SELECT NEW
    const newNomDiv = divAtt('div', 'class', 'grid-2col-input-custard marginBottom marginTop');
    newNomDiv.setAttribute('id', 'newNom');
    // Name
    const nameDiv = buildInpText('fname', 1, 'Name');
    // Surname
    const surnameDiv = buildInpText('sname', 1, 'Surname');
    // Email
    const emailDiv = buildInpEmail('email', 1);

    // Numuber
    const numDiv = buildInpMobNum('cellNum', 1);
    newNomDiv.appendChild(nameDiv);
    newNomDiv.appendChild(surnameDiv);
    newNomDiv.appendChild(emailDiv);
    newNomDiv.appendChild(numDiv);

    const btnOpenDiv = buildBtnFormCenter('btnOpenNom', `UNt_nominations.addNomineeOpen('${nomID}')`, 'Add New Nomination');
    newNomDiv.appendChild(btnOpenDiv);

    if (!ths.nominateRulesCombo) {
      nomineesDiv.classList.add('hide');
      tgglDiv.classList.add('hide');
    } else {
      newNomDiv.classList.add('hide');
    }

    div.append(p);
    div.appendChild(tgglDiv);
    div.appendChild(relDiv);
    div.append(nomineesDiv);
    div.appendChild(newNomDiv);
    if (ths.NominationType === 'Prescribed') {
      eCustardBox(div, `Select Nomination with Relationship to ${ths.noms[nomID].relation}`, [
        [
          btnClosed,
          () => {
            UNt_nominations.addNomineeClosed(`${nomID}`, replaceYN ? 1 : 0);
            // exPageMethod('UNt_nominations', 'addNomineeClosed', `${nomID}`);
          },
          'click',
        ],
        [
          btnOpenDiv,
          () => {
            UNt_nominations.addNomineeOpen(`${nomID}`, replaceYN ? 1 : 0);
            // UNt_nominations.addNomineeOpen(`${nomID}`);
          },
          'click',
        ],
        [
          tggleInput,
          () => {
            UNt_nominations.tgglNewExistingNom();
            // exPageMethod('UNt_nominations', 'tgglNewExistingNom');
          },
          'click',
        ],
      ]);
    } else {
      // custardBox(div, `Select Nomination`);
      eCustardBox(div, `Select Nomination`, [
        [
          btnClosed,
          () => {
            UNt_nominations.addNomineeClosed(`${nomID}`, replaceYN ? 1 : 0);
            // exPageMethod('UNt_nominations', 'addNomineeClosed', `${nomID}`);
          },
          'click',
        ],
        [
          btnOpenDiv,
          () => {
            UNt_nominations.addNomineeOpen(`${nomID}`, replaceYN ? 1 : 0);
          },
          'click',
        ],
        [
          tggleInput,
          () => {
            UNt_nominations.tgglNewExistingNom();
            // exPageMethod('UNt_nominations', 'tgglNewExistingNom');
          },
          'click',
        ],
      ]);
    }

    ccRegex(0, 0);
    // id('cellNum').style = 'margin-bottom: 0;'
  }

  removeNom(nomID) {
    const vars = {};
    vars.nominationUUID = nomID;
    PREP(['removeRaterTwo'], vars)
      .then((res) => {
        console.log('res: ', res);
        changeTabN('UNt_nominations');
      })
      .catch(console.error());
  }

  static replaceNom(vars) {
    PREP(['replaceRater'], vars)
      .then((res) => {
        console.log('res: ', res);
        hide('custardBox');
        changeTabN('UNt_nominations');
      })
      .catch(console.error());
  }

  static addNomineeOpen(nomID, replaceYN) {
    const ths = pgstr.UNt_nominations;

    const fname = id('fname').value;
    const sname = id('sname').value;
    const email = id('email').value;
    const cellNum = id('cellNum').value;

    const varArr = [fname, sname, email, cellNum];

    for (let item of varArr) {
      if (!item) {
        return infoBox('Please fill in all fields.');
      }
    }

    const nom = ths.noms[nomID];
    nom.firstName = fname;
    nom.surname = sname;
    nom.fullName = `${fname} ${sname}`;
    nom.emailAddress = email;
    nom.mobileNumber = cellNum;
    nom.status = 1;
    //  Previous vars
    nom.nominationUUID = nomID;
    nom.nominateComments = '';
    nom.syncedYN = 0;
    nom.batchID = 0;
    nom.AHHpreuuid = nomID;
    nom.batchStatusID = 4;
    nom.ratee = getCookie('jwugtkf');
    nom.HuserID = getCookie('jwugtkf');
    nom.UUID = getCookie('ewttgpvwwkf');
    nom.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;
    // extras
    if (id('relSelect')) {
      const sel = id('relSelect');
      if (sel.value === '-') {
        return infoBox('Please select a relationship from the dropdown.');
      }
      nom.raterRelationship = sel.value;
      nom.relation = sel.options[sel.selectedIndex].text;
    }

    const vars = {};
    for (let item in nom) {
      vars[item] = nom[item];
    }

    if (replaceYN) {
      this.replaceNom(vars);
      return;
    }

    PREP(['addraterthree'], vars)
      .then(() => {
        ths.updateCompleted();
        changeTabN('UNt_nominations');
        hide('custardBox');
      })
      .catch(console.error());
  }

  static addNomineeClosed(nomID, replaceYN) {
    const ths = pgstr.UNt_nominations;

    const HuserID = id('nomineeList').value;

    if (HuserID === '-') {
      return infoBox('Please select an option.');
    }
    const nom = pgstr.UNt_nominations.noms[nomID];

    for (let item of ths.restrictUserListArray) {
      if (HuserID == item.HuserID) {
        nom.fullName = item.fullName;
        nom.email = item.emailaddress;
        nom.status = 1;
        //  Previous vars
        nom.nominationUUID = nomID;
        nom.nominateComments = '';
        nom.syncedYN = 0;
        nom.batchID = 0;
        nom.AHHpreuuid = nomID;
        nom.UUID= getCookie('ewttgpvwwkf');
        nom.batchStatusID = 4;
        nom.HuserID = getCookie('jwugtkf');
        nom.ratee = HuserID;
        nom.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;
        // extras
        if (id('relSelect')) {
          const sel = id('relSelect');
          if (sel.value === '-') {
            return infoBox('Please select a relationship from the dropdown.');
          }
          nom.raterRelationship = sel.value;
          nom.relation = sel.options[sel.selectedIndex].text;
        }

        const vars = {};
        for (let row in nom) {
          vars[row] = nom[row];
        }

        if (replaceYN) {
          this.replaceNom(vars);
          return;
        }

        PREP(['addraterthree'], vars)
          .then(() => {
            ths.updateCompleted();
            changeTabN('UNt_nominations');
            hide('custardBox');
          })
          .catch(console.error());

        break;
      }
    }
  }

  checkForSubmit() {
    const ths = pgstr.UNt_nominations;
    const noms = ths.noms;
    const compObj = {};
    if (ths.NominationType === 'Open') {
      for (let nom in noms) {
        if (noms[nom].status) {
          if (!compObj.completed) {
            compObj.completed = 1;
          } else {
            compObj.completed++;
          }
        }
      }
      const minMet = compObj.completed >= ths.nomQty.qtyMin;
      const maxMet = ths.nomQty.qtyMax === compObj.completed;

      ths.checkSubRes(minMet, maxMet);
    } else if (ths.NominationType === 'Prescribed') {
      for (let nom in noms) {
        if (noms[nom].status) {
          const currNomRelID = noms[nom].raterRelationship;
          if (!compObj[currNomRelID]) {
            compObj[currNomRelID] = 1;
          } else {
            compObj[currNomRelID]++;
          }
        }
      }
      const nomQty = ths.nomQty;
      let minMet = true;
      let maxMet = true;
      for (let rel in nomQty) {
        const currRelID = nomQty[rel].relID;
        if (!compObj[currRelID]) {
          minMet = false;
        } else if (compObj[currRelID] < nomQty[rel].qtyMin) {
          minMet = false;
        } else if (compObj[currRelID] !== nomQty[rel].qtyMax) {
          maxMet = false;
        }
      }
      ths.checkSubRes(minMet, maxMet);
    }
  }

  static submitForApproval(warningAccepted) {
    const ths = pgstr.UNt_nominations;

    hide('custardBox');

    const vars = {};
    if (warningAccepted) {
      vars.warningAccepted = parseInt(warningAccepted);
    } else {
      vars.warningAccepted = 0;
    }
    vars.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;

    PREP(['submitRatersForApproval'], vars)
      .then((res) => {
        note(res.data.submitRatersForApproval);
        changeTabN('UNt_nominations');
      })
      .catch(console.error());
  }

  static tgglNewExistingNom() {
    id('newNom').classList.toggle('hide');
    id('existingNoms').classList.toggle('hide');
  }

  async buildNominations() {
    const blankNomTemplates = id('nomSections');
    blankNomTemplates.innerHTML = '';
    let dynamicEvents = [];
    let dynamicNoms = [];
    if (!Object.keys(this.noms).length) {
      let histObj = {};
      if (this.history.length) {
        // Build history record
        for (let histItm of this.history[0].historyRows) {
          if (!histObj[histItm.raterRelationshipID]) {
            histObj[histItm.raterRelationshipID] = 1;
          } else {
            histObj[histItm.raterRelationshipID]++;
          }
        }
      }

      if (this.NominationType === 'Prescribed') {
        for (let item in this.nomQty) {
          const currItem = this.nomQty[item];

          const nomSection = divAtt('div', 'class', 'nomCards');
          nomSection.setAttribute('id', this.NominationType);
          //console.log("ID",this.NominationType);
          const divHdr = this.buildNomHeader(currItem.qtyMin, currItem.qtyMax, item, histObj[currItem.relID] || 0);
          nomSection.appendChild(divHdr);

          if (histObj.hasOwnProperty(currItem.relID)) {
            const max = currItem.qtyMax - histObj[currItem.relID];
            for (let histRow of this.history[0].historyRows) {
              if (histRow.raterRelationshipID === currItem.relID) {
                const nomID = histRow.UUID ? histRow.UUID : uuidvMain();
                let batch2Obj = null;
                this.history[1] && (await (batch2Obj = this.history[1].historyRows));
                let nominee = null;
                batch2Obj &&
                  (batch2Obj = await (nominee = batch2Obj
                    .slice()
                    .reverse()
                    .find((nominee) => nominee.UUID === nomID)));
                if (!nominee) {
                  this.noms[nomID] = new Nominee(nomID, histRow.raterRelationship, histRow.raterRelationshipID);
                  await this.noms[nomID].addOldNom(histRow);
                } else {
                  this.noms[nomID] = new Nominee(nomID, nominee.raterRelationship, nominee.raterRelationshipID);
                  await this.noms[nomID].addOldNom(nominee);
                }

                const [blankTemplate, dEvents] = this.buildCard(nomID);
                dynamicEvents = dynamicEvents.concat(dEvents);
                nomSection.appendChild(blankTemplate);
              }
            }
            for (let j = 1; j <= max; j++) {
              const [createdNom, nomEvts] = this.createNom(item, currItem.relID);
              nomSection.appendChild(createdNom);
              dynamicNoms = dynamicNoms.concat(nomEvts);
            }
          } else {
            for (let j = 1; j <= currItem.qtyMax; j++) {
              const [createdNom, nomEvts] = this.createNom(item, currItem.relID);
              nomSection.appendChild(createdNom);
              dynamicNoms = dynamicNoms.concat(nomEvts);
            }
          }
          blankNomTemplates.appendChild(nomSection);
        }
      } else {
        const nomSection = divAtt('div', 'class', 'nomCards');
        nomSection.setAttribute('id', this.NominationType);

        const compNumber = this.history.length ? this.history[0].historyRows.length : 0;

        const divHdr = this.buildNomHeader(this.nomQty.qtyMin, this.nomQty.qtyMax, 'Open', compNumber);
        nomSection.appendChild(divHdr);

        let max = this.nomQty.qtyMax;
        if (this.history.length) {
          max = this.nomQty.qtyMax - this.history[0].historyRows.length;

          for (let histRow of this.history[0].historyRows) {
            const nomID = histRow.UUID ? histRow.UUID : uuidvMain();

            let batch2Obj = null;
            this.history[1] && (await (batch2Obj = this.history[1].historyRows));
            let nominee = null;
            batch2Obj &&
              (batch2Obj = await (nominee = batch2Obj
                .slice()
                .reverse()
                .find((nominee) => nominee.UUID === nomID)));
            if (!nominee) {
              this.noms[nomID] = new Nominee(nomID, histRow.raterRelationship, histRow.raterRelationshipID);
              await this.noms[nomID].addOldNom(histRow);
            } else {
              this.noms[nomID] = new Nominee(nomID, nominee.raterRelationship, nominee.raterRelationshipID);
              await this.noms[nomID].addOldNom(nominee);
            }
            // this.noms[nomID] = new Nominee(nomID, histRow.raterRelationship, histRow.raterRelationshipID);
            // await this.noms[nomID].addOldNom(histRow);
            const [blankTemplate, dEvents] = this.buildCard(nomID);
            dynamicEvents = dynamicEvents.concat(dEvents);
            nomSection.appendChild(blankTemplate);
          }
        }
        for (let i = 1; i <= max; i++) {
          const [createdNom, nomEvts] = this.createNom();
          nomSection.appendChild(createdNom);
          dynamicNoms = dynamicNoms.concat(nomEvts);
        }
        blankNomTemplates.appendChild(nomSection);
      }
    }

    const btnDiv = divAtt('div', 'class', 'sectionGap btnEndGrid');
    const btn = divAtt('button', 'class', 'btn');
    btn.innerHTML = `Submit ${this.NominationType} Nominations`;
    btnDiv.appendChild(btn);
    this.appPending && btn.setAttribute('disabled', 'true');
    blankNomTemplates.appendChild(btnDiv);
    eListen(
      btn,
      () => {
        exPageMethod('UNt_nominations', 'checkForSubmit');
      },
      'click'
    );
    for (let i = 0; i < dynamicEvents.length; i++) {
      //stephanie
      eListen(dynamicEvents[i][0], dynamicEvents[i][1], dynamicEvents[i][2]);
    }
    for (let i = 0; i < dynamicNoms.length; i++) {
      //stephanie
      eListen(dynamicNoms[i][0], dynamicNoms[i][1], dynamicNoms[i][2]);
    }
  }

  checkSubRes(minMet, maxMet) {
    if (!minMet && !this.relaxMinRule) {
      return infoBox('You have not met the minimum amount of Nominations to submit.');
    } else if (!minMet && this.relaxMinRule) {
      const div = divAtt('div');
      const p = divAtt('p', 'class', 'marginBottom');
      p.innerHTML = this.minimumNomineeWarning || 'Please note you have not nominated the minimum amount of raters required. Please refer to the nomination guide regarding the minimum amount of raters required per category.';
      const btnDiv = divAtt('div', 'class', 'gridSpan btnCenterGrid');
      const btn2 = divAtt('button', 'class', 'btnCancel');
      btn2.innerHTML = 'Cancel';
      const btn = divAtt('div', 'class', 'btn');
      btn.id = 'additionalBtn';
      // btn.setAttribute("onclick", "UNt_nominations.submitForApproval(1)");
      btn.innerHTML = 'Submit';
      btnDiv.appendChild(btn2);
      btnDiv.appendChild(btn);
      div.appendChild(p);
      div.appendChild(btnDiv);
      // custardBox(div, "Please Note");

      eCustardBox(div, 'Please Note', [
        [
          btn,
          () => {
            UNt_nominations.submitForApproval(1);
          },
          'click',
        ],
        [
          btn2,
          () => {
            hide('custardBox');
          },
          'click',
        ],
      ]);
    } else if (!maxMet) {
      const div = divAtt('div');
      const p = divAtt('p');
      p.innerHTML = 'You have nominated the minimum required number of raters, but can still nominate more. Are you sure you would like to proceed? Should you click submit now, the nominations will be closed and you will not be able to add additional raters.';
      const btnDiv = divAtt('div', 'class', 'btnCenterGrid');
      const btn = divAtt('div', 'class', 'btn btn_solid');
      btn.id = 'additionalBtn';
      // btn.setAttribute("onclick", "UNt_nominations.submitForApproval(1)");
      btn.innerHTML = 'Submit';
      btnDiv.appendChild(btn);
      div.appendChild(p);
      div.appendChild(btnDiv);
      // custardBox(div, "Please Note");
      eCustardBox(div, 'Please Note', [
        [
          btn,
          () => {
            UNt_nominations.submitForApproval(1);
          },
          'click',
        ],
      ]);
    } else {
      UNt_nominations.submitForApproval();
    }
  }

  createNom(item, relID) {
    const nomID = uuidvMain();
    this.noms[nomID] = new Nominee(nomID, item, relID);
    return this.buildCard(nomID);
  }

  updateCompleted() {
    const ths = pgstr.UNt_nominations;
    let statusCnt = 0;
    for (let nom in ths.noms) {
      ths.noms[nom].status && statusCnt++;
    }
    ths.completed = statusCnt;
  }

  buildNomHeader(minNom, maxNom, type, completed) {
    // Nom Headers
    const nomHeader = divAtt('div', 'class', 'gridSpan nomHeader');
    const nomType = document.createElement('h4');
    // set nom type
    nomType.innerHTML = `Nomination: ${type}`;
    // const explanation = document.createElement('p');
    // explanation.innerHTML = 'Explanation of nomination type.';
    const minMaxInnstructions = document.createElement('p');
    const min = divAtt('span', 'class', 'minRequired');
    min.innerHTML = `A minimum of ${minNom} is required `;
    const max = document.createElement('span');
    max.innerHTML = `with a maximum of ${maxNom}.`;
    minMaxInnstructions.appendChild(min);
    minMaxInnstructions.appendChild(max);
    const nomDone = document.createElement('h6');
    nomDone.innerHTML = `<span id="nomDone_${this.NominationType}">${completed}</span> of ${maxNom}`;
    nomHeader.appendChild(nomType);
    // nomHeader.appendChild(explanation);
    nomHeader.appendChild(minMaxInnstructions);
    nomHeader.appendChild(nomDone);

    // nomSection.appendChild(nomHeader)
    return nomHeader;
  }

  buildCard(nomID) {
    const nom = this.noms[nomID];
    // console.log("nom", nom);

    const divText = this.noms[nomID].relation ? this.noms[nomID].relation : 'Nomination';

    let nameText = 'name here';
    if (nom.fullName) {
      nameText = `${nom.fullName}`;
    } else if (nom.fname) {
      nameText = `${nom.fname} ${nom.sname}`;
    }
    const emailText = nom.emailAddress ? `${nom.emailAddress}` : 'email here';
    const classDiv = nom.status ? 'nomEmpty hide' : 'nomEmpty';
    // const cardAction = nom.status
    //   ? `pgstr.UNt_nominations.removeNom('${nomID}')`
    //   : `pgstr.UNt_nominations.selectNom('${nomID}')`;
    let str_nomID = '' + nomID;
    const cardAction = nom.status ? `exPageMethod('UNt_nominations','removeNom','${str_nomID}')` : `exPageMethod('UNt_nominations','selectNom','${str_nomID}')`;

    // CARD DIV

    const cardDiv = divAtt('div', 'class', 'nomCard cardCliclickable');
    let cardDivId = 'cardDiv' + str_nomID;
    cardDiv.id = cardDivId;
    const nomName = document.createElement('h6');
    nomName.innerHTML = nameText;
    const email = document.createElement('p');
    email.innerHTML = emailText;
    let dynamicEvents = [];
    cardDiv.appendChild(nomName);
    cardDiv.appendChild(email);
    if (nom.status) {
      const relationshipP = divAtt('p', 'class', 'cardRelTitle');
      relationshipP.innerHTML = 'Relationship: ';
      relationshipP.style = ` margin-top: 0.7rem;`;
      const spanRel = divAtt('span');
      spanRel.innerHTML = nom.relation;
      relationshipP.appendChild(spanRel);
      cardDiv.appendChild(relationshipP);
    }

    if (!this.appPending) {
      cardDiv.setAttribute('onclick', cardAction);
      // dynamicEvents.push([
      //   cardDivId,
      //   () => {
      //     cardAction;
      //   },
      //   "click",
      // ]);
      const removeCardDiv = divAtt('div', 'class', 'removeCard ');
      let removeCardDivId = 'removeCardDiv' + str_nomID;

      removeCardDiv.id = removeCardDivId;
      dynamicEvents.push([
        removeCardDivId,
        () => {
          this.removeNom(`${nomID}`);
        },
        'click',
      ]);

      removeCardDiv.innerHTML = ` <svg xmlns="http://www.w3.org/2000/svg" width="18.96" height="23.32" viewBox="0 0 18.96 23.32">
        <g id="Group_814" data-name="Group 814">
            <path id="Path_941" data-name="Path 941"
                d="M11.25,0c.15.05.29.08.43.14a2.17,2.17,0,0,1,1.44,2c0,.25,0,.5,0,.79H17A1.82,1.82,0,0,1,19,4.64V6.16a.71.71,0,0,1-.68.76H.78A.72.72,0,0,1,0,6.25V6.16a14.23,14.23,0,0,1,0-1.73A1.75,1.75,0,0,1,1.83,2.92h4V2.27A2.18,2.18,0,0,1,7.62,0L7.7,0Zm.41,2.91c0-.25,0-.49,0-.74A.71.71,0,0,0,11,1.46q-1.47,0-2.94,0A.67.67,0,0,0,7.33,2a5.41,5.41,0,0,0,0,.9Z"
                transform="translate(0)" />
            <path id="Path_942" data-name="Path 942"
                d="M9.48,8.38H17.4c.35,0,.4.06.39.41-.09,1.74-.17,3.47-.25,5.21-.12,2.44-.23,4.88-.36,7.32a2.13,2.13,0,0,1-2.13,2c-.88,0-1.76,0-2.64,0H4a2.14,2.14,0,0,1-2.2-1.79,9.73,9.73,0,0,1-.1-1.43l-.3-6.18L1.17,8.77c0-.32,0-.39.36-.39h8M5.11,14.93v4.73a.72.72,0,0,0,1.43.17,1.13,1.13,0,0,0,0-.32V10.2a.73.73,0,1,0-1.45,0s0,0,0,0v4.69m3.64,0v4.73a.73.73,0,0,0,1.44.17c0-.11,0-.21,0-.32V10.2a.73.73,0,0,0-.75-.71.73.73,0,0,0-.71.74h0v4.7m5.1,0V10.26a.74.74,0,0,0-.46-.74.73.73,0,0,0-1,.42.69.69,0,0,0,0,.32v9.38a.73.73,0,0,0,.69.76.73.73,0,0,0,.76-.7V14.92"
                transform="translate(0)" />
        </g></svg> <span>Remove</span>`;

      const addNomDiv = divAtt('div', 'class', classDiv);
      addNomDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="30.69" height="30.69" viewBox="0 0 30.69 30.69">
      <polygon
          points="30.69 13.6 17.1 13.6 17.1 0 13.6 0 13.6 13.6 0 13.6 0 17.1 13.6 17.1 13.6 30.69 17.1 30.69 17.1 17.1 30.69 17.1 30.69 13.6" />
      </svg>
      <h6>Add ${divText}</h6>`;

      cardDiv.appendChild(removeCardDiv);
      cardDiv.appendChild(addNomDiv);
    } else {
      const profilePic = divAtt('div', 'class', 'profileImgContainerCard');
      const ppImg = divAtt('img', 'alt', 'Profile Image');
      ppImg.src = nom.profileImage;
      profilePic.appendChild(ppImg);

      const assStatus = divAtt('p', 'class', 'cardRelTitle');
      assStatus.innerHTML = 'Assessment Status: ';
      const spanStatus = divAtt('span');
      spanStatus.innerHTML = nom.assessmentStatus;
      assStatus.appendChild(spanStatus);

      cardDiv.prepend(profilePic);
      cardDiv.appendChild(assStatus);

      const replaceCardDiv = divAtt('div', 'class', 'removeCard ');
      let replaceCardDivId = 'replaceCardDiv' + str_nomID;
      replaceCardDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg> <span>Replace</span>`;
      replaceCardDiv.id = replaceCardDivId;
      dynamicEvents.push([
        replaceCardDivId,
        () => {
          this.selectNom(`${nomID}`, 1);
        },
        'click',
      ]);
      cardDiv.appendChild(replaceCardDiv);
    }
    return [cardDiv, dynamicEvents];
  }
}

export class UserSelections {
  constructor(assID) {
    this.name = 'My Selections';
    this.pageTier = 10;
    this.reference = 'userSelections';
    this.f_allocHuserHassessmentID = assID;
    this.pageID = null;
  }

  initPage() {
    this.loadPage();
  }

  loadPage() {
    changeTabN('USt_selections');
  }
}

export class UStguide extends UserSelections {
  constructor(f_allocHuserHassessmentID) {
    super(f_allocHuserHassessmentID);
    this.name = 'Selection Guide';
    this.pageTier = 15;
    this.reference = 'USt_guide';
  }

  initPage() {
    this.loadPage();
  }

  loadPage() {
    // console.log('load the dragons')
  }
}

export class USt_selections extends UserSelections {
  // Generic Functions

  constructor(f_allocHuserHassessmentID) {
    // Inherited variables
    super(f_allocHuserHassessmentID);
    // Generic Variables
    this.name = 'Selections';
    this.pageTier = 15;
    this.reference = 'USt_selections';
    // Page pecific variables
    this.noms = {};
    this.ApprovedYN = null;
    this.NominationType = null;
    this.SubmittedYN = null;
    this.chooseRulesCombo = null;
    this.nominateRulesCombo = null;
    this.nominationGuide = null;
    this.minimumNomineeWarning = null;
    this.rateeName = null;
    this.rateeProfileImage = null;
    this.relaxMinRule = null;
    this.surveyName = null;
    this.restrictUserListArray = [];
    this.relationshipObject = [];
    this.nomQty = {};
    this.qtyMax = null;
    this.qtyMin = null;
    this.completed = 0;
    this.history = null;
    this.prevCompleted = 0;
  }

  initPage(rawData) {
    const data = rawData.getNominateRules[0];
    const chooseData = rawData.getChooseRateeRules[0];
    // Set page pecific variables
    this.ApprovedYN = data.ApprovedYN;
    this.NominationType = data.NominationType;
    this.SubmittedYN = data.SubmittedYN;
    this.chooseRulesCombo = data.chooseRulesCombo;
    this.minimumNomineeWarning = data.minimumNomineeWarning;
    this.chooseRulesCombo = data.chooseRulesCombo;
    this.nominationGuide = data.nominationGuide;
    this.rateeName = data.rateeName;
    this.rateeProfileImage = data.rateeProfileImage;
    this.surveyName = data.surveyName;
    this.relaxMinRule = data.relaxMinRule;
    this.restrictUserListArray = data.restrictUserListArray;
    this.relationshipObject = chooseData.raterRelationships;
    this.history = chooseData.existingAllocations;
    this.nominateRulesCombo = data.nominateRulesCombo;
    if (data.NominationType === 'Prescribed') {
      for (let item of data.rules) {
        const nomObj = {};
        nomObj.qtyMin = item.qtyMin;
        nomObj.qtyMax = item.qtyMax;
        nomObj.relID = item.relationshipObject[0].DDRATERRELATIONSHIPID;
        this.nomQty[item.relationshipObject[0].DESCRIPTION] = nomObj;
      }
    } else {
      const nomObj = {};
      nomObj.qtyMin = chooseData.minRatee;
      nomObj.qtyMax = chooseData.maxRatee;
      this.nomQty = nomObj;
    }
    if (data.history.length) {
      this.prevCompleted = chooseData.existingAllocations.length;
    }
    this.appPending = data.ApprovedYN || data.SubmittedYN ? 1 : 0;
    this.loadPage();
  }

  loadPage() {
    this.buildNominations();
    console.log('USt_selections');
  }

  // Page pecific functions

  selectNom(nomID) {
    const ths = pgstr.USt_selections;

    const div = divAtt('div', 'class', '');
    const p = divAtt('p', 'class', 'marginBottom');
    if (ths.NominationType !== 'Prescribed') {
      p.innerHTML = 'Select a relationship and add a nominee.';
    } else {
      p.innerHTML = 'Select a name from the drop down or toggle New Nominee to fill in new nomination details.';
    }

    // Toggle new/existing Nominee
    const tgglDiv = divAtt('div', 'class', 'nomTggl');
    tgglDiv.id = 'nomTggl';
    const tgglLabel = divAtt('label', 'class', 'switch');
    const tggleInput = divAtt('input', 'type', 'checkbox');
    tggleInput.setAttribute('id', 'tgglNewExistingNom');
    tggleInput.checked = false;
    // tggleInput.setAttribute("onclick", "pgstr.USt_selections.tgglNewExistingNom()");
    // tggleInput.setAttribute(
    //   "onclick",
    //   "exPageMethod('USt_selections','tgglNewExistingNom')"
    // );

    const tgglSpan = divAtt('span', 'class', 'tggl round');
    const tgglName = divAtt('label', 'for', 'tgglNewExistingNom');
    tgglName.setAttribute('class', 'tgglLabel');
    tgglName.innerHTML = `Choose Person To Rate`;

    tgglLabel.appendChild(tggleInput);
    tgglLabel.appendChild(tgglSpan);
    tgglDiv.appendChild(tgglLabel);
    tgglDiv.appendChild(tgglName);

    // RELATIONSHIP DIV
    const relDiv = divAtt('div', 'class', 'relSelect marginTop');
    const relDDdiv = divAtt('div', 'class', 'group');
    const relBar = divAtt('span', 'class', 'bar');
    const relValFeedback = divAtt('p', 'class', 'inpFB hide');
    const relLabel = divAtt('label', 'for', 'name');
    relLabel.innerHTML = 'Relationship';
    let relChoice;
    if (ths.NominationType !== 'Prescribed') {
      relChoice = divAtt('select', 'id', 'relSelect');
      relChoice.setAttribute('select', 'onchange', 'notEmptyVal(this.id)');
      relValFeedback.setAttribute('id', 'relSelect_FB');
      const optOne = divAtt('option', 'value', '-');
      optOne.innerHTML = 'Please choose an option ---';
      relChoice.appendChild(optOne);
      for (let row of ths.relationshipObject) {
        const opt = divAtt('option', 'value', row.DDraterRelationshipID);
        opt.innerHTML = row.name;
        relChoice.appendChild(opt);
      }

      relDDdiv.appendChild(relChoice);
      relDDdiv.appendChild(relBar);
      relDDdiv.appendChild(relValFeedback);
      relDDdiv.appendChild(relLabel);
      relDiv.appendChild(relDDdiv);
    }

    // NOMINEE DROPDOWN
    const nomineesDiv = divAtt('div', 'class', 'grid-2col-input-custard marginBottom marginTop');
    nomineesDiv.setAttribute('id', 'existingNoms');
    const nomineeDDdiv = divAtt('div', 'class', 'group');
    const nomineeSelect = divAtt('select', 'onchange', 'notEmptyVal(this.id)');
    nomineeSelect.setAttribute('id', 'nomineeList');

    if (ths.nominateRulesCombo) {
      const optOne = divAtt('option', 'value', '-');
      optOne.innerHTML = 'Please choose an option ---';
      nomineeSelect.appendChild(optOne);
      for (let row of ths.restrictUserListArray) {
        const opt = divAtt('option', 'value', row.HuserID);
        opt.innerHTML = row.DisplayName;
        nomineeSelect.appendChild(opt);
      }
    }
    const nomBar = divAtt('span', 'class', 'bar');
    const nomValFeedback = divAtt('p', 'class', 'inpFB hide');
    // @ TIM give nomValFeedback same ID name as select but with _FB on end
    nomValFeedback.setAttribute('id', `${nomID}_FB`);
    const nomLabel = divAtt('label', 'for', 'name');
    nomLabel.innerHTML = 'Choose Person To Rate';
    nomineeDDdiv.appendChild(nomineeSelect);
    nomineeDDdiv.appendChild(nomBar);
    nomineeDDdiv.appendChild(nomValFeedback);
    nomineeDDdiv.appendChild(nomLabel);
    nomineesDiv.appendChild(nomineeDDdiv);

    const btnClosedDiv = divAtt('div', 'class', 'btnCenterGrid');
    const btnClosed = divAtt('button', 'class', 'btn ');
    // btnClosed.setAttribute(
    //   "onclick",
    //   `pgstr.USt_selections.addNomineeClosed('${nomID}')`
    // );
    // btnClosed.setAttribute(
    //   "onclick",
    //   `exPageMethod('USt_selections','addNomineeClosed','${nomID}')`
    // );

    btnClosed.innerHTML = 'Choose to Rate';
    btnClosedDiv.appendChild(btnClosed);
    nomineesDiv.appendChild(btnClosedDiv);

    // ADD NEW NOMINEE TO UNHIDE ON SELECT NEW
    const newNomDiv = divAtt('div', 'class', 'grid-2col-input-custard marginBottom marginTop');
    newNomDiv.setAttribute('id', 'newNom');
    // Name
    const nameDiv = buildInpText('fname', 1, 'Name');
    // Surname
    const surnameDiv = buildInpText('sname', 1, 'Surname');
    // Email
    const emailDiv = buildInpEmail('email', 1);

    // Numuber
    const numDiv = buildInpMobNum('cellNum', 1);

    newNomDiv.appendChild(nameDiv);
    newNomDiv.appendChild(surnameDiv);
    newNomDiv.appendChild(emailDiv);
    newNomDiv.appendChild(numDiv);

    const btnOpenDiv = buildBtnFormCenter('btnOpenNom', `USt_selections.selectRatee('${nomID}')`, 'Add New Selection');
    newNomDiv.appendChild(btnOpenDiv);

    if (!ths.nominateRulesCombo) {
      nomineesDiv.classList.add('hide');
      tgglDiv.classList.add('hide');
    } else {
      newNomDiv.classList.add('hide');
    }

    div.append(p);
    div.appendChild(tgglDiv);
    div.appendChild(relDiv);
    div.append(nomineesDiv);
    div.appendChild(newNomDiv);
    if (ths.NominationType === 'Prescribed') {
      eCustardBox(div, `Choose to rate with Relationship to ${ths.noms[nomID].relation}`, [
        [
          btnClosed,
          () => {
            exPageMethod('USt_selections', 'addNomineeClosed', `${nomID}`);
          },
          'click',
        ],
        [
          btnOpenDiv,
          () => {
            USt_selections.selectRatee(`${nomID}`);
          },
          'click',
        ],
        [
          tggleInput,
          () => {
            exPageMethod('USt_selections', 'tgglNewExistingNom');
          },
          'click',
        ],
      ]);
    } else {
      // custardBox(div, `Select Nomination`);
      eCustardBox(div, `Choose to rate`, [
        [
          btnClosed,
          () => {
            exPageMethod('USt_selections', 'addNomineeClosed', `${nomID}`);
          },
          'click',
        ],
        [
          btnOpenDiv,
          () => {
            USt_selections.selectRatee(`${nomID}`);
          },
          'click',
        ],
        [
          tggleInput,
          () => {
            exPageMethod('USt_selections', 'tgglNewExistingNom');
          },
          'click',
        ],
      ]);
    }

    ccRegex(0, 0);
    // id('cellNum').style = 'margin-bottom: 0;'
  }

  static removeSelectedRatee(nomID) {
    const ths = pgstr.USt_selections;
    const assID = ths.noms[nomID].allocHuserHassessmentID;
    const vars = {};
    vars.allocHuserHassessmentID = assID;

    PREP(['removeAllocatedRatee'], vars)
      .then((res) => {
        note(res.data.removeAllocatedRatee);
        changeTabN('USt_selections');
      })
      .catch(console.error());
  }

  static addNomineeOpen(nomID) {
    const ths = pgstr.USt_selections;

    const fname = id('fname').value;
    const sname = id('sname').value;
    const email = id('email').value;
    const cellNum = id('cellNum').value;

    const varArr = [fname, sname, email, cellNum];

    for (let item of varArr) {
      if (!item) {
        return infoBox('Please fill in all fields.');
      }
    }

    const nom = ths.noms[nomID];
    nom.firstName = fname;
    nom.surname = sname;
    nom.fullName = `${fname} ${sname}`;
    nom.emailAddress = email;
    nom.mobileNumber = cellNum;
    nom.status = 1;
    //  Previous vars
    nom.nominationUUID = nomID;
    nom.nominateComments = '';
    nom.syncedYN = 0;
    nom.batchID = 0;
    nom.batchStatusID = 4;
    nom.ratee = getCookie('jwugtkf');
    nom.HuserID = getCookie('jwugtkf');
    nom.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;
    // extras
    if (id('relSelect')) {
      const sel = id('relSelect');
      if (sel.value === '-') {
        return infoBox('Please select a relationship from the dropdown.');
      }
      nom.raterRelationship = sel.value;
      nom.relation = sel.options[sel.selectedIndex].text;
    }

    const vars = {};
    for (let item in nom) {
      vars[item] = nom[item];
    }

    PREP(['addraterthree'], vars)
      .then(() => {
        ths.updateCompleted();
        changeTabN('USt_selections');
        hide('custardBox');
      })
      .catch(console.error());
  }

  static addNomineeClosed(nomID) {
    const ths = pgstr.USt_selections;

    const HuserID = id('nomineeList').value;

    if (HuserID === '-') {
      return infoBox('Please select an option.');
    }
    const nom = ths.noms[nomID];

    for (let item of ths.restrictUserListArray) {
      if (HuserID == item.HuserID) {
        nom.fullName = item.fullName;
        nom.email = item.emailaddress;
        nom.status = 1;
        //  Previous vars
        nom.nominationUUID = nomID;
        nom.nominateComments = '';
        nom.syncedYN = 0;
        nom.batchID = 0;
        nom.batchStatusID = 4;
        nom.HuserID = getCookie('jwugtkf');
        nom.ratee = HuserID;
        nom.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;
        // extras
        if (id('relSelect')) {
          const sel = id('relSelect');
          if (sel.value === '-') {
            return infoBox('Please select a relationship from the dropdown.');
          }
          nom.raterRelationship = sel.value;
          nom.relation = sel.options[sel.selectedIndex].text;
        }

        const vars = {};
        for (let row in nom) {
          vars[row] = nom[row];
        }

        PREP(['addraterthree'], vars)
          .then(() => {
            ths.updateCompleted();
            changeTabN('USt_selections');
            hide('custardBox');
          })
          .catch(console.error());

        break;
      }
    }
  }

  static selectRatee() {
    const ths = pgstr.USt_selections;

    const vars = {};
    vars.fname = id('fname').value;
    vars.sname = id('sname').value;
    vars.email = id('email').value;
    vars.mobile = id('cellNum').value;

    vars.selectionUUID = uuidvMain();
    vars.RateeID = '';
    vars.raterRelationshipID = id('relSelect').value;
    vars.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;

    // Validation
    if (vars.raterRelationshipID === '-') {
      return infoBox('Please choose a relationship from the dropdown.');
    }

    PREP(['allocateToRatee'], vars)
      .then((res) => {
        note(res.data.allocateToRatee);
        changeTabN('USt_selections');
      })
      .catch(console.error());
  }

  checkForSubmit() {
    const ths = pgstr.USt_selections;
    const noms = ths.noms;
    const compObj = {};
    if (ths.NominationType === 'Open') {
      for (let nom in noms) {
        if (noms[nom].status) {
          if (!compObj.completed) {
            compObj.completed = 1;
          } else {
            compObj.completed++;
          }
        }
      }
      const minMet = compObj.completed >= ths.nomQty.qtyMin;
      const maxMet = ths.nomQty.qtyMax === compObj.completed;

      ths.checkSubRes(minMet, maxMet);
    } else if (ths.NominationType === 'Prescribed') {
      for (let nom in noms) {
        if (noms[nom].status) {
          const currNomRelID = noms[nom].raterRelationship;
          if (!compObj[currNomRelID]) {
            compObj[currNomRelID] = 1;
          } else {
            compObj[currNomRelID]++;
          }
        }
      }
      const nomQty = ths.nomQty;
      let minMet = true;
      let maxMet = true;
      for (let rel in nomQty) {
        const currRelID = nomQty[rel].relID;
        if (!compObj[currRelID]) {
          minMet = false;
        } else if (compObj[currRelID] < nomQty[rel].qtyMin) {
          minMet = false;
        } else if (compObj[currRelID] !== nomQty[rel].qtyMax) {
          maxMet = false;
        }
      }
      ths.checkSubRes(minMet, maxMet);
    }
  }

  static submitForApproval(warningAccepted) {
    const ths = pgstr.USt_selections;

    hide('custardBox');

    const vars = {};
    if (warningAccepted) {
      vars.warningAccepted = parseInt(warningAccepted);
    } else {
      vars.warningAccepted = 0;
    }
    vars.allocHuserHassessmentID = ths.f_allocHuserHassessmentID;

    PREP(['submitRatersForApproval'], vars)
      .then((res) => {
        note(res.data.submitRatersForApproval);
        changeTabN('USt_selections');
      })
      .catch(console.error());
  }

  static tgglNewExistingNom() {
    id('newNom').classList.toggle('hide');
    id('existingNoms').classList.toggle('hide');
  }

  async buildNominations() {
    const blankNomTemplates = id('nomSections');
    blankNomTemplates.innerHTML = '';
    let dynamicEvents = [];
    let dynamicNoms = [];
    if (!Object.keys(this.noms).length) {
      let histObj = {};
      if (this.history.length) {
        // Build history record
        for (let histItm of this.history) {
          if (!histObj[histItm.raterRelationshipID]) {
            histObj[histItm.raterRelationshipID] = 1;
          } else {
            histObj[histItm.raterRelationshipID]++;
          }
        }
      }

      if (this.NominationType === 'Prescribed') {
        for (let item in this.nomQty) {
          const currItem = this.nomQty[item];

          const nomSection = divAtt('div', 'class', 'nomCards');
          nomSection.setAttribute('id', this.NominationType);

          const divHdr = this.buildNomHeader(currItem.qtyMin, currItem.qtyMax, item, histObj[currItem.relID] || 0);
          nomSection.appendChild(divHdr);

          if (histObj.hasOwnProperty(currItem.relID)) {
            const max = currItem.qtyMax - histObj[currItem.relID];
            for (let histRow of this.history[0].historyRows) {
              if (histRow.raterRelationshipID === currItem.relID) {
                const nomID = histRow.UUID ? histRow.UUID : uuidvMain();
                let batch2Obj = null;
                this.history[1] && (await (batch2Obj = this.history[1].historyRows));
                let nominee = null;
                batch2Obj &&
                  (batch2Obj = await (nominee = batch2Obj
                    .slice()
                    .reverse()
                    .find((nominee) => nominee.UUID === nomID)));
                if (!nominee) {
                  this.noms[nomID] = new Nominee(nomID, histRow.raterRelationship, histRow.raterRelationshipID);
                  await this.noms[nomID].addOldNom(histRow);
                } else {
                  this.noms[nomID] = new Nominee(nomID, nominee.raterRelationship, nominee.raterRelationshipID);
                  await this.noms[nomID].addOldNom(nominee);
                }

                // this.noms[nomID] = new Nominee(nomID, histRow.raterRelationship, histRow.raterRelationshipID);
                // await this.noms[nomID].addOldNom(histRow);
                const [blankTemplate, dEvents] = this.buildCard(nomID);
                nomSection.appendChild(blankTemplate);
                dynamicEvents = dynamicEvents.concat(dEvents);
              }
            }
            for (let j = 1; j <= max; j++) {
              const [createdNom, nomEvts] = this.createNom(item, currItem.relID);
              nomSection.appendChild(createdNom);
              dynamicNoms = dynamicNoms.concat(nomEvts);
            }
          } else {
            for (let j = 1; j <= currItem.qtyMax; j++) {
              const [createdNom, nomEvts] = this.createNom(item, currItem.relID);
              nomSection.appendChild(createdNom);
              dynamicNoms = dynamicNoms.concat(nomEvts);
            }
          }
          blankNomTemplates.appendChild(nomSection);
        }
      } else {
        const nomSection = divAtt('div', 'class', 'nomCards');
        nomSection.setAttribute('id', this.NominationType);

        const compNumber = this.history.length ? this.history.length : 0;

        const divHdr = this.buildNomHeader(this.nomQty.qtyMin, this.nomQty.qtyMax, 'Open', compNumber);
        nomSection.appendChild(divHdr);

        let max = this.nomQty.qtyMax;
        if (this.history.length) {
          max = this.nomQty.qtyMax - this.history.length;

          for (let histRow of this.history) {
            const nomID = histRow.UUID ? histRow.UUID : uuidvMain();

            let batch2Obj = null;
            this.history[1] && (await (batch2Obj = this.history[1].historyRows));
            let nominee = null;
            batch2Obj &&
              (batch2Obj = await (nominee = batch2Obj
                .slice()
                .reverse()
                .find((nominee) => nominee.UUID === nomID)));
            if (!nominee) {
              this.noms[nomID] = new Nominee(nomID, histRow.raterRelationship, histRow.raterRelationshipID);
              await this.noms[nomID].addOldNom(histRow);
            } else {
              this.noms[nomID] = new Nominee(nomID, nominee.raterRelationship, nominee.raterRelationshipID);
              await this.noms[nomID].addOldNom(nominee);
            }
            // this.noms[nomID] = new Nominee(nomID, '', histRow.allocHuserHassessmentID);
            // await this.noms[nomID].addOldNom(histRow);
            const [blankTemplate, dEvents] = this.buildCard(nomID);
            nomSection.appendChild(blankTemplate);
            dynamicEvents = dynamicEvents.concat(dEvents);
          }
        }
        for (let i = 1; i <= max; i++) {
          const [createdNom, nomEvts] = this.createNom();
          nomSection.appendChild(createdNom);
          dynamicNoms = dynamicNoms.concat(nomEvts);
        }

        blankNomTemplates.appendChild(nomSection);
      }
    }
    //test
    for (let i = 0; i < dynamicEvents.length; i++) {
      eListen(dynamicEvents[i][0], dynamicEvents[i][1], dynamicEvents[i][2]);
    }
    for (let i = 0; i < dynamicNoms.length; i++) {
      eListen(dynamicNoms[i][0], dynamicNoms[i][1], dynamicNoms[i][2]);
    }

    // const btnDiv = divAtt('div', 'class', 'sectionGap btnEndGrid');
    // const btn = divAtt('button', 'class', 'btn');
    // btn.setAttribute('onclick', 'USt_selections.checkForSubmit()');
    // btn.innerHTML = `Submit ${this.NominationType} Nominations`;
    // btnDiv.appendChild(btn);

    // this.appPending && btn.setAttribute('disabled', 'true');

    // blankNomTemplates.appendChild(btnDiv);
  }

  checkSubRes(minMet, maxMet) {
    if (!minMet && !this.relaxMinRule) {
      return infoBox('You have not met the minimum amount of Nominations to submit.');
    } else if (!minMet && this.relaxMinRule) {
      const div = divAtt('div');
      const p = divAtt('p');
      p.innerHTML = this.minimumNomineeWarning;
      const btnDiv = divAtt('div', 'class', 'btnCenterGrid');
      const btn = divAtt('div', 'class', 'btn');
      btn.id = 'additionalBtn';
      // btn.setAttribute("onclick", "pgstr.USt_selections.submitForApproval(1)");
      // btn.setAttribute(
      //   "onclick",
      //   "exPageMethod('USt_selections','submitForApproval','1')"
      // );

      btn.innerHTML = 'Submit';
      btnDiv.appendChild(btn);
      div.appendChild(p);
      div.appendChild(btnDiv);
      custardBox(div, 'Please Note');
    } else if (!maxMet) {
      const div = divAtt('div');
      const p = divAtt('p');
      p.innerHTML = 'You have nominated the minimum required number of raters, but can still nominate more. Are you sure you would like to proceed? Should you click submit now, the nominations will be closed and you will not be able to add additional raters.';
      const btnDiv = divAtt('div', 'class', 'btnCenterGrid');
      const btn = divAtt('div', 'class', 'btn btn_solid');
      btn.id = 'additionalBtn';
      // btn.setAttribute("onclick", "pgstr.USt_selections.submitForApproval(1)");
      // btn.setAttribute(
      //   "onclick",
      //   "exPageMethod('USt_selections','submitForApproval','1')"
      // );

      btn.innerHTML = 'Submit';
      btnDiv.appendChild(btn);
      div.appendChild(p);
      div.appendChild(btnDiv);
      // custardBox(div, "Please Note");
      eCustardBox(div, 'Please Note', [
        btn,
        () => {
          exPageMethod('USt_selections', 'submitForApproval', '1');
        },
        'click',
      ]);
    } else {
      USt_selections.submitForApproval();
    }
  }

  createNom(item, relID) {
    const nomID = uuidvMain();
    this.noms[nomID] = new Nominee(nomID, item, relID);
    return this.buildCard(nomID);
  }

  updateCompleted() {
    const ths = pgstr.USt_selections;
    let statusCnt = 0;
    for (let nom in ths.noms) {
      ths.noms[nom].status && statusCnt++;
    }
    ths.completed = statusCnt;
  }

  buildNomHeader(minNom, maxNom, type, completed) {
    // Nom Headers
    const nomHeader = divAtt('div', 'class', 'gridSpan nomHeader');
    const nomType = document.createElement('h4');
    // set nom type
    nomType.innerHTML = `Selection Type: ${type}`;
    const minMaxInnstructions = document.createElement('p');
    const min = divAtt('span', 'class', 'minRequired');
    min.innerHTML = `A minimum of ${minNom} is required `;
    const max = document.createElement('span');
    max.innerHTML = `with a maximum of ${maxNom}.`;
    minMaxInnstructions.appendChild(min);
    minMaxInnstructions.appendChild(max);
    const nomDone = document.createElement('h6');
    nomDone.innerHTML = `<span id="nomDone_${this.NominationType}">${completed}</span> of ${maxNom}`;
    nomHeader.appendChild(nomType);
    nomHeader.appendChild(minMaxInnstructions);
    nomHeader.appendChild(nomDone);

    // nomSection.appendChild(nomHeader)
    return nomHeader;
  }

  buildCard(nomID) {
    const nom = this.noms[nomID];
    let dynamicEvents = [];
    const divText = this.noms[nomID].relation ? this.noms[nomID].relation : 'Selection';

    let nameText = 'name here';
    if (nom.fullName) {
      nameText = `${nom.fullName}`;
    } else if (nom.fname) {
      nameText = `${nom.fname} ${nom.sname}`;
    }
    const emailText = nom.emailAddress ? `${nom.emailAddress}` : 'email here';
    const classDiv = nom.status ? 'nomEmpty hide' : 'nomEmpty';
    // const cardAction = nom.status
    //   ? `pgstr.USt_selections.removeSelectedRatee('${nomID}')`
    //   : `pgstr.USt_selections.selectNom('${nomID}')`;

    const cardAction = nom.status ? `exPageMethod('USt_selections','removeSelectedRatee','${nomID}')` : `exPageMethod('USt_selections','selectNom','${nomID}')`;

    // CARD DIV

    const cardDiv = divAtt('div', 'class', 'nomCard cardCliclickable');
    const nomName = document.createElement('h6');
    nomName.innerHTML = nameText;
    const email = document.createElement('p');
    email.innerHTML = emailText;

    cardDiv.appendChild(nomName);
    cardDiv.appendChild(email);

    if (!this.appPending) {
      // cardDiv.setAttribute("onclick", cardAction);
      dynamicEvents.push([
        cardDiv,
        () => {
          cardAction;
        },
        'click',
      ]);
      const removeCardDiv = divAtt('div', 'class', 'removeCard ');
      // removeCardDiv.setAttribute(
      //   "onlick",
      //   `USt_selections.removeSelectedRatee('${nomID}')`
      // );
      dynamicEvents.push([
        removeCardDiv,
        () => {
          USt_selections.removeSelectedRatee(`${nomID}`);
        },
        'click',
      ]);
      removeCardDiv.innerHTML = ` <svg xmlns="http://www.w3.org/2000/svg" width="18.96" height="23.32" viewBox="0 0 18.96 23.32">
        <g id="Group_814" data-name="Group 814">
            <path id="Path_941" data-name="Path 941"
                d="M11.25,0c.15.05.29.08.43.14a2.17,2.17,0,0,1,1.44,2c0,.25,0,.5,0,.79H17A1.82,1.82,0,0,1,19,4.64V6.16a.71.71,0,0,1-.68.76H.78A.72.72,0,0,1,0,6.25V6.16a14.23,14.23,0,0,1,0-1.73A1.75,1.75,0,0,1,1.83,2.92h4V2.27A2.18,2.18,0,0,1,7.62,0L7.7,0Zm.41,2.91c0-.25,0-.49,0-.74A.71.71,0,0,0,11,1.46q-1.47,0-2.94,0A.67.67,0,0,0,7.33,2a5.41,5.41,0,0,0,0,.9Z"
                transform="translate(0)" />
            <path id="Path_942" data-name="Path 942"
                d="M9.48,8.38H17.4c.35,0,.4.06.39.41-.09,1.74-.17,3.47-.25,5.21-.12,2.44-.23,4.88-.36,7.32a2.13,2.13,0,0,1-2.13,2c-.88,0-1.76,0-2.64,0H4a2.14,2.14,0,0,1-2.2-1.79,9.73,9.73,0,0,1-.1-1.43l-.3-6.18L1.17,8.77c0-.32,0-.39.36-.39h8M5.11,14.93v4.73a.72.72,0,0,0,1.43.17,1.13,1.13,0,0,0,0-.32V10.2a.73.73,0,1,0-1.45,0s0,0,0,0v4.69m3.64,0v4.73a.73.73,0,0,0,1.44.17c0-.11,0-.21,0-.32V10.2a.73.73,0,0,0-.75-.71.73.73,0,0,0-.71.74h0v4.7m5.1,0V10.26a.74.74,0,0,0-.46-.74.73.73,0,0,0-1,.42.69.69,0,0,0,0,.32v9.38a.73.73,0,0,0,.69.76.73.73,0,0,0,.76-.7V14.92"
                transform="translate(0)" />
        </g></svg> <span>Remove</span>`;

      const addNomDiv = divAtt('div', 'class', classDiv);
      addNomDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="30.69" height="30.69" viewBox="0 0 30.69 30.69">
      <polygon
          points="30.69 13.6 17.1 13.6 17.1 0 13.6 0 13.6 13.6 0 13.6 0 17.1 13.6 17.1 13.6 30.69 17.1 30.69 17.1 17.1 30.69 17.1 30.69 13.6" />
      </svg>
      <h6>Add ${divText}</h6>`;

      cardDiv.appendChild(removeCardDiv);
      cardDiv.appendChild(addNomDiv);
    } else {
      const profilePic = divAtt('div', 'class', 'profileImgContainerCard');
      const ppImg = divAtt('img', 'alt', 'Profile Image');
      ppImg.src = nom.profileImage;
      profilePic.appendChild(ppImg);

      const assStatus = divAtt('p', 'class', 'cardRelTitle');
      assStatus.innerHTML = 'Assessment Status: ';
      const spanStatus = divAtt('span');
      spanStatus.innerHTML = nom.assessmentStatus;
      assStatus.appendChild(spanStatus);

      cardDiv.prepend(profilePic);
      cardDiv.appendChild(assStatus);
    }
    return [cardDiv, dynamicEvents];
  }
}

export class Nominee {
  constructor(nomID, relationship, relID) {
    this.nomID = nomID;
    this.firstName = null;
    this.surname = null;
    this.emailAddress = null;
    this.mobileNumber = null;
    this.fullName = null;
    this.HuserID = null;
    this.relation = relationship;
    this.raterRelationship = relID;
    this.status = 0;
  }

  async addOldNom(data) {
    for (let item in data) {
      if (item === 'raterRelationship') {
        this.relation = data[item];
      } else if (item === 'raterRelationshipID') {
        this.relationship = data[item];
      } else {
        this[item] = data[item];
      }
    }
    this.status = 1;
  }
}
